import React from 'react';
// import MediaQuery from 'react-responsive';
import { Route, hashHistory, Link,BrowerRouter, HashRouter as Router, Switch,browserHistory} from 'react-router-dom';
import Welcome from '../pages/welcome'
import Login from '../pages/App'
import ErrorPage from '../pages/errorPage'
import CompleteInfo from '../pages/completeInfo'
import UserInfo from '../pages/userInfo'
import GroupApply from '../pages/guideGroup'
import PreviewPage from '../pages/previewPage'
import { LocaleProvider  } from 'antd';
import zhCN from 'antd/es/locale-provider/zh_CN';
const routes = [
    {
        // path: '/:key?',
        path:'/',
        exact: true,
        component: Welcome
    },
    {
        path:'/login',
        exact: true,
        component: Login
    },
    {
        path:'/groupApply',
        exact: true,
        component: GroupApply
    },
    {
        // path: '/:key?',
        path:'/completeInfo',
        exact: true,
        component: CompleteInfo
    },
    {
        path:'/previewPage',
        exact: true,
        component: PreviewPage
    },
    {
        // path: '/:key?',
        path:'/userInfo',
        exact: true,
        component: UserInfo
    },

    
]
export default class Root extends React.Component {
    render() {
        return (
            <LocaleProvider locale={zhCN}>
                <div className='Content'>
                {/* <MediaQuery query='(min-device-width: 1224px)'> */}
                    <Router>
                        <Switch>
                            {
                                routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        component={route.component}
                                    />
                                ))
                            }
                            {
                                <Route   component={ErrorPage}></Route>
                            }
                        </Switch>
                    </Router>
                {/* </MediaQuery> */}

            </div>
            </LocaleProvider>
        );
    };
}
