import React, { Component } from 'react';
import { DatePicker,Select,message ,Icon  ,Button,Modal,Checkbox,Input,Upload} from 'antd';
import Editors from '../components/Editor';
import ServiceInfo from '../components/ServiceInfo'
import {URL,isPhone} from  '../config/url';
import moment from 'moment';
import axios from 'axios';
const Option = Select.Option;
let options={
    top:450,
    duration:1.5,
}
message.config(options)
class ServiceDes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            countryss:[],
            carTypes:[],
            localLans:[],
            englishLevs:[],
            moreLans:[],
            jobTypes:[],
            elseSers:[],
            proTypes:[],
            tags:[],
            companys:[],
            txt:'',
            dests:[],
            takeCar:[],
            word:'',
            params:{'districtId':'','endDistrictDate':'','serviceDestination':[],'guideService':[],'conveyorService':[],'languageLevel':'','englishLevel':'','wizardTab':[],
            'multiLanguage':[],'jobType':'','additionalServices':[],'servableProducts':[],'companyId':'','costDescUrl':''},
            keys:{'conveyorService':'接送机服务','guideService':'带车向导服务','districtId':'常住城市','endDistrictDate':'目的地开始居住年月','serviceDestination':'服务目的地','languageLevel':'当地语言','englishLevel':'英语水平','wizardTab':'向导标签',
            'multiLanguage':'多门语言','jobType':'职业类型','additionalServices':'附加选项','servableProducts':'向导服务','companyId':'公司','costDescUrl':'成本单'},
            visible:false,
            isNext:'',
            elseTable:[],
            carTable:'',
            checkAll1:false,
            carCheckBox:[],
            addProCostModal:false,
            addProCostModal1:false,
            productTypes:[{'name':'不带车服务','id':1},{'name':'翻译服务/4小时','id':2},
                        {'name':'翻译服务/天','id':4},{'name':'摄影服务','id':8},{'name':'其他服务','id':16}],
            coinTypes:[{'name':'CNY'},{'name':'USD'},{'name':'CAD'},{'name':'AUD'},{'name':'EUR'},{'name':'GBP'},{'name':'RUB'}],
            addProParam:{'serviceType':'','cityId':'','currency':'','rmk':'','price':''},
            curEditId:'',
            curEditId1:'',
            elseCheckBox:[],
            checkAll2:false,
            deleModal:false,
            productTypes1:[{'name':'接送机服务','id':1},{'name':'包车服务','id':2}],
            elseProTypes:[{'name':'市区','id':1},{'name':'周边','id':2},{'name':' 出城/含住宿补贴','id':4},{'name':'出城/不含住宿补贴','id':8}],
            addProParam1:{'serviceType':'','cityId':'','currency':'','type':2,'serviceScope':'','scheduleDetail':''},
             blurParam:{},
             focusParam:{},
             elseDoc:[],
             delDocModal:false,
             delDocData:'',
             addCarModal:false,
             brands:[],
             carType:[],
             carSets:[],
             addCarParam:{'carBrandId':'','carTypeId':'','passenger':''},
             deleWhich:'',
             deleCarId:'',
             m_deleId0:'',
             isPhone:'',
             m_editList:[],
             curCompanyId:'',
             editTitle:'新增产品报价',
             city1:'',
             city0:''
            }
    }
    onChange(key,e){
        let {params}=this.state
        params[key]=e.target.value
        this.setState({
            params
        })
    }
    handleChange(key,value,e) {
        let {params}=this.state
        if([key]=='endDistrictDate'){
            params[key]=e
        }else{
            params[key]=value
        }
        this.setState({
            params
        })
    }
    async componentDidMount(){
        let {params}=this.state
        
        for (const key in params) {
                
                if(key=='serviceDestination'&&this.props.userData.districtList){
                    this.props.userData.districtList.map((list)=>{
                        params[key].push(list.name)
                    })
                }else if(key=='carTypeId'){
                    this.props.userData.hydeGuideCarVOList.map((list)=>{
                        params[key].push(list.passenger)
                    })
                }else if(key=='multiLanguage'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==4){
                            params[key].push(list.name)
                        }
                    })
                }else if(key=='servableProducts'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==2){
                            params[key].push(list.name)
                        }
                    })
                }else if(key=='wizardTab'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==3){
                            params[key].push(list.name)
                        }
                    })
                }else if(key=='additionalServices'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==5){
                            params[key].push(list.name)
                        }
                    })
                }else if(key=='guideService'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==6){
                            params[key].push(list.name)
                           }
                    })
                }else if(key=='conveyorService'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                           if(list.hydeTagType==7){
                            params[key].push(list.name)
                           }
                    })
                }else if(key=='districtId'){
                    params[key]= this.props.userData.permanentCityShort
                }
                else{
                    params[key]=this.props.userData[key]
                }
       }
        let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getForeignCity?name=&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
        let carType= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24421`,{headers:{'DM_AUTH':this.props.token}})
        let takeCar= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24429`,{headers:{'DM_AUTH':this.props.token}})
        let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24419`,{headers:{'DM_AUTH':this.props.token}})
        let companys=await axios.get(`${URL}/MT/company/load.do?companyId=3000`,{headers:{'DM_AUTH':this.props.token}})
        let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getAllCarBrand`,{headers:{'DM_AUTH':this.props.token}})
        this.loadCarTableData()
        this.loadElseTable()
        this.getUserData()
           await  this.setState({
                countryss:data.data.data,
                dests:data.data.data,
                carTypes:carType.data.data,
                tags:res.data.data,
                companys:companys.data.data,
                takeCar:takeCar.data.data,
                brands:brand.data.data,
                isPhone:isPhone()
            })
    }
   async loadCarTableData(){
       let {blurParam}=this.state
    let carTable=await axios.get(`${URL}/SERVICE-MT-GUIDE/GuideServiceCost/getHydeGuideCarService?id=${sessionStorage.getItem('guideId')}&type=2`,{headers:{'DM_AUTH':this.props.token}})
    if(carTable.data.data.hydeGuideCarServiceList){
        carTable.data.data.hydeGuideCarServiceList.map((list)=>{
           if( blurParam[list.id]){
            list.hydeGuideCarServiceCosts.map((cost)=>{
                blurParam[list.id][cost.carId]=cost.price
            })
           }else{
            blurParam[list.id]={}
            list.hydeGuideCarServiceCosts.map((cost)=>{
                blurParam[list.id][cost.carId]=cost.price
            })
           }
        })
    }
    let carIds=[]
    if(carTable.data.data.hydeGuideCarInfoVOs){
        carTable.data.data.hydeGuideCarInfoVOs.map((car)=>{
            carIds.push(car.id)
        })
    }
    if(carTable.data.data.hydeGuideCarServiceList){
        carTable.data.data.hydeGuideCarServiceList.map((list)=>{
            if(list.hydeGuideCarServiceCosts){
                let costId=[]
                list.hydeGuideCarServiceCosts.map((item)=>{
                    costId.push(item.carId)
                })
                carIds.map((id)=>{
                    if(costId.indexOf(id)==-1){
                        list.hydeGuideCarServiceCosts.push({'carId':id,'hydeGuideCarServiceId':list.id,'price':'','carType':2})
                    }
                })
                list.hydeGuideCarServiceCosts.sort((a,b)=>{
                    return a.carId -b.carId;
                })
            }
        })
    }
    this.setState({
        carTable:carTable.data.data,
        blurParam
    })
    }
    async getUserData(){
        let arr=[]
        let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/guide/detail?guideId=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
        let userData=data.data.data
        if(userData.charterServiceAttachment){
            for (const key in JSON.parse(userData.charterServiceAttachment)) {
                let item={'name':JSON.parse(userData.charterServiceAttachment)[key],'url':key}
                arr.push(item)
            }
        }
            this.setState({
                elseDoc:arr,
                curCompanyId:userData.companyId
            })
    }
    async loadElseTable(){
        let elseTable=await axios.get(`${URL}/SERVICE-MT-GUIDE/GuideServiceCost/getHydeGuideServiceCost?id=${sessionStorage.getItem('guideId')}&type=2`,{headers:{'DM_AUTH':this.props.token}})
        this.setState({
            elseTable:elseTable.data.data
        })
    }
  async  seacrVal(e){
      let _this=this
      if(e.search(/[a-zA-Z]+/)==-1){
          let value=document.querySelectorAll('#cityss .ant-select-search__field')[0].value
            if(value){
                let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getForeignCity?name=${value}&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
                this.setState({
                    countryss:res.data.data,
                    word:value
                })
            }
      }
    }
    async  seacrVals(e){
        if(e.search(/[a-zA-Z]+/)==-1){
            let value=document.querySelectorAll('#dest .ant-select-search__field')[0].value
           if(value){
            let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getForeignCity?name=${value}&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
            await this.setState({
                    dests:res.data.data
            })
           }
        }
    }
    hideModal(){
        this.setState({
            visible:false,
        })
    }
    showModal(isNext){
        this.setState({
            visible:true,
            isNext
        })
    }
    confirmModify(){
        this.setState({
            visible:false,
        })
        let {params,keys,txt,destId,isNext}=this.state
        let {userData}=this.props
        // params['serviceDestination']=destId
        for (const key in params) {
           if(key!=''&&key!='companyId'&&key!='costDescUrl'&&key!='additionalServices'){
               if(!params[key]||params[key].length==0){
                   message.warning(`请选择或填写${keys[key]}`)
                   return false
               }
           }
           if(key=='additionalServices'){
               let arr=[]
                if(params[key]){
                    params[key].map((param)=>{
                        if(!isNaN(param*1)){
                            arr.push(param)
                        }
                       if(userData.tagVOList){
                            userData.tagVOList.map((tag)=>{
                            if(param==tag.name){
                                arr.push(tag.id)
                            }
                        })
                       }
                    })
                }
                params[key]=arr
           }else if(key=='carTypeId'){
                // params[key].map((param)=>{
                //     userData.hydeGuideCarVOList.map((tag)=>{
                //         if(param.name==tag.name){
                //             param=tag.id
                //         }
                //     })
                // })
           }else if(key=='multiLanguage'){
                    let arr=[]
                    params[key].map((param)=>{
                        if(!isNaN(param*1)){
                            arr.push(param)
                        }
                        if(userData.tagVOList){
                        userData.tagVOList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                    })
                    params[key]=arr
            }else if(key=='servableProducts'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }
                    if(userData.tagVOList){
                        userData.tagVOList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                })
                params[key]=arr
            }else if(key=='serviceDestination'){
                if(params[key]){
                    let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }
                    if(userData.districtList){
                        userData.districtList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                })
                // this.state.dests.map((dest)=>{
                //     if(dest.name==params[key]){
                //         arr.push(dest.id)
                //     }
                // })
                params[key]=arr
                }
            }else if(key=='districtId'){
                if(params[key]){
                    if(isNaN(params[key])){
                        params[key]=userData.districtId
                    }
                }
            }else if(key=='wizardTab'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }
                   if(userData.tagVOList){
                        userData.tagVOList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                })
                params[key]=arr
            }else if(key=='guideService'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }else{
                        if(userData.tagVOList){
                            userData.tagVOList.map((tag)=>{
                            if(tag.hydeTagType==6&&param==tag.name){
                                    arr.push(tag.id)
                            }
                        })
                       }
                    }
                   
                })
                params[key]=arr
            }else if(key=='conveyorService'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }else{
                        if(userData.tagVOList){
                            userData.tagVOList.map((tag)=>{
                            if(tag.hydeTagType==7&&param==tag.name){
                                    arr.push(tag.id)
                            }
                        })
                       }
                    }
                   
                })
                params[key]=arr
            }
        }
        params.costDescUrl=txt
        params.id=sessionStorage.getItem('guideId')
        params.page=8
        params['status']=1
        var param = new URLSearchParams();
        for (const key in params) {
            param.append([key],params[key])
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                if(isNext!=0){
                    this.props.goToNext(3)
                    window.scrollTo(0,0)
                }else{
                    message.success('保存成功')
                }
            }
          })
    }
    saveInfo(step){
        if(step==1){
            window.scrollTo(0,0)
            this.props.goToNext(2)
            return false
        }else{
            window.scrollTo(0,0)
            this.props.goToNext(4)
            return false
        }
    }
    getValue(value){
        this.setState({
            txt:value
        })
    }
    checkAllOne(e){
        let {carTable}=this.state
        let arr=[]
        if(e.target.checked){
            carTable.hydeGuideCarServiceList.map((car)=>{
                if(arr.indexOf(car.id)==-1){
                    arr.push(car.id)
                }
            })
        }
        this.setState({
            checkAll1:e.target.checked,
            carCheckBox:arr
        })
    }
    addCheckBox(id,e){
        let {carCheckBox,checkAll1,carTable}=this.state
       if(e.target.checked){
           carCheckBox.push(id)
       }else{
           carCheckBox.splice(carCheckBox.indexOf(id),1)
       }
       if(carCheckBox.length!=carTable.hydeGuideCarServiceList.length){
           checkAll1=false
       }else{
        checkAll1=true
       }
       this.setState({
        carCheckBox,
        checkAll1
       })
    }
    checkAllTwo(e){
        let {elseTable}=this.state
        let arr=[]
        if(e.target.checked){
            elseTable.map((car)=>{
                if(arr.indexOf(car.id)==-1){
                    arr.push(car.id)
                }
            })
        }
        this.setState({
            checkAll2:e.target.checked,
            elseCheckBox:arr
        })
    }
    addCheckBox2(id,e){
        let {elseCheckBox,checkAll2,elseTable}=this.state
       if(e.target.checked){
        elseCheckBox.push(id)
       }else{
        elseCheckBox.splice(elseCheckBox.indexOf(id),1)
       }
       if(elseCheckBox.length!=elseTable.length){
        checkAll2=false
       }else{
        checkAll2=true
       }
       this.setState({
        elseCheckBox,
        checkAll2
       })
    }
    addProductCost(){
        let {addProCostModal,addProParam,city0}=this.state
        this.setState({
            addProCostModal:!addProCostModal,
            addProParam:addProCostModal?{'serviceType':'','cityId':'','currency':'','rmk':'','price':''}:addProParam,
            city0:addProCostModal?'':city0
        })
    }
    addProductCost1(){
        let {addProCostModal1,addProParam1,city1}=this.state
        this.setState({
            addProCostModal1:!addProCostModal1,
            addProParam1:addProCostModal1?{'serviceType':'','cityId':'','currency':'','type':2,'serviceScope':'','scheduleDetail':''}:addProParam1,
            city1:addProCostModal1?'':city1
        })
    }
    addInputChange(key,val){
        let {addProParam,city0}=this.state
        if(key==='cityId'){
            addProParam[key]=JSON.parse(val).id
            city0=JSON.parse(val).name
        }else{
            addProParam[key]=val
        }
        this.setState({
            addProParam,
            city0
        })
    }
    addInputChange1(key,val){
        let {addProParam1,city1}=this.state
        if(key==='cityId'){
            addProParam1[key]=JSON.parse(val).id
            city1=JSON.parse(val).name
        }else{
            addProParam1[key]=val
        }
        this.setState({
            addProParam1,
            city1
        })
    }
    rmkVal(key,e){
        let {addProParam}=this.state
        addProParam[key]=e.target.value
        this.setState({
            addProParam
        })
    }
    rmkVal1(key,e){
        let {addProParam1}=this.state
        addProParam1[key]=e.target.value
        this.setState({
            addProParam1
        })
    }
    saveProCost(){
        let {addProParam,curEditId}=this.state
        addProParam['type']=2
        addProParam['hydeGuideBaseId']=sessionStorage.getItem('guideId')
        if(curEditId){
            addProParam['id']=curEditId
        }
        var param = new URLSearchParams();
        for (const key in addProParam) {
            param.append([key],addProParam[key])
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/GuideServiceCost/saveHydeGuideServiceCost`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                this.loadElseTable()
                this.setState({
                    addProCostModal:false,
                    addProParam:{'serviceType':'','cityId':'','currency':'','rmk':'','price':''}
                })
                message.success('保存成功')
            }
          })
    }
    saveProCost1(){
        let {addProParam1,curEditId1,m_editList,isPhone}=this.state
        addProParam1['type']=2
        addProParam1['hydeGuideBaseId']=sessionStorage.getItem('guideId')
        if(curEditId1){
            addProParam1['id']=curEditId1
        }
        if(isPhone){
            addProParam1['price']=''
            addProParam1['hydeGuideCarServiceCostList']=JSON.stringify(m_editList)
        }
        var param = new URLSearchParams();
        for (const key in addProParam1) {
            param.append([key],addProParam1[key])
        }
        let path=isPhone?'GuideServiceCost/saveGuideCarServiceCost':'GuideServiceCost/saveHydeGuideCarService'
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/${path}`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            dataType: 'json',
                contentType: 'application/json; charset=UTF-8',// 解决415错误
            data: param
          }).then((res)=>{
            if(res.data.success){
                this.loadCarTableData()
                this.setState({
                    addProCostModal1:false,
                    addProParam1:{'serviceType':'','cityId':'','currency':'','type':2,'serviceScope':'','scheduleDetail':''}
                })
                message.success('保存成功')
            }
          })
    }
   async editElseTable(table){
        let {addProParam}=this.state
        addProParam['cityId']=table['cityId']
        addProParam['currency']=table['currency']
        addProParam['price']=table['price']
        addProParam['serviceType']=table['serviceType']
        addProParam['rmk']=table['rmk']
        addProParam['id']=table['id']
        this.setState({
            addProParam,
            addProCostModal:true,
            curEditId:table.id,
            city0:table.cityName
        })
    }
    async editElseTable1(table){
        let {addProParam1,isPhone,m_editList,carTable}=this.state
        let arr=[]
        if(isPhone&&carTable.hydeGuideCarInfoVOs){
            carTable.hydeGuideCarInfoVOs.map((car)=>{
                table.hydeGuideCarServiceCosts.map((cost)=>{
                    if(car.id==cost.carId){
                        arr.push(cost)
                    }
                })
            })
        }
        addProParam1['cityId']=table['cityId']
        addProParam1['currency']=table['currency']
        addProParam1['price']=table['price']
        addProParam1['serviceType']=table['serviceType']
        addProParam1['scheduleDetail']=table['scheduleDetail']
        addProParam1['serviceScope']=table['serviceScope']?table['serviceScope']:''
        addProParam1['id']=table['id']
        this.setState({
            addProParam1,
            addProCostModal1:true,
            curEditId1:table.id,
            m_editList:arr,
            city1:table.cityName
        })
    }
    deleteElseTable(){
     let {elseCheckBox,carCheckBox,deleWhich,m_deleId0}=this.state
     var param = new URLSearchParams();
     let path
     if(deleWhich==0){
        param.append('hydeGuideCarServiceIds',m_deleId0?m_deleId0:carCheckBox)
        path='GuideServiceCost/deleteHydeGuideCarService'
     }else{
        param.append('hydeGuideServiceCostIds',m_deleId0?m_deleId0:elseCheckBox)
        path='GuideServiceCost/deleteHydeGuideServiceCost'
     }
     axios({
         url: `${URL}/SERVICE-MT-GUIDE/${path}`,
         method: 'post',
         headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
         data: param
       }).then((res)=>{
         if(res.data.success){
             if(deleWhich==0){
                this.loadCarTableData()
                this.setState({
                    deleModal:false,
                    carCheckBox:[]
                 })
             }else{
                this.loadElseTable()
                this.setState({
                    deleModal:false,
                    elseCheckBox:[]
                 })
             }
             message.success('删除成功')
         }
       })
    }
    changeDeleModal(num){
        let {deleModal}=this.state
        this.setState({
            deleModal:!deleModal,
            deleWhich:num
        })
    }
    changeDeleModalM(id,num,e){
        e.stopPropagation()
        let {deleModal,m_deleId0}=this.state
        this.setState({
            deleModal:!deleModal,
            m_deleId0:deleModal?'':[id],
            deleWhich:num
        })
    }
    carCostPrice(car,cost,e){
        if(isNaN(e.target.value*1)&&e.target.value*1>0){
            return false
        }
        let {focusParam,blurParam}=this.state
        focusParam['hydeGuideCarServiceId']=cost.hydeGuideCarServiceId?cost.hydeGuideCarServiceId:car.id
        focusParam['carId']=cost.carId?cost.carId:cost.id
        focusParam['carType']=isNaN(cost.carType*1)?2:cost.carType
        focusParam['price']=e.target.value
        blurParam[car.id][cost.carId?cost.carId:cost.id]=e.target.value
        this.setState({
            focusParam,
            blurParam
        })
    }
    saveCostPrice(){
        let {focusParam}=this.state
        if(!focusParam.price){
            return false
        }
        var param = new URLSearchParams();
        for (const key in focusParam) {
            param.append([key],focusParam[key])
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/GuideServiceCost/saveHydeGuideCarServiceCost`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                this.loadCarTableData()
            }
          })
    }
    deleteDoc(doc,b){
        let {delDocModal,delDocData}=this.state
       if(doc){
            this.setState({
                delDocData:doc,
                delDocModal:!delDocModal
            })
       }else{
        if(b){
            var param = new URLSearchParams();
            param.append('guideId',sessionStorage.getItem('guideId'))
            param.append('attachmentUrl',delDocData.url)
            axios({
                url: `${URL}/SERVICE-MT-GUIDE/GuideServiceCost/deleteGuideCarServiceAttachment`,
                method: 'post',
                headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
                data: param
            }).then((res)=>{
                if(res.data.success){
                    message.success('删除成功')
                    this.getUserData()
                    this.setState({
                        delDocModal:!delDocModal
                    })
                }
            })
        }else{
            this.setState({
                delDocModal:!delDocModal
            })
        }
       }
    }
    uploadFile(){
        var formData = new FormData();
        formData.append("file",document.getElementById("attachment").files[0])
        formData.append('guideId',sessionStorage.getItem('guideId'))
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/GuideServiceCost/saveGuideCarServiceAttachment`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: formData
        }).then((res)=>{
            if(res.data.success){
                message.success('添加成功')
                this.getUserData()
            }
        })
    }
   async addCarModalShow(car){
        let {addCarModal,addCarParam}=this.state
        if(car){
            addCarParam['carBrandId']=car.carBrandId
            addCarParam['carTypeId']=car.carTypeId
            addCarParam['passenger']=car.passenger
            let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getCar?id=${car.carBrandId}`,{headers:{'DM_AUTH':this.props.token}})
            let sets=[]
            brand.data.data.map((type)=>{
                if(type.typeId==car.carTypeId){
                    sets.push(type)
                }
            })
            this.setState({
                carType:brand.data.data,
                carSets:sets
            })
        }
        this.setState({
            addCarModal:!addCarModal,
            deleCarId:car?car.id:'',
            addCarParam
        })
    }
    async handleCarChange(e,key){
        let {addCarParam}=this.state
        addCarParam['carBrandId']=key
        addCarParam['carTypeId']=''
        addCarParam['passenger']=''
        let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getCar?id=${key}`,{headers:{'DM_AUTH':this.props.token}})
        this.setState({
            carType:brand.data.data,
            addCarParam
        })
    }
    async handleCarTypeChange(e,key){
        let {addCarParam}=this.state
        addCarParam[e]=key
        await  this.setState({
            addCarParam
            })
        let sets=[]
        this.state.carType.map((type)=>{
            if(type.typeId==key){
                sets.push(type)
            }
        })
        this.setState({
            carSets:sets
        })
    }
   async saveAddCar(){
        let {addCarParam,deleCarId}=this.state
           addCarParam['carAddress']=''
           addCarParam['carNumber']=''
           addCarParam['files']=[]
           addCarParam['page']=4
           addCarParam['guideId']=sessionStorage.getItem('guideId')*1
           if(deleCarId){
               addCarParam['id']=deleCarId
           }
           var formData = new FormData();
           for (const key in addCarParam) {
            formData.append([key],addCarParam[key])
            }
           let res= await axios.post(`${URL}/SERVICE-MT-GUIDE/car/save`,formData,{headers:{'DM_AUTH':this.props.token}})
           if(res.data.success){
            message.success('保存成功')
            this.setState({
                addCarModal:false
            })
            this.loadCarTableData()
        }
    }
    async deleCarItem(car){
        var formData = new FormData();
        formData.append('ids',[car.id])
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/car/delete`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: formData
        }).then((res)=>{
            if(res.data.success){
                message.success('删除成功')
                this.loadCarTableData()
            }
        })
    }
    showService(){
        Modal.info({
            title:null,
            icon:null,
            content: (
              <div>
                <ServiceInfo></ServiceInfo>
              </div>
            ),
            onOk() {},
          });
    }
    changeEditlist(i,e){
        let {m_editList}=this.state
        m_editList[i].price=e.target.value
        this.setState({
            m_editList
        })
    }
    render() {
        let {countryss,carTypes,localLans,englishLevs,moreLans,jobTypes,elseSers,
            proTypes,tags,dests,params,takeCar,elseTable,
            carTable,checkAll1,carCheckBox,addProParam,productTypes,
            coinTypes,checkAll2,elseCheckBox,deleModal,productTypes1,addProParam1,
            elseProTypes,blurParam,elseDoc,brands,addCarParam,carType,carSets,isPhone,
            m_editList,curCompanyId,curEditId,curEditId1,city1,city0}=this.state
        let date
        const dateFormat = 'YYYY-MM-DD';
        if(params['endDistrictDate']){
            params['endDistrictDate']=params['endDistrictDate'].replace(' 00:00:00','')
            date=moment(params['endDistrictDate'], dateFormat)
        }
        return (
            <div className='baseInfo serviceInfo'>
                <div className='infos'>
                    {curCompanyId===3525&&<div className='infoItem'>
                        <div className='title f20'>服务费用</div>
                        <div className='cover'></div>
                        {/* <Editors onChange={this.handleChange.bind(this,'costDescUrl')} getValue={this.getValue.bind(this)} values={params['costDescUrl']}></Editors> */}
                        <div className='tableLabel'>接送机服务、包车服务</div>
                        <div className='mt10'>
                            <Button style={{backgroundColor:'#f0a507',color:'#fff',marginRight:'10px'}} onClick={this.addProductCost1.bind(this)}>
                                <Icon type='plus'></Icon>
                                新增产品报价
                            </Button>
                            <Button style={{backgroundColor:'#f0a507',color:'#fff'}} onClick={this.addCarModalShow.bind(this,'')}>
                                <Icon type='plus'></Icon>
                                新增车型
                            </Button>
                            <Button style={{float:'right'}} className='deleBtn' disabled={carCheckBox.length>0?false:true} onClick={this.changeDeleModal.bind(this,0)}>
                                <Icon type="delete" />
                                批量删除行程
                            </Button>
                        </div>
                        <div className='mt20 m_table'>
                        {carTable.hydeGuideCarServiceList&&carTable.hydeGuideCarServiceList.map((table,t)=>{
                            return(
                                <div className='tableItem' key={t} onClick={this.editElseTable1.bind(this,table)}>
                                    <div>
                                        {table.serviceType==1&&'接送机服务 : '}{table.serviceType==2&&`包车服务${table.serviceScope==1?'/市区':''}
                                    ${table.serviceScope==2?'/周边':''}${table.serviceScope==4?'/出城/含住宿补贴':''}${table.serviceScope==8?'/出城/不含住宿补贴':''} ：`}
                                        [ {table.cityName} ]
                                    </div>
                                    <div className='mt5'>{table.scheduleDetail}</div>
                                    {carTable.hydeGuideCarInfoVOs.map((car,c)=>{
                                        let price
                                        table.hydeGuideCarServiceCosts.map((cost)=>{
                                            if(car.id==cost.carId){
                                                price=cost.price
                                            }
                                        })
                                        return(
                                            <div key={c} className='mt5'>{car.carBrand}/{car.carType}/{car.passenger}座 : {price} {table.currency}</div>
                                        )
                                    })}
                                     <Button  className='deleBtnItem'  onClick={this.changeDeleModalM.bind(this,table.id,0)}>
                                        <Icon type="delete" />
                                        删除
                                    </Button>
                                </div>
                            )
                        })}
                        </div>
                        {carTable&&<table className='costTable mt10'>
                            <thead>
                            <tr>
                            <th><Checkbox checked={checkAll1} onChange={this.checkAllOne.bind(this)}></Checkbox></th>
                            <th>类型</th>
                            <th>城市</th>
                            <th>行程</th>
                            {carTable.hydeGuideCarInfoVOs&&carTable.hydeGuideCarInfoVOs.map((carType,ca)=>{
                                return(
                                    <th key={ca} className='carTypeItem'>
                                        {carType.carType}
                                        <span className='hideIcon ml5'> 
                                            <Icon type="delete" title='删除' onClick={this.deleCarItem.bind(this,carType)}/> 
                                            <Icon type="edit" title='编辑' className='ml5' onClick={this.addCarModalShow.bind(this,carType)}/>
                                        </span>
                                    </th>
                                )
                            })}
                            <th>币种</th>
                            <th>操作</th>
                            </tr>
                            </thead>
                          <tbody>
                          {carTable.hydeGuideCarServiceList&&carTable.hydeGuideCarServiceList.map((car,c)=>{
                               return(
                                <tr key={c}>
                                    <td><Checkbox checked={carCheckBox.indexOf(car.id)!=-1?true:false} onChange={this.addCheckBox.bind(this,car.id)}></Checkbox></td>
                                    <td>{car.serviceType==1&&'接送机服务'}{car.serviceType==2&&'包车服务'}{car.serviceScope==1&&'/市区'}
                                    {car.serviceScope==2&&'/周边'}{car.serviceScope==4&&'/出城/含住宿补贴'}{car.serviceScope==8&&'/出城/不含住宿补贴'}</td>
                                    <td>{car.cityName}</td>
                                    <td>{car.scheduleDetail}</td>
                                    {/* {car.hydeGuideCarServiceCosts&&car.hydeGuideCarServiceCosts.map((cost,co)=>{
                                        return(
                                            <td key={co}><input type="text" value={blurParam[car.id][cost.carId]} className='carPrice'  
                                            onChange={this.carCostPrice.bind(this,car,cost)} onBlur={this.saveCostPrice.bind(this)}/></td>
                                        )
                                    })} */}
                                    {carTable.hydeGuideCarInfoVOs&&carTable.hydeGuideCarInfoVOs.map((cost,co)=>{
                                        let ishave
                                        car.hydeGuideCarServiceCosts.map((cst)=>{
                                            if(cst.carId==cost.id){
                                                ishave=cst
                                            }
                                        })
                                        if(ishave){
                                            return(
                                                <td key={co}><input type="text" value={blurParam[car.id][ishave.carId]} className='carPrice'  
                                                onChange={this.carCostPrice.bind(this,car,ishave)} onBlur={this.saveCostPrice.bind(this)}/></td>
                                            )
                                        }else{
                                            return(
                                                <td key={co}><input type="text" value={blurParam[car.id][cost.carId]} 
                                                className='carPrice' onChange={this.carCostPrice.bind(this,car,cost)} onBlur={this.saveCostPrice.bind(this)}/></td>
                                            )
                                        }
                                        
                                    })}
                                    <td>{car.currency}</td>
                                    <td><Icon type="edit" style={{fontSize:'20px',color:'#f0a507'}} onClick={this.editElseTable1.bind(this,car)}/></td>
                                </tr>
                               )
                           })}
                          </tbody>
                        </table>}
                        <div className='elseDoc mt10'>
                            包车服务附件：
                            <span className='addDoc mr15'>
                                <input type="file" onChange={this.uploadFile.bind(this)} id='attachment'/>
                            <Icon type="upload" />
                            {elseDoc.length==0?'添加附件':'继续添加附件'}
                                
                            </span>
                            {elseDoc.map((doc,d)=>{
                                return(
                                    <span key={d}  className='mr15'>
                                        <a  href={`https://service.dameiweb.com/MT/multimedia/download.do?key=${doc.url}&DM_AUTH=${this.props.token}`} target='_blank' >{doc.name} </a>
                                        <span className='delBtn' onClick={this.deleteDoc.bind(this,doc,false)}>删除</span>
                                    </span>
                                )
                            })}
                        </div>
                        <div className='tableLabel mt20'>不带车服务、翻译服务、摄影服务、其他服务</div>
                        <div className='mt10'>
                            <Button style={{backgroundColor:'#f0a507',color:'#fff',marginRight:'10px'}} onClick={this.addProductCost.bind(this)}>
                                <Icon type='plus'></Icon>
                                新增产品报价
                            </Button>
                            <Button style={{float:'right'}} className='deleBtn' disabled={elseCheckBox.length>0?false:true} onClick={this.changeDeleModal.bind(this,1)}>
                                <Icon type="delete" />
                                批量删除行程
                            </Button>
                        </div>
                        <div className='mt20 m_table'>
                        {elseTable.map((table,t)=>{
                            return(
                                <div className='tableItem' key={t} onClick={this.editElseTable.bind(this,table)}>
                                    <div>
                                    {table.serviceType==1&&'不带车服务'}{table.serviceType==2&&'翻译服务/4小时'}{table.serviceType==4&&'翻译服务/天'}{table.serviceType==8&&'摄影服务'}{table.serviceType==16&&'其他服务'}
                                       ： [ {table.cityName} ]
                                    </div>
                                    <div className='mt5'>价格：{table.price} {table.currency}</div>
                                    <div className='mt5'>{table.rmk}</div>
                                     <Button  className='deleBtnItem'  onClick={this.changeDeleModalM.bind(this,table.id,1)}>
                                        <Icon type="delete" />
                                        删除
                                    </Button>
                                </div>
                            )
                        })}
                        </div>
                        <table className='costTable mt10'>
                            <thead>
                            <tr>
                            <th><Checkbox checked={checkAll2} onChange={this.checkAllTwo.bind(this)}></Checkbox></th>
                            <th>类型</th>
                            <th>城市</th>
                            <th>价格</th>
                            <th>币种</th>
                            <th>备注</th>
                            <th>编辑</th>
                            </tr>
                            </thead>
                            <tbody>
                            {elseTable.map((table,t)=>{
                                return(
                                    <tr key={t}>
                                        <td><Checkbox checked={elseCheckBox.indexOf(table.id)!=-1?true:false} onChange={this.addCheckBox2.bind(this,table.id)}></Checkbox></td>
                                        <td>{table.serviceType==1&&'不带车服务'}{table.serviceType==2&&'翻译服务/4小时'}{table.serviceType==4&&'翻译服务/天'}{table.serviceType==8&&'摄影服务'}{table.serviceType==16&&'其他服务'}</td>
                                        <td>{table.cityName}</td>
                                        <td>{table.price}</td>
                                        <td>{table.currency}</td>
                                        <td>{table.rmk}</td>
                                        <td><Icon type="edit" style={{fontSize:'20px',color:'#f0a507'}} onClick={this.editElseTable.bind(this,table)}/></td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className='mt10 serviceState mt10' onClick={this.showService}>《服务说明》</div>
                    </div>}
                    <div className='alignRight'>
                        <Button onClick={this.saveInfo.bind(this,1)} type="primary" className='saveBtn mr15'>上一页</Button>
                        {/* <Button onClick={this.saveInfo.bind(this,0)} type="primary" className='saveBtn mr15'>保存</Button> */}
                        <Button onClick={this.saveInfo.bind(this)} type="primary" className='saveBtn'>保存并下一页</Button>
                    </div>
                </div>
                <Modal
                    title="温馨提示："
                    visible={this.state.visible}
                    onOk={this.confirmModify.bind(this)}
                    onCancel={this.hideModal.bind(this)}
                    okText="确认"
                    cancelText="取消">
                    <div>是否重新保存？重新保存后需重新审核。</div>
                </Modal>
                {/* 包车服务modal */}
                <Modal 
                    title={<div className='alignCenter'>{curEditId||curEditId1?'编辑产品报价':'新增产品报价'}</div>}
                    visible={this.state.addProCostModal1}
                    onOk={this.saveProCost1.bind(this)}
                    onCancel={this.addProductCost1.bind(this)}
                    okText="保存"
                    cancelText="取消">
                    <div className='alignCenter'>
                    <div className='inputItem'>
                            <span className='label_'>类型<span className='mustIcon'>*</span>：</span>
                            <Select  style={{ width: 145 }} onChange={this.addInputChange1.bind(this,'serviceType')} 
                            value={addProParam1['serviceType']} showArrow={true} className='mr10'>
                                {productTypes1.map((type,t)=>{
                                        return(
                                            <Option value={type.id} key={t}>{type.name}</Option>
                                        )
                                    })}
                            </Select>
                            {addProParam1['serviceType']==2?<Select  style={{ width: 145 }} 
                            onChange={this.addInputChange1.bind(this,'serviceScope')} 
                            value={addProParam1['serviceScope']} showArrow={true} >
                                {elseProTypes.map((type,t)=>{
                                        return(
                                            <Option value={type.id} key={t}>{type.name}</Option>
                                        )
                                    })}
                            </Select>:<div style={{width:'145px',display:'inline-block'}}></div>}
                        </div>
                        <div className='inputItem citys mt10' id='cityss'>
                            <span className='label_'>城市：</span>
                            <Select  style={{ width: 300 }} onSearch={this.seacrVal.bind(this)}
                             onChange={this.addInputChange1.bind(this,'cityId')} optionFilterProp="data-name" 
                             filterOption={(input, option) => option.props.children.indexOf(input) >= 0} 
                             showArrow={true} showSearch value={city1}>
                                {countryss.length>0&&countryss.map((country,c)=>{
                                    return(
                                        <Option value={JSON.stringify(country)} key={c} data-name={country.name}>{country.name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className='inputItem mt10'>
                            <span className='label_'>币种：</span>
                            <Select  style={{ width: 300 }} onChange={this.addInputChange1.bind(this,'currency')} value={addProParam1['currency']} showArrow={true} >
                                {coinTypes.map((type,t)=>{
                                        return(
                                            <Option value={type.name} key={t}>{type.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div>
                        {isPhone&&m_editList.length>0&&carTable.hydeGuideCarInfoVOs&&carTable.hydeGuideCarInfoVOs.map((info,i)=>{
                            if(m_editList[i]){
                                return(
                                    <div className='inputItem mt10' key={i}>
                                        <span className='label_'>{info.carBrand}/{info.carType}/{info.passenger}：</span>
                                        <Input style={{ width: 300 }} onChange={this.changeEditlist.bind(this,i)} value={m_editList[i]['price']}/>
                                    </div>
                                )
                            }else{
                                return(
                                    <div className='inputItem mt10' key={i}>
                                        <span className='label_'>{info.carBrand}/{info.carType}/{info.passenger}：</span>
                                        <Input style={{ width: 300 }} onChange={this.changeEditlist.bind(this,i)} />
                                    </div>
                                )
                            }
                        })}
                        <div className='inputItem mt10'>
                            <span className='label_ vTop'>行程：</span>
                            <textarea name="" id="" cols="30" rows="10" onChange={this.rmkVal1.bind(this,'scheduleDetail')} value={addProParam1['scheduleDetail']}></textarea>
                        </div>
                    </div>
                </Modal>
                {/* 其他服务modal */}
                <Modal 
                    title={<div className='alignCenter'>{curEditId||curEditId1?'编辑产品报价':'新增产品报价'}</div>}
                    visible={this.state.addProCostModal}
                    onOk={this.saveProCost.bind(this)}
                    onCancel={this.addProductCost.bind(this)}
                    okText="保存"
                    cancelText="取消">
                    <div className='alignCenter'>
                    <div className='inputItem'>
                            <span className='label_'>类型<span className='mustIcon'>*</span>：</span>
                            <Select  style={{ width: 300 }} onChange={this.addInputChange.bind(this,'serviceType')} value={addProParam['serviceType']} showArrow={true} >
                                {productTypes.map((type,t)=>{
                                        return(
                                            <Option value={type.id} key={t}>{type.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div>
                        <div className='inputItem citys mt10' id='cityss'>
                            <span className='label_'>城市：</span>
                            <Select  style={{ width: 300 }} onSearch={this.seacrVal.bind(this)}
                             onChange={this.addInputChange.bind(this,'cityId')} optionFilterProp="data-name" 
                             filterOption={(input, option) => option.props.children.indexOf(input) >= 0} 
                             showArrow={true} showSearch value={city0}>
                                {countryss.length>0&&countryss.map((country,c)=>{
                                    return(
                                        <Option value={JSON.stringify(country)} key={c} data-name={country.name}>{country.name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className='inputItem mt10'>
                            <span className='label_'>价格：</span>
                            <Input style={{ width: 300 }} onChange={this.rmkVal.bind(this,'price')} value={addProParam['price']}/>
                        </div>
                        <div className='inputItem mt10'>
                            <span className='label_'>币种：</span>
                            <Select  style={{ width: 300 }} onChange={this.addInputChange.bind(this,'currency')} value={addProParam['currency']} showArrow={true} >
                                {coinTypes.map((type,t)=>{
                                        return(
                                            <Option value={type.name} key={t}>{type.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div>
                        <div className='inputItem mt10'>
                            <span className='label_ vTop'>备注：</span>
                            <textarea name="" id="" cols="30" rows="10" onChange={this.rmkVal.bind(this,'rmk')} value={addProParam['rmk']}></textarea>
                        </div>
                    </div>
                </Modal>
                  {/* 新增车辆modal */}
                  <Modal 
                    title={<div className='alignCenter'>新增车型</div>}
                    visible={this.state.addCarModal}
                    onOk={this.saveAddCar.bind(this)}
                    onCancel={this.addCarModalShow.bind(this,'')}
                    okText="保存"
                    cancelText="取消">
                    <div className='alignCenter'>
                    <div className='inputItem'>
                            <span className='label_'>车品牌<span className='mustIcon'>*</span>：</span>
                            <Select placeholder='请选择车型' style={{width:300}}  value={addCarParam['carBrandId']?addCarParam['carBrandId']:'请选择车品牌'}  onChange={this.handleCarChange.bind(this,'carBrandId')} 
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}  showArrow={true} showSearch>
                                    {brands.map((brand,b)=>{
                                        return(
                                            <Option value={brand.id} key={b}>{brand.name}</Option>
                                        )
                                    })}
                        </Select>
                    </div>
                    <div className='inputItem mt10'>
                            <span className='label_'>车类型<span className='mustIcon'>*</span>：</span>
                            <Select placeholder='请选择' style={{width:300}} value={addCarParam['carTypeId']?addCarParam['carTypeId']:'请选择车型'}   onChange={this.handleCarTypeChange.bind(this,'carTypeId')} 
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}  showArrow={true} showSearch>
                                    {carType.map((type,t)=>{
                                        return(
                                            <Option value={type.typeId} key={t}>{type.name}</Option>
                                        )
                                    })}
                        </Select>
                    </div>
                    <div className='inputItem mt10'>
                            <span className='label_'>车座位<span className='mustIcon'>*</span>：</span>
                            <Select placeholder='请选择' style={{width:300}} value={addCarParam['passenger']?addCarParam['passenger']:'请选择车座位'}   onChange={this.handleCarTypeChange.bind(this,'passenger')} 
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}  showArrow={true} showSearch>
                                    {carSets.map((type,t)=>{
                                        return(
                                            <Option value={type.passenger} key={t}>{type.passenger}</Option>
                                        )
                                    })}
                        </Select>
                    </div>
                    </div>
                </Modal>
                <Modal
                    title={<div className='alignCenter'>温馨提示：</div>}
                    visible={deleModal}
                    onOk={this.deleteElseTable.bind(this)}
                    onCancel={this.changeDeleModal.bind(this,'')}
                    okText="确认"
                    cancelText="取消">
                    <div className='alignCenter'>是否删除该行程？</div>
                </Modal>
                <Modal
                    title={<div className='alignCenter'>温馨提示：</div>}
                    visible={this.state.delDocModal}
                    onOk={this.deleteDoc.bind(this,'',true)}
                    onCancel={this.deleteDoc.bind(this,'',false)}
                    okText="确认"
                    cancelText="取消">
                    <div className='alignCenter'>是否删除该附件？</div>
                </Modal>
            </div>
        );
    }
}
export default ServiceDes