import React, { Component ,ReactDOM} from 'react';
import Progress from '../components/Progress'
import BaseInfo from '../components/BaseInfo'
import ServiceDes from '../components/ServiceDes'
import PersonInfo from '../components/PersonInfo'
import BasicInformation  from '../components/BasicInformation'
import ServiceCar from '../components/ServiceCar'
import ServiceFee from '../components/ServiceFee'
import { Tabs ,Button} from 'antd';
import axios from 'axios'
import {URL} from  '../config/url'
const TabPane = Tabs.TabPane;
class CompleteInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token:'',
            userData:'',
            navs:['1：基本资料','2：附加信息','3：车辆信息','服务说明','5：个人简介'],
            index:1,
            urlIndex:1
        }
    }
    async componentDidMount(){
        // sessionStorage.setItem('userToken','AUTH__APP_1052_e0471a18-fa2a-4d82-8090-32e7ceefc14c')
        // sessionStorage.setItem('guideId','5033')
        let url=this.props.history.location.search
        if(!sessionStorage.getItem('userToken')){
            window.location='/'
            return false
        }
        let res= await axios.post(`${URL}/AUTH/loginByApp?appId=1058&token=71b0eea0fe5ece3d9818ce07b343ef24`)
        if(sessionStorage.getItem('guideId')){
            let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/guide/detail?guideId=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
            this.setState({
                userData:data.data.data,
            })
        }
       await this.setState({
            token:res.data.data,
            urlIndex:url?url.slice(url.indexOf('=')+1)*1+1:1
        })
    }
  async  goToNext(num){
        if(num==1){
            let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/guide/detail?guideId=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
            this.setState({
                userData:data.data.data,
            })
        }
        this.setState({
            urlIndex:num+1
        })
    }
   async tabChange(i){
    //     let {index}=this.state
        
    //    if(i){
    //     index++
    //   await  this.setState({
    //         index
    //     })
    //    }else{
    //        index--
    //        await  this.setState({
    //         index
    //     })
    //    }
    }
    onTabClick(e){
        this.setState({
            urlIndex:e
        })
    }
    componentWillReceiveProps(e){
        console.log(e)
    }
    render() {
        let {token,userData,index,urlIndex}=this.state
        return (
            <div className='infoPage'>
                <div className='headStep'>
                    <Tabs  defaultActiveKey={`${index}`} activeKey={`${urlIndex}`} onTabClick={this.onTabClick.bind(this)}  onNextClick={this.tabChange.bind(this,true)} onPrevClick={this.tabChange.bind(this,false)}>
                                <TabPane tab={'1：基本资料'} key={`1`} disabled={!sessionStorage.getItem('guideId')}>
                                    {token&&<BasicInformation token={token} userData={userData} goToNext={this.goToNext.bind(this)}/>}
                                </TabPane>
                                <TabPane tab={'2：附加信息'} key={`2`} disabled={!sessionStorage.getItem('guideId')}>
                                    {token&&<BaseInfo token={token} userData={userData} goToNext={this.goToNext.bind(this)}/>}
                                </TabPane>
                                <TabPane tab={'3：服务信息'} key={`3`} disabled={!sessionStorage.getItem('guideId')}>
                                    {token&&<ServiceDes token={token} userData={userData} goToNext={this.goToNext.bind(this)}/>}
                                </TabPane>
                                {userData.companyId==3525&&<TabPane tab={'4：服务费用'} key={`4`} disabled={!sessionStorage.getItem('guideId')}>
                                    {token&&<ServiceFee token={token} userData={userData} goToNext={this.goToNext.bind(this)}/>}
                                </TabPane>}
                                <TabPane tab={`${userData.companyId==3525?'5':'4'}：个人故事`} key={`${userData.companyId==3525?'5':'4'}`} disabled={!sessionStorage.getItem('guideId')}>
                                    {token&&userData&&<PersonInfo token={token} userData={userData} goToNext={this.goToNext.bind(this)}/>}
                                </TabPane>
                                <TabPane tab={`${userData.companyId==3525?'6':'5'}：车辆信息`} key={`${userData.companyId==3525?'6':'5'}`} disabled={!sessionStorage.getItem('guideId')}>
                                    {token&&<ServiceCar token={token} userData={userData} goToNext={this.goToNext.bind(this)}/>}
                                </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}
export default CompleteInfo