import React, { Component } from 'react';
import '../App.less'
import codeSrc from '../images/dmLogo.png'
import car from '../images/car.png'
import guide from '../images/guide.png'
class Welcome extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    
    render() {
        return (
            <div className='welcomePage'>
                <div className='logo'>
                    <img src={codeSrc} alt=""/>
                    <div className='name'>
                        <div className='f15 fb fc66'>欢迎来到达美</div>
                        <div className='f24 fb'>全球向导中心</div>
                    </div>
                </div>
                <div className='content'>
                    <div><a className='person' href='/#/groupApply'>车导团队申请</a></div>
                    <div><a className='person' href='/#/login'>个人向导登录</a></div>
                </div>
                <div className='contentM'>
                        <a className='logoItem' href='/#/groupApply'>
                            <img src={car} alt=""/>
                            <div className='fb'>车导团队申请</div>
                        </a>
                        <a className='logoItem' href='/#/login'>
                            <img src={guide} alt="" className='guide'/>
                            <div className='fb'>个人向导登录</div>
                        </a>
                        <div className='expectItem'></div>
                </div>
            </div>
        );
    }
}
export default Welcome