import React, { Component } from 'react';
import { Select,message ,Modal ,Button} from 'antd';
import {URL} from  '../config/url';
import axios from 'axios';
const Option = Select.Option;
let options={
    top:450,
    duration:1.5,
}
message.config(options)
class GuideGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params:{},
            citysTxt:[],
            groupTxt:'',
            token:''
            }
    }
    onChange(key,e){
        let {params}=this.state
        params[key]=e.target.value
        this.setState({
            params
        })
    }
    handleChange(key,value,e) {
        let {params}=this.state
        if([key]=='endDistrictDate'){
            params[key]=e
        }else{
            params[key]=value
        }
        this.setState({
            params
        })
    }
    async componentDidMount(){
        let res= await axios.post(`${URL}/AUTH/loginByApp?appId=1058&token=71b0eea0fe5ece3d9818ce07b343ef24`)
        let token=res.data.data;
        this.setState({
            token:token
        })
        if(localStorage.getItem('paramsInfo')){
            this.setState({
                params:JSON.parse(localStorage.getItem('paramsInfo')),
                citysTxt:JSON.parse(localStorage.getItem('citysTxt')),
                groupTxt:localStorage.getItem('groupTxt')
        })
        }
    }
    saveInfo(){
        let {params,token}=this.state
        if(localStorage.getItem('groupId')){
            params['id']=localStorage.getItem('groupId')
        }
        var param = new URLSearchParams();
        for (const key in params) {
            param.append([key],params[key])
        }
        
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/group/save`,
            method: 'post',
            headers:{'DM_AUTH':token},
            data: param
          }).then((res)=>{
            if(res.data.success){
                    localStorage.setItem('groupId',`${res.data.data.id}`)
                    Modal.success({
                        title: '您的申请已成功提交',
                        content: <div>请添加微信号<span style={{color:'#f0a507'}}>18501679551</span>查询结果</div>,
                    });
            }
          })
    }
    backModify(){
        window.scrollTo(0,0)
        window.location='/#/groupApply?modify'
    }
    render() {
        let {params,groupTxt,citysTxt}=this.state
        return (
            <div className='baseInfo serviceInfo groupApply'>
                <div className='infos'>
                    <div className='infoItem'>
                        <div className='inputItem'>
                            <span className='label_'>车队性质：</span>
                            <span>{groupTxt}</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>车队名称：</span>
                            <span>{params['motorcadeName']}</span>
                        </div>
                        <div className='inputItem' id='dest'>
                            <span className='label_'>服务城市：</span>
                            {citysTxt.map((city,c)=>{
                                return(
                                    <span key={c}>{city}{c!=citysTxt.length-1?',':''}</span>
                                )
                            })}
                        </div>
                        
                        <div className='inputItem'>
                            <span className='label_'>联系人手机号：</span>
                            <span>{params['phoneArea']}{params['phone']}</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>联系人：</span>
                            <span>{params['personCharge']}</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>联系人微信：</span>
                            <span>{params['personChargeWechat']}</span>
                        </div>
                        <div className='inputItem' style={{display:'flex',wordBreak:'break-all'}}>
                            <div className='label_' >团队介绍：</div>
                            <div style={{flex:1}}>{params['groupDescribe']}</div>
                        </div>
                    </div>
                    <div className='alignRight'>您也可以添加微信号：18501679551，与我们取得联系</div>
                    <div className='alignRight mt10'>
                        <Button  type="primary" className='saveBtn mr15 modifyBtn' onClick={this.backModify.bind(this)}>修改</Button>
                        <Button  type="primary" className='saveBtn mr15'  onClick={this.saveInfo.bind(this)}>提交</Button>
                    </div>
                </div>
            </div>
        );
    }
}
export default GuideGroup