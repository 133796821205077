import React, { Component } from "react";
import { Upload, Icon, Modal } from "antd";
import {URL} from '../config/url'
import axios from 'axios'
class Uploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      fileList: []
    };
  }
  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  handleChange({fileList}){
    this.props.getGuideImg(fileList)
    this.setState({ fileList })
  }
 async componentDidMount(){
    await  this.setState({
          fileList:this.props.guideImgs
      })
  }
//   handleChange = ({ fileList }) => this.setState({ fileList })
  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
        <div className="clearfix mt20" style={{marginLeft:'100px'}}>
            <Upload
            action={`${URL}/SERVICE-MT-GUIDE/img/save?DM_AUTH=${sessionStorage.getItem('userToken')}&basePath=guide/img/guideCard`}
            listType="picture-card"
            fileList={fileList}
            onPreview={this.handlePreview}
            onChange={this.handleChange.bind(this)}
            >
            {fileList.length >= 100 ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    );
  }
}
export default Uploads;
