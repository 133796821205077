import React, { Component } from 'react';
import { Select,message ,Input ,Button} from 'antd';
import {countrys} from  '../config/countrys'
import {URL} from  '../config/url';
import axios from 'axios';
const Option = Select.Option;
let options={
    top:450,
    duration:1.5,
}
message.config(options)
class GuideGroup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token:'',
            dests:[],
            groupTxt:'',
            citysTxt:[],
            countryArr:[],
            groupType:[{'name':'地接社','id':1},{'name':'车队公司','id':2},{'name':'导游团队','id':3}],
            params:{id:'','nature':'','motorcadeName':'','phoneArea':'','phone':'','personCharge':'','personChargeWechat':'','groupDescribe':'','serviceCity':[]},
            keys:{'nature':'团队性质','motorcadeName':'团队名称','phoneArea':'手机区号','phone':'手机号','personCharge':'联系人','personChargeWechat':'联系人微信','groupDescribe':'团队介绍','serviceCity':'服务城市'},
            }
    }
    onChange(key,e){
        let {params}=this.state
        params[key]=e.target.value
        this.setState({
            params
        })
    }
   async handleChange(key,value,e) {
        let {params,groupTxt,citysTxt}=this.state
        params[key]=value
        if(key=='nature'){
            groupTxt=e.props.children
        }else if(key=='serviceCity'){
            citysTxt=[];
            e.map((el)=>{
                citysTxt.push(el.props.children)
                
            })
        }
      await  this.setState({
            params,
            groupTxt,
            citysTxt
        })
    }
    searchArea(e){
        let {params}=this.state
        params['phoneArea']=e
        this.setState({
            params
        }) 
    }
    async componentDidMount(){
        let {params,groupTxt,citysTxt}=this.state
       if(this.props.location.search=="?modify"){
        if(localStorage.getItem('paramsInfo')){
            params=JSON.parse(localStorage.getItem('paramsInfo'))
            groupTxt=localStorage.getItem('groupTxt')
            citysTxt=JSON.parse(localStorage.getItem('citysTxt'))
        }
       }else{
        localStorage.setItem('paramsInfo','')
        localStorage.setItem('citysTxt','')
        localStorage.setItem('groupTxt','')
        localStorage.setItem('groupId','')
       }
        let res= await axios.post(`${URL}/AUTH/loginByApp?appId=1058&token=71b0eea0fe5ece3d9818ce07b343ef24`)
        let token=res.data.data;
        let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getForeignCity?name=&types=${['c']}`,{headers:{'DM_AUTH':token}})
        let countrys= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getAreaCodeByCodeOrName?countryArea=`,{headers:{'DM_AUTH':token}})
        await this.setState({
            dests:data.data.data,
            countryArr:countrys.data.data,
            token:token,
            params,
            citysTxt,
            groupTxt
        })
    }
  async  seacrVal(e){
      let _this=this
      if(e.search(/[a-zA-Z]+/)==-1){
          let value=document.querySelectorAll('#citys .ant-select-search__field')[0].value
            if(value){
                let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getForeignCity?name=${value}&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
                this.setState({
                    countryss:res.data.data,
                    word:value
                })
            }
      }
    }
    async  seacrVals(e){
        if(e.search(/[a-zA-Z]+/)==-1){
            let value=document.querySelectorAll('#dest .ant-select-search__field')[0].value
           if(value){
            let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getForeignCity?name=${value}&types=${['c']}`,{headers:{'DM_AUTH':this.state.token}})
            await this.setState({
                    dests:res.data.data
            })
           }
        }
    }
    saveInfo(){
        let {params,keys,citysTxt,groupTxt}=this.state
        if(params['phoneArea'].indexOf('（')!=-1){
            params['phoneArea']=params['phoneArea'].slice(0,params['phoneArea'].indexOf('（'))
        }
        if(params['groupDescribe'].length<30){
            message.warning('团队介绍最少30个字符')
            return false;
        }else if(params['groupDescribe'].length>300){
            message.warning('团队介绍最多300个字符')
            return false;
        }
        for (const key in params) {
            if(params[key].length==0&&key!='id'){
                message.warning(`请填写或选择${keys[key]}`)
                return false;
            }
        }
        window.scrollTo(0,0)
        localStorage.setItem('paramsInfo',JSON.stringify(params))
        localStorage.setItem('citysTxt',`${JSON.stringify(citysTxt)}`)
        localStorage.setItem('groupTxt',`${groupTxt}`)
        window.location=`/#/previewPage?save`
    }

    render() {
        let {dests,params,groupType,countryArr}=this.state
        return (
            <div className='baseInfo serviceInfo groupApply'>
                <div className='infos'>
                    <div className='infoItem'>
                        <div className='title f20'>车导团队信息</div>
                        <div className='cover'></div>
                        <div className='inputItem'>
                            <span className='label_'>车队性质：</span>
                            <Select style={{ width: 230 }} onChange={this.handleChange.bind(this,'nature')} showArrow={true} value={params['nature']}>
                                {groupType.map((group,e)=>{
                                        return(
                                            <Option value={group.id} key={e}>{group.name}</Option>
                                        )
                                    })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>车队名称：</span>
                            <Input  placeholder='请输入车队名称' className='mt20' style={{ width: 230 }} value={params['motorcadeName']} onChange={this.onChange.bind(this,'motorcadeName')}/>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem' id='dest'>
                            <span className='label_'>服务城市：</span>
                            <Select  style={{ width: 230 }} onSearch={this.seacrVals.bind(this)} optionFilterProp={'data-name'}   onChange={this.handleChange.bind(this,'serviceCity')} value={params['serviceCity']} showArrow={true} mode="multiple">
                                {dests.length>0&&dests.map((dest,d)=>{
                                    return(
                                        <Option value={dest.id} key={d} data-name={dest.name}>{dest.name}</Option>
                                    )
                                })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        
                        <div className='inputItem'>
                        <span className='label_'>联系人手机号：</span>
                            <Select className='area_code mt5 border mr10'   
                            placeholder='区号' style={{width:100}} 
                            showSearch
                            defaultActiveFirstOption={false}
                            onSearch={this.searchArea.bind(this)} 
                            optionFilterProp="value"
                            filterOption={(input, option) =>
                                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            value={params['phoneArea']} onChange={this.handleChange.bind(this,'phoneArea')}>
                                {countryArr.map((item,i)=>{
                                    return(
                                            <Option value={`${item.countryAreaNum}（${item.countryAreaName}）`} key={i} title={item.countryAreaName}>{item.countryAreaNum}（{item.countryAreaName}）</Option>
                                        )
                                })}
                            </Select>
                            <Input placeholder='请输入手机号' style={{width:120}} onChange={this.onChange.bind(this,'phone')} value={params['phone']}/>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>联系人：</span>
                            <Input  placeholder='请输入联系人' className='mt20' style={{ width: 230 }} onChange={this.onChange.bind(this,'personCharge')} value={params['personCharge']}/>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>联系人微信：</span>
                            <Input  placeholder='请输入联系人微信' className='mt20' style={{ width: 230 }} value={params['personChargeWechat']} onChange={this.onChange.bind(this,'personChargeWechat')}/>
                            <span className='mustIcon'>*</span>
                        </div>
                    </div>
                    <div className='infoItem'>
                        <div className='title f20'>团队介绍</div>
                        <div className='cover'></div>
                        <textarea style={{width:'100%',border:'none'}} onChange={this.onChange.bind(this,'groupDescribe')}  value={params['groupDescribe']} name="" id="" cols="30" rows="10" placeholder='组建年月，团队优势，自营导游数量，自有车型及数量（30-300字符）'></textarea>
                    </div>
                    <div className='alignRight'>您也可以添加微信号：18501679551，与我们取得联系</div>
                    <div className='alignRight mt10'>
                        <Button  type="primary" className='saveBtn mr15' onClick={this.saveInfo.bind(this)}>保存</Button>
                    </div>
                </div>
            </div>
        );
    }
}
export default GuideGroup