import React, { Component } from 'react';
import { Progress,Tabs } from 'antd';
const TabPane = Tabs.TabPane;
class Step extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navs:['基本资料','附加信息','可服务车辆','向导价格','个人简介'],
        }
    }
    render() {
        let {navs}=this.state
        let {activeStep}=this.props
        let navBox=[]
        let index=0
        navs.map((nav,n)=>{
            navBox.push(<span key={n} className={n==activeStep?'navItem f18 active':'navItem f18 '} onClick={(()=>{
                this.props.changeIndex(n)
            })}>{nav}</span>)
        })
        return (
            <div className='headStep'>
            <Tabs defaultActiveKey="1">
                {navs.map((nav,n)=>{
                    return(
                        <TabPane tab={nav} key={n}></TabPane>
                    )
                })}
            </Tabs>
                <div className='mb20'>
                    {navBox}
                </div>
                <Progress percent={20*activeStep+20} showInfo={false} strokeWidth={4}/>
            </div>
        );
    }
}
export default Step