import React, { Component } from 'react';
import { Modal,Select ,message,Button } from 'antd';
import Editors from '../components/Editor'
import Upload from '../components/Upload'
import {URL} from  '../config/url';
import LimitUpload from './LimitUpload'
import axios from 'axios'
import Cropper from '../pages/test'
import { error } from 'util';
const Option = Select.Option;
let options={
    top:450,
    duration:1.5,
}
message.config(options)
class PersonInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params:{'headImgUrl':{'file':this.props.userData.headImgUrl},'coverImgUrl':{'file':this.props.userData.coverImgUrl},
            'travelMessage':''},
            paramTxt:{'headImgUrl':'请上传头像','coverImgUrl':'请上传封面头像','myStoryOne':'请填写我的故事1',
            'myStoryTwo':'请填写我的故事2','myCityStoryOne':'请填写我与城市的故事1','myCityStoryTwo':'请填写我与城市的故事2',
            'myStoryOneImgUrl':'请上传我的故事1图片','myStoryTwoImgUrl':'请上传我的故事2图片','myCityStoryOneImgUrl':'请填写我与城市的故事1图片',
            'myCityStoryTwoImgUrl':'请填写我与城市的故事2图片',
            'travelMessage':'请填写旅行寄语','page':4},
            txt:'',
            visible:false,
            isNext:'',
            myStoryStr:{}
        }
    }
    onChange(e){
        let {params}=this.state
        params['travelMessage']=e.target.value
        this.setState({
            params
        })
    }
    handleChange(value) {
        console.log(`selected ${value}`);
    }
    getImgUrl(url,key,index){
        let {params}=this.state
        params[key]=url
        this.setState({
            params
        })
    }
    getValue(value){
        this.setState({
            txt:value
        })
    }
   async componentDidMount(){
        let {params}=this.state
        let {userData}=this.props
        for (const key in params) {
            if(key=='headImgUrl'||key=='coverImgUrl'){
                if(userData[key]){
                    params[key]={'file':userData[key]}
                }
            }else{
                if(userData[key]){
                    params[key]=userData[key]
                }
            }
        }
    let myStoryJson
      if(userData.myStory){
        myStoryJson=JSON.parse(userData.myStory)
      }else{
        myStoryJson={story:[{'storyText':'','storyImg':[],'paramImg':[]}],city:[{'cityText':'','cityImg':[],'paramImg':[]}],group:[{'groupText':'','groupImg':[],'paramImg':[]}]}
      }
      params.personDescUrl=userData.personDescUrl
      for (const key in myStoryJson) {
          myStoryJson[key].map((item)=>{
               item['paramImg']=[]
                 item[key+'Img'].map((img,i)=>{
                let list={'url':img,'status':'done','uid':i}
                item['paramImg'].push(list)
              })
              
          })
      }
      await  this.setState({
            params,
            myStoryStr:myStoryJson
        })
    }
    addMyStory(num){
        let {myStoryStr}=this.state
        if(num==1){
            myStoryStr.story.push({'storyText':'','storyImg':[],'paramImg':[]})
        }else if(num==2){
            myStoryStr.city.push({'cityText':'','cityImg':[],'paramImg':[]})
        }else if(num==3){
            myStoryStr.group.push({'groupText':'','groupImg':[],'paramImg':[]})
        }
        this.setState({
            myStoryStr
        })
    }
    hideModal(){
        this.setState({
            visible:false,
        })
    }
    showModal(isNext){
        this.setState({
            visible:true,
            isNext
        })
    }
    confirmModify(){
        this.setState({
            visible:false,
        })
        let {params,txt,paramTxt,isNext}=this.state
        params.page=16
        params['status']=1
        params.id=sessionStorage.getItem('guideId')
        if(params.headImgUrl.file){
            params.headImgUrl=params.headImgUrl.file
        }
        if(params.coverImgUrl.file){
            params.coverImgUrl=params.coverImgUrl.file
        }
        for (const key in params) {
            if(key=='myStoryOneImgUrl'||key=='myStoryTwoImgUrl'||key=='myCityStoryOneImgUrl'||key=='myCityStoryTwoImgUrl'){
                let arr=[]
                params[key].map((img)=>{
                    if(img.url){
                        arr.push(img.url)
                    }else{
                        arr.push(img)
                    }
                    params[key]=arr
                })
            }
        }
        var param = new URLSearchParams();
        for (const key in params) {
            param.append([key],params[key])
            if(!params[key]){
                message.warning(`${paramTxt[key]}`)
                   return false
            }else{
                if(params['myStoryOne'].length<30){
                    message.warning('我的故事1字数不能少于30')
                    return false
                }else if(params['myStoryOne'].length>500){
                    message.warning('我的故事1字数不能超过500')
                    return false
                }else if(params['myStoryTwo'].length<30){
                    message.warning('我的故事2字数不能少于30')
                    return false
                }else if(params['myStoryTwo'].length>500){
                    message.warning('我的故事2字数不能超过500')
                    return false
                }else if(params['myCityStoryOne'].length<30){
                    message.warning('我与城市的故事1字数不能少于30')
                    return false
                }else if(params['travelMessage'].length>30){
                    message.warning('旅行寄语字数不能超过30')
                    return false
                }else if(params['myCityStoryTwo'].length>500){
                    message.warning('我与城市的故事2字数不能超过500')
                    return false
                }
            }
            
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                
                // window.location='/#/userInfo'
                if(isNext!=0){
                    this.props.goToNext(4)
                    window.scrollTo(0,0)
                }else{
                    message.success('保存成功')
                }
            }
          })
    }
    saveInfo(num){
        if(num==1){
            if(this.props.userData.companyId==3525){
                this.props.goToNext(3)
            }else{
                this.props.goToNext(2)
            }
            window.scrollTo(0,0)
            return false
        }
        if(this.props.userData.status==2){
            this.showModal(num)
            return false;
        }
        let {params,txt,paramTxt,myStoryStr}=this.state
        // params.personDescUrl=txt
        params.page=16
        params.id=sessionStorage.getItem('guideId')
        if(params.headImgUrl.file){
            params.headImgUrl=params.headImgUrl.file
        }
        if(params.coverImgUrl.file){
            params.coverImgUrl=params.coverImgUrl.file
        }
        // for (const key in params) {
        //     if(key=='myStoryOneImgUrl'||key=='myStoryTwoImgUrl'||key=='myCityStoryOneImgUrl'||key=='myCityStoryTwoImgUrl'){
        //         let arr=[]
        //         params[key].map((img)=>{
        //             if(img.url){
        //                 arr.push(img.url)
        //             }else{
        //                 arr.push(img)
        //             }
        //             params[key]=arr
        //         })
        //     }
        // }
        let myStory={}
        for (const key in myStoryStr) {
            myStory[key]=[]
            myStoryStr[key].map((str)=>{
                let imgs=[]
                str['paramImg'].map((img)=>{
                    if(img.url){
                        imgs.push(img.url)
                    }else{
                        imgs.push(img)
                    }
                })
                let item={[key+'Img']:imgs,[key+'Text']:str[key+'Text']}
                myStory[key].push(item)
            })
        }
        let msg
        if(myStoryStr){
            myStoryStr.story.map((sto)=>{
                if(!sto.storyText||sto.storyText.length<30){
                    msg='我的故事字数不能少于30'
                }else if(sto.storyText.length>500){
                    msg='我的故事字数不能大于500'
                }
            })
            myStoryStr.group.map((sto)=>{
                if(!sto.groupText||sto.groupText.length<30){
                    msg='我的团组字数不能少于30'
                }else if(sto.groupText.length>500){
                    msg='我的团组字数不能大于500'
                }
            })
            myStoryStr.city.map((sto)=>{
                if(!sto.cityText||sto.cityText.length<30){
                    msg='我与城市的故事字数不能少于30'
                }else if(sto.cityText.length>500){
                    msg='我与城市的故事字数不能大于500'
                }
            })
        }else{
            myStory=''
        }
        if(msg){
            message.warning(msg)
            return false
        }
        params['myStory']=JSON.stringify(myStory)
        var param = new URLSearchParams();
        for (const key in params) {
            param.append([key],params[key])
            if(!params[key]&&key!='personDescUrl'){
                message.warning(`${paramTxt[key]}`)
                   return false
            }else{
                if(params['travelMessage'].length>30){
                        message.warning('旅行寄语字数不能超过30')
                        return false
                    }
            }
            
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                
                // window.location='/#/userInfo'
                if(num!=0){
                    if(this.props.userData.companyId==3525){
                        this.props.goToNext(5)
                    }else{
                        this.props.goToNext(4)
                    }
                    window.scrollTo(0,0)
                }else{
                    message.success('保存成功')
                }
            }
          })
    }
    getGuideImg(index,imgs){
        let arr=[]
        let {params}=this.state
        imgs.map((img)=>{
            if(img.response){
                arr.push(img.response.data)
            }else{
                arr.push(img.url)
            }
        }) 
        if(index==0){
            params['myStoryOneImgUrl']=arr
        }else if(index==1){
            params['myStoryTwoImgUrl']=arr
        }else if(index==2){
            params['myCityStoryOneImgUrl']=arr
        }else if(index==3){
            params['myCityStoryTwoImgUrl']=arr
        }
        this.setState({
            params
        })
    }
    inpuVal(index,e){
        let {params}=this.state
        if(index==0){
            params['myStoryOne']=e.target.value
        }else if(index==1){
            params['myStoryTwo']=e.target.value
        }else if(index==2){
            params['myCityStoryOne']=e.target.value
        }else if(index==3){
            params['myCityStoryTwo']=e.target.value
        }
        this.setState({
            params
        })
    }
    async changeImg(url,type){
        let {params}=this.state
        let _this=this;
       let paramss=type==0?{'headImgUrl':url,'page':16,'id':sessionStorage.getItem('guideId')}:{'coverImgUrl':url,'page':16,'id':sessionStorage.getItem('guideId')}
       var param = new URLSearchParams();
           for (const key in paramss) {
               param.append([key],paramss[key])
           }
         axios({
           url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
           method: 'post',
           headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
           data: param
         }).then((res)=>{
           if(res.data.success){
             if(type==0){
                params.headImgUrl=url
             }else{
                params.coverImgUrl=url
             }
             _this.setState({
                params
             })
           }
         })
       }
      async headImgChange(e){
        let {params}=this.state
        var formData = new FormData();
        formData.append("file",document.getElementById("headImg").files[0]);
       await axios({
          url: `${URL}/SERVICE-MT-GUIDE/img/save?basePath=headImgUrl`,
          method: 'post',
          headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
          data: formData,
        }).then((res)=>{
        params.headImgUrl=res.data.data
          this.setState({
            params
          })
        })
        let paramss={'headImgUrl':params.headImgUrl,'page':16,'id':sessionStorage.getItem('guideId')}
        var param = new URLSearchParams();
            for (const key in paramss) {
                param.append([key],paramss[key])
            }
        axios({
          url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
          method: 'post',
          headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
          data: param
        }).then((res)=>{
          if(res.data.success){
             
          }
        })
       }
       myStoryInpuVal(index,e){
         let {myStoryStr}=this.state
         myStoryStr.story[index].storyText=e.target.value
         this.setState({
             myStoryStr
         })
       }
       getGuideStoryImg(index,imgs){
        let arr=[]
            let {myStoryStr}=this.state
            imgs.map((img)=>{
                if(img.response){
                    arr.push(img.response.data)
                }else{
                    arr.push(img.url)
                }
            }) 
          myStoryStr.story[index].paramImg=arr
          this.setState({
            myStoryStr
          })
       }
       myCityInpuVal(index,e){
        let {myStoryStr}=this.state
        myStoryStr.city[index].cityText=e.target.value
        this.setState({
            myStoryStr
        })
      }
      getGuideCityImg(index,imgs){
           let arr=[]
           let {myStoryStr}=this.state
           imgs.map((img)=>{
               if(img.response){
                   arr.push(img.response.data)
               }else{
                   arr.push(img.url)
               }
           }) 
         myStoryStr.city[index].paramImg=arr
         this.setState({
           myStoryStr
         })
      }
      myGroupInpuVal(index,e){
        let {myStoryStr}=this.state
        myStoryStr.group[index].groupText=e.target.value
        this.setState({
            myStoryStr
        })
      }
      getGuideGroupImg(index,imgs){
           let arr=[]
           let {myStoryStr}=this.state
           imgs.map((img)=>{
               if(img.response){
                   arr.push(img.response.data)
               }else{
                   arr.push(img.url)
               }
           }) 
            myStoryStr.group[index].paramImg=arr
            this.setState({
            myStoryStr
            })
      }
    deleteMyStory(num,index){
        let {myStoryStr}=this.state
        if(num==1){
            myStoryStr.story.splice(index,1)
        }else if(num==2){
            myStoryStr.city.splice(index,1)
        }else if(num==3){
            myStoryStr.group.splice(index,1)
        }
        this.setState({
            myStoryStr
        })
    }
    render() {
        let {params,myStoryStr}=this.state
        let show
        if(params['headImgUrl']){
            show=true
            if(params['headImgUrl']['file']!=null){
                show=true
            }else{
                show=false
            }
        }else{
            show=false
        }
        return (
            <div className='baseInfo personInfo'>
                <div className='infos'>
                <div className='infoItem'>
                        <div className='title f20'>照片</div>
                        <div className='cover'></div>
                        {/* <div className='inputItem  mShow'>
                           <div>
                           <div>*头像尺寸不得小于200*260</div>
                           <Upload label={'头像'}   getImgUrl={this.getImgUrl.bind(this)} keys={'headImgUrl'} index='0' imgs={params['headImgUrl']['file']?params['headImgUrl']:null} basePath={"guide/img/head"}></Upload>
                            
                           </div>
                            <div>
                            <div>*封面照最佳尺寸285*166</div>
                            <Upload label={'封面照'}  getImgUrl={this.getImgUrl.bind(this)} keys={'coverImgUrl'} index='1' imgs={params['coverImgUrl']['file']?params['coverImgUrl']:null} basePath={"guide/img/cover"}></Upload>
                            </div>
                        </div> */}
                        <div className="inputItem  cropperWrap">
                                <div className='mobileHide'>
                                    <div style={{marginBottom:'20px'}}>*头像尺寸不得小于300*300 </div>
                                    <span style={{position:'relative',display:'flex',width:'160px'}} className='mt20'>
                                    <span style={{lineHeight:'100px',width:'60px'}}>头像：</span>
                                    <span className='pcUpload'>
                                        {/* <div style={{position:'absolute',width:'100%',textAlign:'center',top:'40px'}}>点击可上传</div> */}
                                        <Cropper prop={1}  imgChange={this.changeImg.bind(this)} type={0}></Cropper>
                                    </span>
                                    <img src={params['headImgUrl']['file']?params['headImgUrl']['file']:params['headImgUrl']} alt="" style={{width:'100px',height:'100px',position:'relative'}}/>
                                    </span>
                                    <div style={{textAlign:'center',marginLeft:'60px'}}>点击可上传</div>
                                </div>
                                <div className='mobileShow'>
                                    <div>*头像尺寸不得小于300*300</div>
                                    <span style={{lineHeight:'100px',width:'60px'}}>头像：</span>
                                    <span style={{display:'inline-block',width:'100px',height:'100px',border:'1px solid #eaeaea',verticalAlign:'bottom'}} className='mt20'>
                                        <img src={params['headImgUrl']['file']?params['headImgUrl']['file']:params['headImgUrl']} alt="" style={{width:'100px',height:'100px',position:'relative'}}/>
                                    </span>
                                    {/* <div style={{position:'absolute',width:'100px',textAlign:'center',top:'100px',right:'10px',display:show?'none':'block'}}>点击可上传</div> */}
                                    <input type="file" onChange={this.headImgChange.bind(this)} className='headImgHide' id='headImg'/>
                                    <div style={{textAlign:'center',marginLeft:'42px'}}>点击可上传</div>
                                    {/* <Upload label={'头像'}   getImgUrl={this.getImgUrl.bind(this)} keys={'headImgUrl'} index='0' imgs={params['headImgUrl']['file']?params['headImgUrl']:null} basePath={"guide/img/head"}></Upload> */}
                            
                                </div>
                                <div className='coverImg'>
                                    <div> *封面照尺寸不得小于800*600</div>
                                    <span style={{position:'relative',display:'flex',width:'180px'}} className='mt20'>
                                    <span style={{lineHeight:'90px',width:'60px'}}>封面照：</span>
                                    <span className='pcUpload'>
                                    {/* <div style={{position:'absolute',width:'100%',textAlign:'center',top:'35px'}}>点击可上传</div> */}
                                    <Cropper prop={4/3} imgChange={this.changeImg.bind(this)} type={1}></Cropper></span>
                                    <img src={params['coverImgUrl']['file']?params['coverImgUrl']['file']:params['coverImgUrl']} alt="" style={{width:'120px',height:'90px',position:'relative'}}/>
                                    </span>
                                    <div style={{textAlign:'center',marginLeft:'60px'}}>点击可上传</div>
                                </div>
                        </div>
                    </div>
                    <div className='infoItem'>
                        <div className='title f20'>旅行寄语</div>
                        <div className='cover'></div>
                        <textarea name="" id="" cols="30" rows="10" placeholder='最多30个字符' onChange={this.onChange.bind(this)} value={params['travelMessage']} style={{width:'100%'}}></textarea>
                    </div>
                    <div className='infoItem' style={{padding:'40px 20px'}}>
                        <div className='title f20'>个人故事</div>
                        <div className='cover'></div>
                        {/* <Editors getValue={this.getValue.bind(this)} values={params['personDescUrl']}></Editors> */}
                        <div style={{border:'1px solid #dadada',padding:'10px 20px'}}>
                            <h3 className='fb'>我的故事</h3>
                            {myStoryStr&&myStoryStr.story&&myStoryStr.story.map((str,ss)=>{
                                return(
                                <div key={ss}>
                                        <textarea name="" id="" cols="30" rows="10" style={{width:'100%'}} onChange={this.myStoryInpuVal.bind(this,ss)} value={str['storyText']} placeholder='请填写我的故事1，30-500个字符'></textarea>
                                        {/* <div style={{display:'flex'}}> */}
                                        <LimitUpload getGuideImg={this.getGuideStoryImg.bind(this,ss)} guideImgs={str['paramImg']} basePath={'guide/img/storyone'}></LimitUpload>
                                        {ss!=0&&<Button type='primary' onClick={this.deleteMyStory.bind(this,1,ss)} style={{display:'inline-block'}} className='mb10'>删除此条</Button>}
                                        {/* </div> */}
                                </div>
                                )
                            })}
                            <Button type='primary' onClick={this.addMyStory.bind(this,1)}>新增我的故事</Button>
                        </div>
                        <div style={{border:'1px solid #dadada',padding:'10px 20px'}} className='mt10'>
                            <h3 className='fb' style={{paddingTop:'5px'}}>我与城市的故事</h3>
                            {myStoryStr&&myStoryStr.city&&myStoryStr.city.map((city,c)=>{
                                return(
                                    <div key={c}>
                                        <textarea name="" id="" cols="30" rows="10" style={{width:'100%'}} onChange={this.myCityInpuVal.bind(this,c)} value={city['cityText']} placeholder='请填写我与城市的故事，30-500个字符'></textarea>
                                        <LimitUpload getGuideImg={this.getGuideCityImg.bind(this,c)} guideImgs={city['paramImg']} basePath={'guide/img/cityone'}></LimitUpload>
                                       {c!=0&& <Button type='primary' onClick={this.deleteMyStory.bind(this,2,c)} style={{display:'inline-block'}} className='mb10'>删除此条</Button>}
                                    </div>
                                )
                            })}
                            <Button type='primary' onClick={this.addMyStory.bind(this,2)}>新增我与城市的故事</Button>
                        </div>
                        <div style={{border:'1px solid #dadada',padding:'10px 20px'}} className='mt10'>
                            <h3 className='fb' style={{paddingTop:'5px'}}>我带过的团组</h3>
                            {myStoryStr&&myStoryStr.group&&myStoryStr.group.map((group,g)=>{
                                return(
                                    <div key={g}>
                                        <textarea name="" id="" cols="30" rows="10" style={{width:'100%'}} onChange={this.myGroupInpuVal.bind(this,g)} value={group['groupText']} placeholder='请填写我带过的团组，30-500个字符'></textarea>
                                        <LimitUpload getGuideImg={this.getGuideGroupImg.bind(this,g)} guideImgs={group['paramImg']} basePath={'guide/img/citytwo'}></LimitUpload>
                                       { g!=0&&<Button type='primary' onClick={this.deleteMyStory.bind(this,3,g)} style={{display:'inline-block'}} className='mb10'>删除此条</Button>}
                                    </div>
                                )
                            })}
                            <Button type='primary' onClick={this.addMyStory.bind(this,3)}>新增我带过的团组</Button>
                        </div>
                    </div>
                    <div className='alignRight'>
                        <Button onClick={this.saveInfo.bind(this,1)} type="primary" className='saveBtn mr15'>上一页</Button>
                        <Button onClick={this.saveInfo.bind(this,0)} type="primary" className='saveBtn mr15' >保存</Button>
                        <Button onClick={this.saveInfo.bind(this)} type="primary" className='saveBtn'>保存并下一页</Button>
                    </div>
                </div>
                <Modal
                    title="温馨提示："
                    visible={this.state.visible}
                    onOk={this.confirmModify.bind(this)}
                    onCancel={this.hideModal.bind(this)}
                    okText="确认"
                    cancelText="取消">
                    <div>是否重新保存？重新保存后需重新审核。</div>
                </Modal>
            </div>
        );
    }
}
export default PersonInfo