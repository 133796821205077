import React, { Component } from 'react';
import { DatePicker,Input,Button,Select,message, Modal} from 'antd';
import moment from 'moment';
import axios from 'axios'
import {URL} from  '../config/url'
import locale from 'antd/lib/date-picker/locale/zh_CN';
import {countrys} from  '../config/countrys'
const Option = Select.Option;
class BaseInfo extends Component{
    constructor(props){
        super(props)
        this.state={
            token:'',
            visible:false,
            date:'',
            name:'',
            school:'',
            education:'',
            enname:'',
            sex:'',
            profession:'',
            address:'',
            code:'',
            firstPhoen:'',
            wechat:'',
            email:'',
            sedCode:'',
            sedPhoen:'',
            qq:'',
            remark:'',
            msg:false,
            countryss:[],
            degrees:[],
            homeTown:'',
            guideRank:[],
            companys:[],
            companyss:[],
            isNext:'',
            star:'',
            areaVal:'',
            isShowPull:false,
            countryArr:[],
            params:{'recommender':'','name':'','birtyday':'','school':'','enName':'','gender':'0','major':'',
            'weChat':'','email':'','qq':'','remark':'','topEdu':'','homeId':'','phoneSpareArea':'','phoneSpare':'','companyId':''},
            paramTxt:{'name':'请填写姓名','birtyday':'请填写生日','school':'请填写毕业学校','gender':'请填写性别','major':'请填写专业','phone':'请填写手机号',
            'weChat':'请填写微信','email':'请填写邮箱','qq':'请填写QQ','remark':'','topEdu':'请选择最高学历','homeId':'请填写家乡地址','phoneArea':'请选择手机区号','phoneSpareArea':'请选择备用手机区号','phoneSpare':'请填写备用手机号'},

        }
    }
    hideModal(){
        this.setState({
            visible:false,
        })
    }
    showModal(isNext){
        this.setState({
            visible:true,
            isNext
        })
    }
    changeAll(key,e){
        let {params}=this.state
        params[key]=e.target.value
        this.setState({
            params
        })
    }
    changeStar(e){
        let {params}=this.state
        params['guideStar']=e
        this.setState({
            params
        })
    }
    changeDate(date,dateString){
        let {params}=this.state
        params['birtyday']=dateString
        this.setState({
            params
        })
    }
    changeName(e){
        let {params}=this.state
        params['name']=e.target.value
        this.setState({
            params
        })
    }
    changeSchool(e){
        let {params}=this.state
        params['school']=e.target.value
        this.setState({
            params
        })
    }
    changEeducation(e){
        let {params}=this.state
        params['topEdu']=e
        this.setState({
            params
        })
    }
    changEnname(e){
        let {params}=this.state
        params['enName']=e.target.value
        this.setState({
            params
        }) 
    }
    changeSex(e){
        let {params}=this.state
        params['gender']=e
        this.setState({
            params
        }) 
    }
    changeProfession(e){
        let {params}=this.state
        params['major']=e.target.value
        this.setState({
            params
        }) 
    }
    changeAdress(e){
        let {params,countryss,homeTown}=this.state
        params['homeId']=e
        countryss.map((country)=>{
            if(country.id==e){
                homeTown=country.name
            }
        })
        this.setState({
            params,
            homeTown
        }) 
    }
    changeCode(e){
        let {params}=this.state
        params['phoneArea']=e
        this.setState({
            params
        }) 
    }
    changeFirtPhoen(e){
        let {params}=this.state
        params['phone']=e.target.value
        this.setState({
            params
        }) 
    }
    changeWeChat(e){
        let {params}=this.state
        params['weChat']=e.target.value
        this.setState({
            params
        }) 
    }
    changeEmail(e){
        let {params}=this.state
        params['email']=e.target.value
        this.setState({
            params
        }) 
    }
    changSedCode(e){
        let {params}=this.state
        params['phoneSpareArea']=e
            this.setState({
                params
            }) 
       
    }
    blurVal(e){
        console.log(e)
    }
    searchArea(e){
        let {params}=this.state
        params['phoneSpareArea']=e
        this.setState({
            params
        }) 
    }

    changeSedPhoen(e){
        let {params}=this.state
        params['phoneSpare']=e.target.value
        this.setState({
            params
        }) 
    }
    changeQQ(e){
        let {params}=this.state
        params['qq']=e.target.value
        this.setState({
            params
        }) 
    }
    changeRemark(e){
        let {params}=this.state
        params['remark']=e.target.value
        this.setState({
            params
        }) 
    }
    confirmModify(){
        let {params,paramTxt,isNext}=this.state
        this.setState({
            visible:false,
        })
        params['page']=1
        params['status']=1;
        if(sessionStorage.getItem('guideId')){
            params.id=sessionStorage.getItem('guideId')
        }
        if(!params.companyId){
            params.companyId=3525
        }
        if(!params.gender){
            params.gender='0'
        }
        params.phone=sessionStorage.getItem('phoneNum')
        params.phoneArea=sessionStorage.getItem('phoneArea')
        var param = new URLSearchParams();
        for (const key in params) {
            param.append([key],params[key])
            if(key=='recommender'){
                if(isNaN(params[key])&&params[key]){
                    message.warning('请输入正确推荐人ID')
                    return false 
                }
            }
            if(!params[key]&&key!='school'&&key!='major'&&key!='qq'&&key!='email'&&key!='remark'&&key!='phoneSpareArea'&&key!='phoneSpare'&&key!='recommender'&&key!='companyId'){
                message.warning(paramTxt[key])
                return false
            }
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                sessionStorage.setItem('guideId',res.data.data.id)
               if(isNext==0){
                message.success('保存成功')
               }else{
                window.scrollTo(0,0)
                   this.props.goToNext(1)
               }
               sessionStorage.setItem('guideId',res.data.data.id)
            }
          })
    }
    submit(isNext,e){
        if(this.props.userData.status==2){
            this.showModal(isNext)
            return false;
        }
        
        let {params,paramTxt}=this.state
        
        if(params['phoneSpareArea'].indexOf('（')!=-1){
            params['phoneSpareArea']=params['phoneSpareArea'].slice(0,params['phoneSpareArea'].indexOf('（'))
        }
        params['page']=1
        if(sessionStorage.getItem('guideId')){
            params.id=sessionStorage.getItem('guideId')
        }
        if(!params.companyId){
            params.companyId=3525
        }
        if(!params.gender){
            params.gender='0'
        }
        params.phone=sessionStorage.getItem('phoneNum')
        params.phoneArea=sessionStorage.getItem('phoneArea')
        var param = new URLSearchParams();
        for (const key in params) {
            param.append([key],params[key])
            if(key=='recommender'){
                if(isNaN(params[key])&&params[key]){
                    message.warning('请输入正确推荐人ID')
                    return false 
                }
            }
            if(!params[key]&&key!='school'&&key!='major'&&key!='qq'&&key!='enName'&&key!='email'&&key!='remark'&&key!='phoneSpareArea'&&key!='phoneSpare'&&key!='recommender'&&key!='companyId'){
                message.warning(paramTxt[key])
                return false
            }
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                sessionStorage.setItem('guideId',res.data.data.id)
               if(isNext==0){
                message.success('保存成功')
               }else{
                window.scrollTo(0,0)
                   this.props.goToNext(1)
                //    window.location='/#/completeInfo?step=1'
                //    window.location.reload()
               }
               sessionStorage.setItem('guideId',res.data.data.id)
            }
          })
        
    }
   async componentDidMount(){
        let {
            name,
            birtyday,
            school,
            enName,
            gender,
            major,
            weChat,
            email,
            qq,
            remark,
            topEdu,
            homeId,
            phoneSpareArea,
            phoneSpare,
            recommender,
            companyId
            }=this.props.userData
        let params={
            name,
            birtyday,
            school,
            enName,
            gender,
            major,
            weChat,
            email,
            qq,
            remark,
            topEdu,
            homeId,
            phoneSpareArea,
            phoneSpare,
            recommender,
            companyId
            }
        if(this.props.userData){
            this.setState({
                params,
                homeTown:this.props.userData.hometown,
                star:this.props.userData.guideStarName
            })
        }
        let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getDistrictName?name=&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
        let tags= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24420`,{headers:{'DM_AUTH':this.props.token}})
        let guideRank= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24428`,{headers:{'DM_AUTH':this.props.token}})
        let companys=await axios.get(`${URL}/MT/company/load.do?companyId=3000`,{headers:{'DM_AUTH':this.props.token}})
        let countrys= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getAreaCodeByCodeOrName?countryArea=`,{headers:{'DM_AUTH':this.props.token}})
           await  this.setState({
                countryss:data.data.data,
                degrees:tags.data.data,
                guideRank:guideRank.data.data,
                companys:companys.data.data,
                countryArr:countrys.data.data
            })
    }
  async  seacrVal(e){
    if(e.search(/[a-zA-Z]+/)==-1){
        let value=document.querySelectorAll('#homeTown .ant-select-search__field')[0].value
        if(value){
            let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getDistrictName?name=${value}&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
            await  this.setState({
                    countryss:res.data.data
                })
        }
    }
        
    
    }
    handleChange(key,value,e) {
        let {params}=this.state
        params[key]=value
        this.setState({
            params,
            companyss:[]
        })
    }
    seacrCompanyVal(e){
        let {companys}=this.state
        if(!e.isNaN&&e.length==4){
            this.setState({
                companyss:companys
            })
        }else{
            this.setState({
                companyss:[]
            })
        }
    }
    clearCompany(){
        this.setState({
            companyss:[]
        })
    }
    render(){
        let {message,countryss,degrees,name,date,params,homeTown,guideRank,star,companys,companyss,countryArr}=this.state
        let birthday
        const dateFormat = 'YYYY-MM-DD';
        if(params['birtyday']){
            params['birtyday']=params['birtyday'].replace(' 00:00:00','')
            birthday=moment(params['birtyday'], dateFormat)
        }
        let companyName={}
        companys.map((company)=>{
            companyName[company.id]=company.name
        })
        return(
            <div className='baseInfo_first'>
                <div className='info_title'>
                    {/* <span className='f32'>基本信息</span> <span>修改你的个人资料，将显示在你的首页，并可以让更多的朋友了解和认识你！</span> */}
                </div>
                <div className='basic_information'>
                    <div className='little f20'>基本信息</div>
                    <div className='cover'></div>
                
                    <div className='infoItem'>
                        <span className='label_'>姓名：</span>
                        <Input onChange={this.changeName.bind(this)} value={params['name']} placeholder='请输入姓名'/>
                        {/* <input onChange={this.changeName.bind(this)} value={params['name']}></input> */}
                         <span className='red f22'>*</span>
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>英文名：</span>
                        <Input placeholder='请输入英文名字' onChange={this.changEnname.bind(this)}  value={params['enName']}/>
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>出生日期：</span>
                        <DatePicker locale={locale}  onChange={this.changeDate.bind(this)} className='mt20' placeholder="请选择日期"  value={birthday}></DatePicker> <span className='red f22'>*</span>
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>性别：</span>
                        <Select className='mt20 border' value={params['gender']==0?'女':'男'} onChange={this.changeSex.bind(this)} style={{width:172}} placeholder='请选择'>
                            <Option value='1'>男</Option>
                            <Option value='0'>女</Option>
                        </Select> <span className='red f22'>*</span>
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>毕业院校：</span>
                        <Input onChange={this.changeSchool.bind(this)} value={params['school']} placeholder='请输入毕业院校' className='mt20'/>
                        {/* <input className='mt20' placeholder='请输入毕业院校' value={params['school']} onChange={this.changeSchool.bind(this)}></input>  */}
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>专业：</span>
                        <Input onChange={this.changeProfession.bind(this)} value={params['major']}placeholder='请输入专业' className='mt20'/>
                        {/* <input className='mt20' placeholder='请输入专业' onChange={this.changeProfession.bind(this)} value={params['major']}></input>  */}
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>最高学历：</span>
                        <Select className='mt20 border' onChange={this.changEeducation.bind(this)} placeholder='请选择' style={{width:172}} value={params['topEdu']}>
                                {degrees.map((opt,i)=>{
                                    return(
                                        <Option value={opt.id} key={i}>{opt.name}</Option>
                                    )
                                })}
                           </Select><span className='red f22'>*</span> 
                    </div>
                    <div className='infoItem homeTown_' id='homeTown'>
                        <span className='label_'>家乡：</span>
                        <Select placeholder='搜索'  className='mt20 border'  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}  
                        showArrow={true} showSearch value={homeTown}  onSearch={this.seacrVal.bind(this)} onChange={this.changeAdress.bind(this)} style={{width:172}}>
                                {countryss.map((country,c)=>{
                                    return(
                                        <Option value={country.id} key={c}>{country.name}</Option>
                                    )
                                })}
                        </Select> <span className='red f22'>*</span>
                    </div>
                    {/* <div className='infoItem'>
                        <span className='label_'>导游等级：</span>
                        <Select className='mt20 border' onChange={this.changeStar.bind(this)} disabled={true} placeholder='请选择' style={{width:172}} value={params['guideStar']?params['guideStar']:star}>
                                {guideRank.map((opt,i)=>{
                                    return(
                                        <Option value={opt.id} key={i}>{opt.name}</Option>
                                    )
                                })}
                           </Select>
                           <span className='red f22'>*</span> 
                    </div> */}
                    <div className='infoItem'>
                        <span className='label_'>推荐人ID：</span>
                        <Input onChange={this.changeAll.bind(this,'recommender')} value={params['recommender']}placeholder='请输入推荐人ID'/>
                        {/* <input onChange={this.changeAll.bind(this,'recommender')} value={params['recommender']}></input> */}
                    </div>
                    <div className='infoItem'>
                    <span className='label_'>公司：</span>
                            <Select className='mt20 border' style={{width:172}}  onSearch={this.seacrCompanyVal.bind(this)}
                             onChange={this.handleChange.bind(this,'companyId')} optionFilterProp="children" 
                             filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} 
                             onBlur={this.clearCompany.bind(this)}
                             showArrow={false} showSearch value={params['companyId']?`${params['companyId']} ${companyName[params['companyId']]}`:'3525 自营签约向导'}>
                                {companyss.map((country,c)=>{
                                        return(
                                            <Option value={country.id} key={c}>{country.id+' '+country.name}</Option>
                                        )
                                })}
                            </Select>
                            {/* <Input onChange={this.changeAll.bind(this,'companyId')} value={params['companyId']}placeholder='请输入公司ID'/> */}
                    </div>
                </div>
                <div className='contact_information'>
                    <div className='little f20'>联系方式</div>
                    <div className='cover'></div>
                    <div className='infoItem'>
                        <span className='label_'>手机号(区号)：</span>
                        <span>{sessionStorage.getItem('phoneArea')}-{sessionStorage.getItem('phoneNum')}</span>
                        {/* <Select className='area_code mt5 border' onChange={this.changeCode.bind(this)} placeholder='请选择' value={sessionStorage.getItem('phoneArea')} disabled>
                        {countryArr.map((item,i)=>{
                                return(
                                        <Option value={+item.num} key={i} title={item.name}>+{item.num}（{item.name}）</Option>
                                    )
                            })}
                        </Select>
                        <Input placeholder='请输入手机号' style={{width:120}} onChange={this.changeFirtPhoen.bind(this)} value={sessionStorage.getItem('phoneNum')} disabled/> */}
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>备用手机号(区号)：</span>
                        <Select className='area_code mt5 border'
                         showSearch
                         onSearch={this.searchArea.bind(this)} style={{width:172}}  
                         onChange={this.changSedCode.bind(this)} placeholder='请输入区号' 
                         defaultActiveFirstOption={false}
                         value={params['phoneSpareArea']}
                         optionFilterProp="value"
                         filterOption={(input, option) =>
                            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }>
                            {countryArr.map((item,i)=>{
                                return(
                                        <Option value={`${item.countryAreaNum}（${item.countryAreaName}）`} key={i} title={item.countryAreaName}>{item.countryAreaNum}（{item.countryAreaName}）</Option>
                                    )
                            })}
                        </Select>
                        <span className='pcInput'>
                            <span></span>
                            <Input placeholder='请输入手机号' style={{width:120}} onChange={this.changeSedPhoen.bind(this)} value={params['phoneSpare']}/>
                        </span>
                        {/* <input placeholder='请输入手机号' style={{width:120}} onChange={this.changeSedPhoen.bind(this)} value={params['phoneSpare']}></input>                     */}
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>微信号：</span>
                        <Input onChange={this.changeWeChat.bind(this)} value={params['weChat']} placeholder='请输入微信号' className='mt20'/> <span className='red f22'>*</span>
                        {/* <input placeholder='请输入微信号' className='mt20' onChange={this.changeWeChat.bind(this)} value={params['weChat']}></input>                      */}
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>QQ号：</span>
                        <Input onChange={this.changeQQ.bind(this)} value={params['qq']} placeholder='请输入QQ号' className='mt20'/>
                       {/* <input className='mt20' placeholder='请输入QQ号' onChange={this.changeQQ.bind(this)} value={params['qq']}></input>                     */}
                    </div>
                    <div className='infoItem'>
                        <span className='label_'>电子邮箱：</span>
                        <Input onChange={this.changeEmail.bind(this)} value={params['email']} placeholder='请输入邮箱' className='mt20'/>
                        {/* <input placeholder='请输入邮箱' className='mt20' onChange={this.changeEmail.bind(this)} value={params['email']}></input>                     */}
                    </div>
                </div>
                <div className='remarks'>
                       <div className='little f20'>备注信息</div>  
                       <div className='cover'></div> 
                       <div>
                            {/* <span className="remark_title">备注：</span> */}
                            <textarea className='remark_area border'  onChange={this.changeRemark.bind(this)} value={params['remark']}></textarea>   
                       </div>     
                </div>
                <div style={{textAlign:'right',width:'80%',margin:'0 auto'}}>  
                    <Button onClick={this.submit.bind(this,0)} type="primary" className='next_button'>保存</Button>
                    <Button onClick={this.submit.bind(this)} type="primary" className='next_button'>保存并下一页</Button>
                </div>
                <Modal
                    title="温馨提示："
                    visible={this.state.visible}
                    onOk={this.confirmModify.bind(this)}
                    onCancel={this.hideModal.bind(this)}
                    okText="确认"
                    cancelText="取消">
                    <div>是否重新保存？重新保存后需重新审核。</div>
                </Modal>
                {message&&<div></div>}
            </div>
        )
    }
}
export default BaseInfo