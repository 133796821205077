import React, { Component } from 'react';
import { Icon } from 'antd';
class Protocol extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        let {show}=this.props
        let style={
            transform:show?'scale(1)':'scale(0)'
        }
        return (
            <div className='protocolWrap' style={style}>
                <div className='closeBtn'><Icon type='close' style={{fontSize:'15px'}}></Icon></div>
                <h2 className='fb'>私导服务协议</h2>
                <div>
                    在注册达美旅行全球向导（B2B品牌为海德•地接）前，请务必仔细阅读并认同本声明。一旦注册成功，即代表您的行为将被视为对本声明全部内容的认同和并坚决执行。
                </div>
                <div className='title'>
                    隐私条款
                </div>
                <div>
                关于您的用户注册及其他特定资料下隐私权和条款受到保护与规范，以下条款中公开本公司对信息收集和使用的情况。本隐私条款正在不断改进中，随着我们服务范围的扩大，我们会随时更新我们的隐私条款。我们欢迎您随时会来查看本隐私条款，并向我们反馈您的意见。 本隐私条款的全部条款作为海德•地接合作协议不可分割的一部份，在您同意本公司服务协议之时，您已经同意我们按照本隐私条款来使用和披露您的个人信息。
                </div>
                <div className='title'>
                    注册信息
                </div>
                <div>
                当您注册成为达美旅行签约向导后，我们会要求您提供部分个人信息，包括但不仅限于您的<span className='fb'>真实姓名，所在国家，所在城市，服务城市，年龄，性别，出生日期，电话，微信，邮箱，若为带车向导车型，是否有儿童座椅，紧急联系人，收款方式以及自我介绍。</span>我们会使用您填写的信息进行分类，例如年龄、国家等，以便有针对性地向我们的用户提供新的服务和机会。我们会通过您的邮件地址来通知您这些新的服务和机会。
                </div>
                <div className='title'>
                账户信息
                </div>
                <div>
                您所提供的服务将通过我们获取您提供的账户信息完成转帐，我们将严格履行账户信息保管的相关法规，确保您的资料安全。
                </div>
                <div className='title'>
                交易行为
                </div>
                <div>
                我们承诺不将此类信息提供给任何第三方（法律法规另有规定的除外）。
                </div>
                <div className='title'>
                信息的存储和交换
                </div>
                <div>
                您的信息和资料被收集和存储在放置于中国的服务器上。只有为了做备份的需要时，我们才可能需要将您的资料传送到别国的服务器上。
                </div>
                <div className='title'>
                安全
                </div>
                <div>
                我们有相应的安全措施来确保我们掌握的信息不丢失，不被滥用和编造。这些安全措施包括：
                </div>
                <div>
                1、向其它服务器备份数据和对用户密码加密。尽管我们有这些安全措施，但请注意在因特网上不存在"完善的安全措施"；
                </div>
                <div>
                2、存有您个人资料的记录会被安放在有防护措施的装置内。
                </div>
                <div className='title'>
                联系我们
                </div>
                <div>
                如果您对我们的隐私政策有任何疑问、建议或意见，请通过邮箱与我们联系：
                <div><span className='color'>guideservice@dameitravel.com</span>。</div>
                </div>
                <h2 className='fb title'>合作协议</h2>
                <div>
                甲方：北京达美国际旅行社有限责任公司 
                </div>
                <div>
                地址：北京市朝阳区安慧里4区15号院13楼1602室
                </div>
                <div className='mt20'>
                乙方：达美旅行（海德•地接）注册用户（“司导”）
                </div>
                <div>
                甲乙双方根据《中华人民共和国合同法》等相关法律、法规之规定，本着平等自愿、诚实信用的原则，就合作开展"海德•地接"全球向导业务事宜，达成如下合作协议，共同遵守。
                </div>
                <div className='mt20'>
                一、达美旅行向导业务
                </div>
                <div className='fb'>
                达美旅行依托于国内旅游平台和同业旅游市场的长期合作伙伴，主营业务为出境旅游定制团组接待服务，我们期望携手全球专职华人导游，摒弃恶意低价包车的市场竞争恶习，为客人提供专业的导游接待服务，同时为专业导游创作合理的利润价值。
                </div>
                <div className='mt20'>
                二、合作方式
                </div>
                <div>甲方根据国内团组的出境需求预约乙方的向导服务，在乙方严格按照本协议约定向客人提供服务后，甲方向乙方支付约定的服务费用。</div>
                <div className='mt20'>
                三、 合同构成
                </div>
                <div>下列内容作为本合同的有效组成部分，与本合同具有同等法律效力：</div>
                <div>
                1、《导游评级体系》《导游服务标准》《客户评价》《费用说明》；
                </div>
                <div>2、双方业务往来确认（如：传真确认、微信确认、邮件确认）；</div>
                <div>3、双方就未尽事宜达成的补充协议；</div>
                <div>4、其他约定：如应急时采用电话、邮件、短信等工具通知的变更信息。</div>
                <div className='mt20'>
                四、甲方的权利义务
                </div>
                <div>1、甲方应按第六条约定的时间、数额向乙方支付服务费用；</div>
                <div>2、甲方应真实、明确的说明接待要求和服务标准；</div>
                <div>3、甲方应对完成接待服务予以必要的协助；</div>
                <div>4、未经乙方允许，甲方不能和乙方所管理的车辆单独签约；</div>
                <div className='mt20'>五、乙方的权利义务</div>
                <div>1、乙方若提供带车服务，应提供证件齐全、有效的车辆；</div>
                <div>2、乙方应严格按照双方约定安排服务行程，不得随便终止服务；</div>
                <div>3、未经甲方允许，乙方不得以任何方式将本协议约定的乙方应履行的义务转让给其他方；</div>
                <div>4、乙方不得就约定的服务外另行向用户收取服务费用；</div>
                <div>5、乙方服务过程中，造成用户人身、财产损害的，乙方应采取救助措施并先行垫付必要费用，同时应立即向甲方反馈信息，收集和保存相关证据。因乙方司机或车辆原因造成用户或其他方人身、财产损害的，应由乙方承担与之有关的全部赔偿责任。甲方有权从应向乙方支付的费用中扣除乙方应承担的赔偿金额；</div>
                <div>6、未经甲方书面同意，乙方不得与任何第三方（包括但不限于甲方的同行和最终客户）签署同类的服务条款或协议；</div>
                <div>7、乙方应对其所管理司导定期进行服务标准及服务流程的培训。</div>
                <div>8、乙方应根据其所在地法律自行申报个人所得税。</div>
                <div>9、乙方应满足其所在地法律限定的工作身份要求，包括但不限于（国籍状态，签证状态，周工作时长等）。</div>
                <div className='mt20'>六、结算方式：</div>
                <div>可以选择以下2种结算方式：</div>
                <div className='fb'>1、一团一结：团组出发前确认下单，团组结束后2个工作日无投诉付清团款。</div>
                <div className='fb'>2、周期结算：每月结算。乙方在每月1日汇总上月结束团组，提交对账单，甲方核对无误，于每月10日前完成付款。</div>
                <div className='mt20'>七、特别说明</div>
                <div>1、若乙方为导游团队或者公司，本协议由乙方代表签署，乙方应安排符合甲方要求的员工提供本协议约定服务；</div>
                <div>2、乙方所安排员工司导管理者应将员工信息分别录入达美旅行全球向导管理平台，保证其录入内容的真实性且一名司导对应一个手机号（登陆平台时需要手机验证码验证）。</div>
                <div className='mt20'>八、不可抗力</div>
                <div>1、因不可抗力致使本协议履行不可能或者不必要的，任何一方有权单独解除本协议并及时通知对方。遭受不可抗力的一方如全部或者部分不能履行、迟延履行或解除协议时，应自不可抗力发生之日起 30 日内将事件情况以电子邮件形式通知对方，并提交导致其全部或部分不能履行、迟延履行或解除协议的证明，遭受不可抗力的一方应本着诚信、合作之精神处理好善后事宜。</div>
                <div>2、本协议所称的不可抗力是指不能预见、不能避免且不能克服的，对一方或者双方造成重大影响的客观事实，包括但不限于如洪水、地震等自然灾害以及如战争、动乱、政策变更、网络病毒侵染、黑客攻击等社会事件。</div>
                <div className='mt20'>九、 违约责任</div>
                <div>1、甲乙双方应承诺并保证严格遵守并自觉履行本协议之约定，任何一方不得有违反本协议之行为，否则应承担违约责任，给对方造成损失的，违约方还应承担相应的赔偿责任；</div>
                <div>2、如乙方未能按本协议约定提供服务，或乙方提供服务不符合本协议约定标准的，甲方有权让其他方提供有关服务，由此产生的费用应全部由乙方承担。甲方有权从应向乙方支付的款项中扣除上述费用；</div>
                <div>3、不论本合同是否有其他约定，乙方如有违反本协议约定的任何行为的，甲方有权立即解除本协议。</div>
                <div className='mt20'>十、保密条款</div>
                <div>1、甲乙双方对在签订或者履行本协议及其附件的过程中知悉的对方的信息资料，在本协议有效存续期间及本协议终止后均负有保密义务；甲、乙双方任何一方需承担因违反本条款给对方造成的损失;</div>
                <div>2、本条款在本协议终止后仍继续有效。</div>
                <div className='mt20'>十一、争议的解决</div>
                <div>本协议应适用中华人民共和国法律。凡因本协议引起的或在履行本协议过程中发生的任何争议，甲乙双方应协商解决。若协商不成，应提交中国国际经济贸易仲裁委员会，按照该会申请仲裁时现行有效的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力，仲裁费用由败诉方承担。</div>
            </div>
        );
    }
}
export default Protocol