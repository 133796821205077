import React, { Component } from 'react';
import logo from '../images/logo.png'
class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            logo:'',
            navs:[]
        }
    }
    render() {
        let {currentUrl,navs}=this.props
        let style={
            borderBottom:'2px solid #f8b62b'
        }
        return (
            <div className='headerContent'>
                <div className='nav'>
                    <a href="/#home"><img src={logo} alt=""/></a>
                    <ul>
                        {navs.navs&&navs.navs.map((url,u)=>{
                            if(url.url){
                                return(
                                    <li className='top-nav' key={u} style={currentUrl==url.url?style:null}><a href={'/#'+url.url}>{url.name}</a></li>
                                )
                            }else{
                                return(
                                    <li className='top-nav' key={u} style={currentUrl==url.url?style:null}><a href='javascript:'>{url.name}</a>
                                        <span className='warnTips'>
                                            建设中^_^
                                            <span className='arrow'></span>
                                        </span>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}
export default Header