import React, { Component } from "react";
import { Modal,Button ,message} from "antd";
import {URL} from  '../config/url'
import axios from 'axios'
import Cropper from './test'
import ServiceInfo from '../components/ServiceInfo'
class CompleteInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info:'',
      isPic:false,
      carPics:[],
      elseTable:[],
      carTable:[],
      elseDoc:[]
    };
  }
 async onChange(num,e) {
    let {info}=this.state
    var formData = new FormData();
    if(num==0){
      formData.append("file",document.getElementById("head").files[0]);
    }else{
      formData.append("file",document.getElementById("cover").files[0]);
    }
   await axios({
      url: `${URL}/SERVICE-MT-GUIDE/img/save?basePath=headImgUrl`,
      method: 'post',
      headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
      data: formData,
    }).then((res)=>{
      if(num==0){
        info.headImgUrl=res.data.data
      }else{
        info.coverImgUrl=res.data.data
      }
      this.setState({
        info
      })
    })
    let params=num==0?{'headImgUrl':info.headImgUrl,'page':16,'id':sessionStorage.getItem('guideId')}:{'coverImgUrl':info.coverImgUrl,'page':16,'id':sessionStorage.getItem('guideId')}
    var param = new URLSearchParams();
        for (const key in params) {
            param.append([key],params[key])
        }
    axios({
      url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
      method: 'post',
      headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
      data: param
    }).then((res)=>{
      if(res.data.success){
         
      }
    })
  }
  handleChange(){}
 async componentDidMount(){
  if(!sessionStorage.getItem('userToken')){
    window.location='/'
    return false
}
  let detail= await axios.get(`${URL}/SERVICE-MT-GUIDE/guide/detail?guideId=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
    if(detail.data.success){
      if(detail.data.data.companyId==3525){
        this.loadCarTableData()
        this.loadElseTable()
      }
      let arr=[]
      let userData=detail.data.data
        if(userData.charterServiceAttachment){
            for (const key in JSON.parse(userData.charterServiceAttachment)) {
                let item={'name':JSON.parse(userData.charterServiceAttachment)[key],'url':key}
                arr.push(item)
            }
        }
      this.setState({
        info:detail.data.data,
        elseDoc:arr
      })
    }else{
      window.location='/'
    }
  }
  async loadElseTable(){
    let elseTable=await axios.get(`${URL}/SERVICE-MT-GUIDE/GuideServiceCost/getHydeGuideServiceCost?id=${sessionStorage.getItem('guideId')}&type=2`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
    this.setState({
        elseTable:elseTable.data.data
    })
  }
  async loadCarTableData(){
    let carTable=await axios.get(`${URL}/SERVICE-MT-GUIDE/GuideServiceCost/getHydeGuideCarService?id=${sessionStorage.getItem('guideId')}&type=2`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
    this.setState({
        carTable:carTable.data.data,
    })
  }
  picShow(){
    this.setState({
      isPic:false
    })
  }
  lookPic(list){
    this.setState({
      isPic:true,
      carPics:list.hydeCarImgList
    })
  }
 async changeImg(url,type){
   let {info}=this.state
   let _this=this;
  let params=type==0?{'headImgUrl':url,'page':16,'id':sessionStorage.getItem('guideId')}:{'coverImgUrl':url,'page':16,'id':sessionStorage.getItem('guideId')}
  var param = new URLSearchParams();
      for (const key in params) {
          param.append([key],params[key])
      }
    axios({
      url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
      method: 'post',
      headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
      data: param
    }).then((res)=>{
      if(res.data.success){
        if(type==0){
          info.headImgUrl=url
        }else{
          info.coverImgUrl=url
        }
        _this.setState({
          info
        })
      }
    })
  }
  showService(){
    Modal.info({
        title:null,
        icon:null,
        content: (
          <div>
            <ServiceInfo></ServiceInfo>
          </div>
        ),
        onOk() {},
      });
}
  render() {
    let {info,isPic,carPics,carTable,elseTable,elseDoc}=this.state
    let proArr=[]
    let tagArr=[]
    let lanArr=[]
    let elseArr=[]
    let myImgs1=[]
    let myImgs2=[]
    let myImgs3=[]
    let myImgs4=[]
    if(info.tagVOList&&info.tagVOList.length>0){
      info.tagVOList.map((tag,t)=>{
        if(tag.hydeTagType==2){
         proArr.push(tag)
        }else if(tag.hydeTagType==3){
          tagArr.push(tag)
        }else if(tag.hydeTagType==4){
          lanArr.push(tag)
        }else if(tag.hydeTagType==5){
          elseArr.push(tag)
        }
    })
  }
  if(info.hydeGuideImgList&&info.hydeGuideImgList.length>0){
    info.hydeGuideImgList.map((img)=>{
      if(img.imgType==4){
        myImgs1.push(img)
      }else if(img.imgType==5){
        myImgs2.push(img)
      }else if(img.imgType==6){
        myImgs3.push(img)
      }else if(img.imgType==7){
        myImgs4.push(img)
      }
    })
  }
  let myStoryAll
  if(info&&info.myStory){
    myStoryAll=JSON.parse(info.myStory)
  }
    return (
      <div className="infoPage userPage">
        <div className="inputs">
          <div className="inputItem w100">
            <span style={{position:'relative',display:'flex'}}>
              <span style={{lineHeight:'100px'}}>头像：</span>
              <span className='pcUpload'><Cropper imgChange={this.changeImg.bind(this)} type={0} prop={1}></Cropper></span>
              <img src={info.headImgUrl} alt="" style={{width:'100px',height:'100px'}}/>
              <span className='mUpload'>
                <input type="file" onChange={this.onChange.bind(this,0)} id='head' className='hiddenInput'/>
              </span>
            </span>
            <span style={{position:'relative',display:'flex'}} className='coverImg'>
              <span style={{lineHeight:'100px'}}>封面照：</span>
              <span className='pcUpload'><Cropper imgChange={this.changeImg.bind(this)} type={1} prop={4/3}></Cropper></span>
              <img src={info.coverImgUrl} alt="" style={{width:'120px',height:'90px'}}/>
              {/* <span className='mUpload '>
                <input type="file" onChange={this.onChange.bind(this,1)} id='cover' className='hiddenInput'/>
              </span> */}
            </span>
          </div>
          <div className="inputBox">
          <div className='title fb'>基本信息：</div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">姓名：</span>
                <span>{info.name}</span>
              </div>
              <div className='w50'>
                <span className="label_">英文名：</span>
                <span>{info.enName}</span>
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">出生日期：</span>
                <span>{info.birtyday}</span>
              </div>
              <div className='w50'>
                <span className="label_">性别：</span>
                <span>{info.gender==0?'女':'男'}</span>
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">毕业院校：</span>
                <span>{info.school}</span>
              </div>
              <div className='w50'>
                <span className="label_">专业：</span>
                <span>{info.major}</span>
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className='label_'>导游证等级：</span>
                {info.guideStarName&&<span>{info.guideStarName}级</span>}
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">最高学历：</span>
                <span>{info.topEduName}</span>
              </div>
              <div className='w50'>
                <span className='label_'>家乡：</span>
                <span>{info.hometown}</span>
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">手机号(区号)：</span>
                <span>{info.phoneArea}-{info.phone}</span>
              </div>
              <div className='w50'>
                <span className='label_'>备用手机号(区号)：</span>
                {info.phoneSpareArea&&<span>{info.phoneSpareArea}-{info.phoneSpare}</span>}
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_ long">公司：</span>
                <span>{info.companyId} {info.companyName}</span>
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">微信号：</span>
                <span>{info.weChat}</span>
              </div>
              <div className='w50'>
                <span className="label_">QQ号：</span>
                <span>{info.qq}</span>
              </div>
            </div>
            <div className="rowItem">
                <span className="label_" style={{verticalAlign:'top'}}>备注：</span>
                <span>{info.remark}</span>
            </div>
            <div className='alignRight mt10'>
              <Button type="primary" href='/#/completeInfo'> <span style={{color:'#fff'}}>修改</span></Button>
            </div>
            <div className="rowItem">
            <div className='title fb'>附加信息：</div>
              <div className='w50'>
                <span className="label_">护照号码：</span>
                <span>{info.passportNumber}</span>
              </div>
              <div className='w50'>
                <span className="label_">护照有效期：</span>
                <span>{info.passportValidity}</span>
              </div>
            </div>
            <div className="rowItem flex">
                <span className="label_" style={{verticalAlign:'top'}}>护照照片：</span>
                <span className='flex1'>
                {info.hydeGuideImgList&&info.hydeGuideImgList.map((list,l)=>{
                  if(list.imgType==1){
                    return(
                      <img src={list.file} alt="" key={l} onClick={this.lookPic.bind(this,{'hydeCarImgList':[{'carOssUrl':list.file}]})}/>
                    )
                  }
                })}
                </span>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">驾照号码：</span>
                <span>{info.driverNumber}</span>
              </div>
              <div className='w50'>
                <span className="label_">驾照有效期：</span>
                <span>{info.driverValidity}</span>
              </div>
            </div>
            <div className="rowItem flex">
                <span className="label_" style={{verticalAlign:'top'}}>驾照照片：</span>
                <span className='flex1'>
                  {info.hydeGuideImgList&&info.hydeGuideImgList.map((list,l)=>{
                    if(list.imgType==2){
                      return(
                        <img src={list.file} alt="" key={l} onClick={this.lookPic.bind(this,{'hydeCarImgList':[{'carOssUrl':list.file}]})}/>
                      )
                    }
                  })}
                </span>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">导游证编号：</span>
                <span>{info.guideNumber}</span>
              </div>
              <div className='w50'>
                <span className="label_">导游证有效期：</span>
                <span>{info.guideCardValidity}</span>
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">导游证发证机构：</span>
                <span>{info.guideCardOrg}</span>
              </div>
            </div>
            <div className="rowItem flex">
                <span className="label_" style={{verticalAlign:'top'}}>导游证照片：</span>
                <span className='flex1'>
                  {info.hydeGuideImgList&&info.hydeGuideImgList.map((list,l)=>{
                    if(list.imgType==3){
                      return(
                        <img src={list.file} alt="" key={l} onClick={this.lookPic.bind(this,{'hydeCarImgList':[{'carOssUrl':list.file}]})}/>
                      )
                    }
                  })}
                </span>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">开户行：</span>
                <span>{info.openBankName}</span>
              </div>
              <div className='w50'>
                <span className="label_">开户名称：</span>
                <span>{info.openAccountName}</span>
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_">账号：</span>
                <span>{info.account}</span>
              </div>
            </div>
            <div className='alignRight mt10'>
              <Button type="primary" href='/#/completeInfo?step=1'><span style={{color:'#fff'}}>修改</span></Button>
            </div>
            <div className="rowItem">
            <div className='title fb'>服务信息：</div>
              <div className='w50 flex'>
                <span className="label_ long">常住城市：</span>
                <span className='flex1'>{info.permanentCity}</span>
              </div>
              <div className='w50'>
                <span className="label_ long">目的地开始居住年月：</span>
                <span>{info.endDistrictDate}</span>
              </div>
            </div>
            <div className="rowItem">
              <div className='w50 flex'>
                <span className="label_ long">服务目的地：</span>
                <span className='flex1'>
                {info.districtList&&info.districtList.map((tag,t)=>{
                    return(
                      <span key={t}>{t>0&&','}{tag.name}</span>
                    )
                })}
                </span>
              </div>
              <div className='w50'>
                <span className="label_ long">可服务产品：</span>
                {proArr.map((tag,t)=>{
                    return(
                      <span key={t}>{t>0&&','}{tag.name}</span>
                    )
                })}
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_ long">当地语言：</span>
                <span>{info.languageLevelName}</span>
              </div>
              <div className='w50'>
                <span className="label_ long">英语水平：</span>
                <span>{info.englishLevelName}</span>
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_ long">向导标签：</span>
                {tagArr.map((tag,t)=>{
                  if(tag.hydeTagType==3){
                    return(
                      <span key={t}>{t>0&&','}{tag.name}</span>
                    )
                  }
                })}
              </div>
              <div className='w50'>
                <span className="label_ long">多门语言：</span>
                {lanArr.map((tag,t)=>{
                  if(tag.hydeTagType==4){
                    return(
                      <span key={t}>{t>0&&','}{tag.name}</span>
                    )
                  }
                })}
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_ long">职业类型：</span>
                <span>{info.jobTypeName}</span>
              </div>
              <div className='w50'>
                <span className="label_ long">附加服务：</span>
                {elseArr.map((tag,t)=>{
                  if(tag.hydeTagType==5){
                    return(
                      <span key={t}>{t>0&&','}{tag.name}</span>
                    )
                  }
                })}
              </div>
            </div>
            <div className="rowItem">
              <div className='w50'>
                <span className="label_ long">服务次数：</span>
                <span>{info.serviceTime}</span>
              </div>
              <div className='w50'>
                <span className="label_ long">推荐人：</span>
                <span>{info.recommender}</span>
              </div>
            </div>
            <div className='rowItem'>
    
                        <div className='flex'>
                          <span className="label_ long">灵活结算：</span>
                          <div style={{width:'80%',margin:'0 auto'}} className='flex1'>
                          出团前邮件下单确认，团组结束后3个工作日无投诉结清团款。
                          </div>
                        </div>
                    </div>
                    <div className='rowItem'>
                      <div className='flex'>
                          <span className="label_ long">退改政策：</span>
                          <div style={{width:'80%',margin:'0 auto'}} className='flex1'>
                          <div>1.行程开始前7天（不含7天）以上取消订单，无需支付违约金；</div>
                          <div>2.行程开始前7-3天（不含3天）取消订单,支付订单金额的20%的违约金；</div>
                          <div>3.行程开始前3-1天（不含1天）取消订单,支付订单金额的50%的违约金；</div>
                          <div>4.行程开始24小时内取消订单,支付订单金额的100%的违约金。</div>
                          </div>
                        </div>
                        
                    </div>
            <div className="rowItem alignRight">
              <Button type="primary" href='/#/completeInfo?step=2'><span style={{color:'#fff'}}>修改</span></Button>
            </div>
            <div className="rowItem alignRight">
              <div style={{textAlign:'left'}}>
                <span className="label_ long fb" style={{textAlign:'left'}}>服务费用：</span>
                <div style={{margin:'0 auto'}}>
                <div className='mt5'>接送机服务、包车服务</div>
                {carTable&&<table className='costTable mt10'>
                            <thead>
                            <tr>
                            <th>类型</th>
                            <th>城市</th>
                            <th>行程</th>
                            {carTable.hydeGuideCarInfoVOs&&carTable.hydeGuideCarInfoVOs.map((carType,ca)=>{
                                return(
                                    <th key={ca} className='carTypeItem'>
                                        {carType.carType}
                                    </th>
                                )
                            })}
                            <th>币种</th>
                            </tr>
                            </thead>
                          <tbody>
                          {carTable.hydeGuideCarServiceList&&carTable.hydeGuideCarServiceList.map((car,c)=>{
                               return(
                                <tr key={c}>
                                    <td>{car.serviceType==1&&'接送机服务'}{car.serviceType==2&&'包车服务'}</td>
                                    <td>{car.cityName}</td>
                                    <td>{car.scheduleDetail}</td>
                                    {carTable.hydeGuideCarInfoVOs&&carTable.hydeGuideCarInfoVOs.map((cost,co)=>{
                                        let ishave
                                        car.hydeGuideCarServiceCosts.map((cst)=>{
                                            if(cst.carId==cost.id){
                                                ishave=cst
                                            }
                                        })
                                        if(ishave){
                                            return(
                                                <td key={co}>{ishave.price}</td>
                                            )
                                        }else{
                                            return(
                                                <td key={co}></td>
                                            )
                                        }
                                        
                                    })}
                                    <td>{car.currency}</td>
                                </tr>
                               )
                           })}
                          </tbody>
                        </table>}
                    <div className='mt5'>
                      <span>包车服务附件：</span>
                    {elseDoc.map((doc,d)=>{
                                return(
                                    <span key={d}  className='mr15'>
                                        <a  href={`https://service.dameiweb.com/MT/multimedia/download.do?key=${doc.url}&DM_AUTH=${sessionStorage.getItem('userToken')}`} target='_blank' >{doc.name} </a>
                                    </span>
                                )
                            })}
                    </div>
                    <div className='mt10'>不带车服务、翻译服务、摄影服务、其他服务</div>
                    <table className='costTable mt10'>
                            <thead>
                            <tr>
                            <th>类型</th>
                            <th>城市</th>
                            <th>价格</th>
                            <th>币种</th>
                            <th>备注</th>
                            </tr>
                            </thead>
                            <tbody>
                            {elseTable.map((table,t)=>{
                                return(
                                    <tr key={t}>
                                        <td>{table.serviceType==1&&'不带车服务'}{table.serviceType==2&&'翻译服务/4小时'}{table.serviceType==4&&'翻译服务/天'}{table.serviceType==8&&'摄影服务'}{table.serviceType==16&&'其他服务'}</td>
                                        <td>{table.cityName}</td>
                                        <td>{table.price}</td>
                                        <td>{table.currency}</td>
                                        <td>{table.rmk}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className=' serviceState mt5' onClick={this.showService}>《服务说明》</div>
                </div>
              </div>
              <Button type="primary" href='/#/completeInfo?step=3'><span style={{color:'#fff'}}>修改</span></Button>
            </div>
            <div className="rowItem">
            <div className='title fb'>个人故事：</div>
              <div className='flex'>
                <span className="label_ long">旅行寄语：</span>
                <div dangerouslySetInnerHTML={{__html:info.travelMessage}} style={{width:'80%',margin:'0 auto'}} className='flex1'>

                </div>
              </div>
            </div>
            <div className="rowItem">
              <div className='flex'>
                <span className="label_ long">我的故事：</span>
                  {myStoryAll&&<div style={{width:'80%',margin:'0 auto'}} className='flex1'>
                  {myStoryAll&&myStoryAll.story.map((sto,s)=>{
                    return(
                      <div key={s}>
                        <div style={{wordBreak: 'break-word'}}>{sto.storyText}</div>
                        {sto.storyImg.length>0&&<div>{sto.storyImg.map((img,i)=>{
                          return(
                            <img src={img} alt="" key={i} onClick={this.lookPic.bind(this,{'hydeCarImgList':[{'carOssUrl':img}]})}/>
                          )
                        })}</div>}
                      </div>
                    )
                  })}
                </div>}
              </div>
            </div>
            <div className="rowItem">
              <div className='flex'>
                <span className="label_ long">我与城市的故事：</span>
                {myStoryAll&&<div style={{width:'80%',margin:'0 auto'}} className='flex1'>
                  {myStoryAll&&myStoryAll.city.map((sto,s)=>{
                    return(
                      <div key={s}>
                        <div style={{wordBreak: 'break-word'}}>{sto.cityText}</div>
                        {sto.cityImg.length>0&&<div>{sto.cityImg.map((img,i)=>{
                          return(
                            <img src={img} alt="" key={i} onClick={this.lookPic.bind(this,{'hydeCarImgList':[{'carOssUrl':img}]})}/>
                          )
                        })}</div>}
                      </div>
                    )
                  })}
                </div>}
              </div>
            </div>
            <div className="rowItem">
              <div className='flex'>
                <span className="label_ long">我带过的团组：</span>
                {myStoryAll&&<div style={{width:'80%',margin:'0 auto'}} className='flex1'>
                  {myStoryAll&&myStoryAll.group.map((sto,s)=>{
                    return(
                      <div key={s}>
                        <div style={{wordBreak: 'break-word'}}>{sto.groupText}</div>
                        {sto.groupImg.length>0&&<div>{sto.groupImg.map((img,i)=>{
                          return(
                            <img src={img} alt="" key={i} onClick={this.lookPic.bind(this,{'hydeCarImgList':[{'carOssUrl':img}]})}/>
                          )
                        })}</div>}
                      </div>
                    )
                  })}
                </div>}
              </div>
            </div>
            <div className='alignRight mt10' style={{border:'none'}}>
              <Button type="primary" href={`/#/completeInfo?step=${info.companyId==3525?'4':'3'}`}><span style={{color:'#fff'}}>修改</span></Button>
            </div>
            <div className='carInfo'>
                <div className='title fb dashed'>可服务车辆：</div>
                {info.hydeGuideCarVOList&&info.hydeGuideCarVOList.map((list,ll)=>{
                  return(
                    <div className='carTables' key={ll}>
                      <div>
                        <div className='halfWidth'>编号：{list.id}</div>
                        <div className='halfWidth '>添加时间：{list.gmtCreate}</div>
                      </div>
                      <div className='mt10'>
                        <div className='halfWidth'>车辆所在地：{list.address}</div>
                        <div className='halfWidth'>车品牌：{list.carName}</div>
                      </div>
                      <div className='mt10'>
                        <div className='halfWidth'>车型：{list.typeName}</div>
                        <div className='halfWidth'>车座位：{list.passenger}</div>
                      </div>
                      <div className='mt10'>
                        <div className='halfWidth'>车牌号：{list.carNumber}</div>
                        <div className='halfWidth'>车购置年月：{list.purchaseTime!=null?list.purchaseTime.replace('/','-'):''}</div>
                      </div>
                      <div className='mt10'>车图片：</div>
                      <div className='mt10'>
                        {list.hydeCarImgList&&list.hydeCarImgList.map((li,l)=>{
                          return(
                            <img src={li.carOssUrl} alt="" onClick={this.lookPic.bind(this,{'hydeCarImgList':[{'carOssUrl':li.carOssUrl}]})} style={{width:'90px',height:'90px'}} key={l}/>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
                <div className='alignRight mt10'>
                    <Button type="primary" href={`/#/completeInfo?step=${info.companyId==3525?'5':'4'}`}><span style={{color:'#fff'}}>修改</span></Button>
                </div>
            </div>
          </div>
        </div>
        <Modal  visible={isPic}  
                        onCancel={this.picShow.bind(this)} footer={null}>
                        {carPics.map((carPic,c)=>{
                            return(
                                <div  key={c} style={{textAlign:'center'}}>
                                    <img src={carPic.carOssUrl} alt="" style={{width:'200px'}}/>
                                </div>
                            )
                        })}
            </Modal>
      </div>
    );
  }
}
export default CompleteInfo;
