import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Icon} from "antd";
import Cropper from 'react-cropper' // 引入Cropper
import 'cropperjs/dist/cropper.css' // 引入Cropper对应的css
import {URL} from  '../config/url'
import axios from 'axios'



export default class ClassCropperModal extends Component {
  static propTypes = {
    uploadedImageFile: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      src: null
    }
  }

  componentDidMount() {
    const fileReader = new FileReader()
    fileReader.onload = e => {
      const dataURL = e.target.result
      this.setState({ src: dataURL })
    }

    fileReader.readAsDataURL(this.props.uploadedImageFile)
  }

  handleSubmit = () => {
    if (!this.state.submitting) {
      let url = `${URL}/SERVICE-MT-GUIDE/img/save?basePath=headImgUrl` // 你要上传的url
      // 拿到文件名
      let filename = this.props.uploadedImageFile.name
      // TODO: 这里可以尝试修改上传图片的尺寸
      this.cropper.getCroppedCanvas().toBlob(async blob => {
        // // 创造提交表单数据对象
        const formData = new FormData()
        // // 添加要上传的文件
        formData.append('file', blob, filename)
        // 提示开始上传 (因为demo没有后端server, 所以这里代码我注释掉了, 这里是上传到服务器并拿到返回数据的代码)
        this.setState({submitting: true})
        // 上传图片
        const resp = await axios.post(url, formData,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
        // 拿到服务器返回的数据(resp)
        console.log(resp)
        // 提示上传完毕
        this.setState({submitting: false})

        //把选中裁切好的的图片传出去
        this.props.onSubmit(resp.data.data);

        // 关闭弹窗
        this.props.onClose()
      })
    }
  }
  closeModal(){
    this.props.onClose()
  }
  rotate(b){
    if(!b){
        this.cropper.rotate(-90);
    }else{
        this.cropper.rotate(90);
    }
  }
  render() {
    return (
      <div className="class-cropper-modal">
        <div className="modal-panel">
            <span className='closeBtn'> <Icon type='close' onClick={this.closeModal.bind(this)}></Icon> </span>
          <div className="cropper-container-container">
            <div className="cropper-container">
              <Cropper
                src={this.state.src}
                className="cropper"
                ref={cropper => (this.cropper = cropper)}
                // Cropper.js options
                viewMode={1}
                zoomable={true}
                aspectRatio={this.props.prop} // 固定为1:1  可以自己设置比例, 默认情况为自由比例
                guides={false}
                preview=".cropper-preview"
              />
            </div>
            <div className="preview-container">
              <div className="cropper-preview" />
            </div>
          </div>
          <div className="button-row">
            <div>
                <div className="submit-button mr15 inline" onClick={this.rotate.bind(this,false)}>
                <Icon type="undo" />左旋
                </div>
                <div className="submit-button inline" onClick={this.rotate.bind(this,true)}>
                <Icon type="redo" />右旋
                </div>
            </div>
            <div className="submit-button" onClick={this.handleSubmit}>
            <Icon type="upload" />上传
            </div>
          </div>
        </div>
      </div>
    )
  }
}