import React, { Component } from 'react';
import { DatePicker,Select,message ,Icon  ,Button,Modal,Checkbox,Input,Upload} from 'antd';
import Editors from '../components/Editor';
import ServiceInfo from '../components/ServiceInfo'
import {URL,isPhone} from  '../config/url';
import moment from 'moment';
import axios from 'axios';
const Option = Select.Option;
let options={
    top:450,
    duration:1.5,
}
message.config(options)
class ServiceDes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            countryss:[],
            carTypes:[],
            localLans:[],
            englishLevs:[],
            moreLans:[],
            jobTypes:[],
            elseSers:[],
            proTypes:[],
            tags:[],
            companys:[],
            txt:'',
            dests:[],
            takeCar:[],
            word:'',
            params:{'districtId':'','endDistrictDate':'','serviceDestination':[],'guideService':[],'conveyorService':[],'languageLevel':'','englishLevel':'','wizardTab':[],
            'multiLanguage':[],'jobType':'','additionalServices':[],'servableProducts':[],'companyId':'','costDescUrl':''},
            keys:{'conveyorService':'接送机服务','guideService':'带车向导服务','districtId':'常住城市','endDistrictDate':'目的地开始居住年月','serviceDestination':'服务目的地','languageLevel':'当地语言','englishLevel':'英语水平','wizardTab':'向导标签',
            'multiLanguage':'多门语言','jobType':'职业类型','additionalServices':'附加选项','servableProducts':'向导服务','companyId':'公司','costDescUrl':'成本单'},
            visible:false,
            isNext:'',
            elseTable:[],
            carTable:'',
            checkAll1:false,
            carCheckBox:[],
            addProCostModal:false,
            addProCostModal1:false,
            productTypes:[{'name':'不带车服务','id':1},{'name':'翻译服务/4小时','id':2},
                        {'name':'翻译服务/天','id':4},{'name':'摄影服务','id':8},{'name':'其他服务','id':16}],
            coinTypes:[{'name':'CNY'},{'name':'USD'},{'name':'CAD'},{'name':'AUD'},{'name':'EUR'},{'name':'GBP'},{'name':'RUB'}],
            addProParam:{'serviceType':'','cityId':'','currency':'','rmk':'','price':''},
            curEditId:'',
            curEditId1:'',
            elseCheckBox:[],
            checkAll2:false,
            deleModal:false,
            productTypes1:[{'name':'接送机服务','id':1},{'name':'包车服务','id':2}],
            elseProTypes:[{'name':'市区','id':1},{'name':'周边','id':2},{'name':' 出城/含住宿补贴','id':4},{'name':'出城/不含住宿补贴','id':8}],
            addProParam1:{'serviceType':'','cityId':'','currency':'','type':2,'serviceScope':'','scheduleDetail':''},
             blurParam:{},
             focusParam:{},
             elseDoc:[],
             delDocModal:false,
             delDocData:'',
             addCarModal:false,
             brands:[],
             carType:[],
             carSets:[],
             addCarParam:{'carBrandId':'','carTypeId':'','passenger':''},
             deleWhich:'',
             deleCarId:'',
             m_deleId0:'',
             isPhone:'',
             m_editList:[],
             curCompanyId:'',
             editTitle:'新增产品报价'
            }
    }
    onChange(key,e){
        let {params}=this.state
        params[key]=e.target.value
        this.setState({
            params
        })
    }
    handleChange(key,value,e) {
        let {params}=this.state
        if([key]=='endDistrictDate'){
            params[key]=e
        }else{
            params[key]=value
        }
        this.setState({
            params
        })
    }
    async componentDidMount(){
        let {params}=this.state
        
        for (const key in params) {
                
                if(key=='serviceDestination'&&this.props.userData.districtList){
                    this.props.userData.districtList.map((list)=>{
                        params[key].push(list.name)
                    })
                }else if(key=='carTypeId'){
                    this.props.userData.hydeGuideCarVOList.map((list)=>{
                        params[key].push(list.passenger)
                    })
                }else if(key=='multiLanguage'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==4){
                            params[key].push(list.name)
                        }
                    })
                }else if(key=='servableProducts'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==2){
                            params[key].push(list.name)
                        }
                    })
                }else if(key=='wizardTab'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==3){
                            params[key].push(list.name)
                        }
                    })
                }else if(key=='additionalServices'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==5){
                            params[key].push(list.name)
                        }
                    })
                }else if(key=='guideService'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                        if(list.hydeTagType==6){
                            params[key].push(list.name)
                           }
                    })
                }else if(key=='conveyorService'&&this.props.userData.tagVOList){
                    this.props.userData.tagVOList.map((list)=>{
                           if(list.hydeTagType==7){
                            params[key].push(list.name)
                           }
                    })
                }else if(key=='districtId'){
                    params[key]= this.props.userData.permanentCityShort
                }
                else{
                    params[key]=this.props.userData[key]
                }
       }
        let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getForeignCity?name=&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
        let carType= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24421`,{headers:{'DM_AUTH':this.props.token}})
        let takeCar= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24429`,{headers:{'DM_AUTH':this.props.token}})
        let localLan= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24422`,{headers:{'DM_AUTH':this.props.token}})
        let englishLev= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24423`,{headers:{'DM_AUTH':this.props.token}})
        let moreLan= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24427`,{headers:{'DM_AUTH':this.props.token}})
        let jobType= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24424`,{headers:{'DM_AUTH':this.props.token}})
        let elseSer= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24425`,{headers:{'DM_AUTH':this.props.token}})
        let proType= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24426`,{headers:{'DM_AUTH':this.props.token}})
        let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24419`,{headers:{'DM_AUTH':this.props.token}})
        let companys=await axios.get(`${URL}/MT/company/load.do?companyId=3000`,{headers:{'DM_AUTH':this.props.token}})
        let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getAllCarBrand`,{headers:{'DM_AUTH':this.props.token}})
        this.loadCarTableData()
        this.loadElseTable()
        this.getUserData()
           await  this.setState({
                countryss:data.data.data,
                dests:data.data.data,
                carTypes:carType.data.data,
                localLans:localLan.data.data,
                englishLevs:englishLev.data.data,
                moreLans:moreLan.data.data,
                jobTypes:jobType.data.data,
                elseSers:elseSer.data.data,
                proTypes:proType.data.data,
                tags:res.data.data,
                companys:companys.data.data,
                takeCar:takeCar.data.data,
                brands:brand.data.data,
                isPhone:isPhone()
            })
    }
   async loadCarTableData(){
       let {blurParam}=this.state
    let carTable=await axios.get(`${URL}/SERVICE-MT-GUIDE/GuideServiceCost/getHydeGuideCarService?id=${sessionStorage.getItem('guideId')}&type=2`,{headers:{'DM_AUTH':this.props.token}})
    if(carTable.data.data.hydeGuideCarServiceList){
        carTable.data.data.hydeGuideCarServiceList.map((list)=>{
           if( blurParam[list.id]){
            list.hydeGuideCarServiceCosts.map((cost)=>{
                blurParam[list.id][cost.carId]=cost.price
            })
           }else{
            blurParam[list.id]={}
            list.hydeGuideCarServiceCosts.map((cost)=>{
                blurParam[list.id][cost.carId]=cost.price
            })
           }
        })
    }
    let carIds=[]
    if(carTable.data.data.hydeGuideCarInfoVOs){
        carTable.data.data.hydeGuideCarInfoVOs.map((car)=>{
            carIds.push(car.id)
        })
    }
    if(carTable.data.data.hydeGuideCarServiceList){
        carTable.data.data.hydeGuideCarServiceList.map((list)=>{
            if(list.hydeGuideCarServiceCosts){
                let costId=[]
                list.hydeGuideCarServiceCosts.map((item)=>{
                    costId.push(item.carId)
                })
                carIds.map((id)=>{
                    if(costId.indexOf(id)==-1){
                        list.hydeGuideCarServiceCosts.push({'carId':id,'hydeGuideCarServiceId':list.id,'price':'','carType':2})
                    }
                })
                list.hydeGuideCarServiceCosts.sort((a,b)=>{
                    return a.carId -b.carId;
                })
            }
        })
    }
    this.setState({
        carTable:carTable.data.data,
        blurParam
    })
    }
    async getUserData(){
        let arr=[]
        let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/guide/detail?guideId=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
        let userData=data.data.data
        if(userData.charterServiceAttachment){
            for (const key in JSON.parse(userData.charterServiceAttachment)) {
                let item={'name':JSON.parse(userData.charterServiceAttachment)[key],'url':key}
                arr.push(item)
            }
        }
            this.setState({
                elseDoc:arr,
                curCompanyId:userData.companyId
            })
    }
    async loadElseTable(){
        let elseTable=await axios.get(`${URL}/SERVICE-MT-GUIDE/GuideServiceCost/getHydeGuideServiceCost?id=${sessionStorage.getItem('guideId')}&type=2`,{headers:{'DM_AUTH':this.props.token}})
        this.setState({
            elseTable:elseTable.data.data
        })
    }
  async  seacrVal(e){
      let _this=this
      if(e.search(/[a-zA-Z]+/)==-1){
          let value=document.querySelectorAll('#citys .ant-select-search__field')[0].value
            if(value){
                let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getForeignCity?name=${value}&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
                this.setState({
                    countryss:res.data.data,
                    word:value
                })
            }
      }
    }
    async  seacrVals(e){
        if(e.search(/[a-zA-Z]+/)==-1){
            let value=document.querySelectorAll('#dest .ant-select-search__field')[0].value
           if(value){
            let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getForeignCity?name=${value}&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
            await this.setState({
                    dests:res.data.data
            })
           }
        }
    }
    hideModal(){
        this.setState({
            visible:false,
        })
    }
    showModal(isNext){
        this.setState({
            visible:true,
            isNext
        })
    }
    confirmModify(){
        this.setState({
            visible:false,
        })
        let {params,keys,txt,destId,isNext}=this.state
        let {userData}=this.props
        // params['serviceDestination']=destId
        for (const key in params) {
           if(key!=''&&key!='companyId'&&key!='costDescUrl'&&key!='additionalServices'){
               if(!params[key]||params[key].length==0){
                   message.warning(`请选择或填写${keys[key]}`)
                   return false
               }
           }
           if(key=='additionalServices'){
               let arr=[]
                if(params[key]){
                    params[key].map((param)=>{
                        if(!isNaN(param*1)){
                            arr.push(param)
                        }
                       if(userData.tagVOList){
                            userData.tagVOList.map((tag)=>{
                            if(param==tag.name){
                                arr.push(tag.id)
                            }
                        })
                       }
                    })
                }
                params[key]=arr
           }else if(key=='carTypeId'){
                // params[key].map((param)=>{
                //     userData.hydeGuideCarVOList.map((tag)=>{
                //         if(param.name==tag.name){
                //             param=tag.id
                //         }
                //     })
                // })
           }else if(key=='multiLanguage'){
                    let arr=[]
                    params[key].map((param)=>{
                        if(!isNaN(param*1)){
                            arr.push(param)
                        }
                        if(userData.tagVOList){
                        userData.tagVOList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                    })
                    params[key]=arr
            }else if(key=='servableProducts'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }
                    if(userData.tagVOList){
                        userData.tagVOList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                })
                params[key]=arr
            }else if(key=='serviceDestination'){
                if(params[key]){
                    let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }
                    if(userData.districtList){
                        userData.districtList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                })
                // this.state.dests.map((dest)=>{
                //     if(dest.name==params[key]){
                //         arr.push(dest.id)
                //     }
                // })
                params[key]=arr
                }
            }else if(key=='districtId'){
                if(params[key]){
                    if(isNaN(params[key])){
                        params[key]=userData.districtId
                    }
                }
            }else if(key=='wizardTab'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }
                   if(userData.tagVOList){
                        userData.tagVOList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                })
                params[key]=arr
            }else if(key=='guideService'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }else{
                        if(userData.tagVOList){
                            userData.tagVOList.map((tag)=>{
                            if(tag.hydeTagType==6&&param==tag.name){
                                    arr.push(tag.id)
                            }
                        })
                       }
                    }
                   
                })
                params[key]=arr
            }else if(key=='conveyorService'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }else{
                        if(userData.tagVOList){
                            userData.tagVOList.map((tag)=>{
                            if(tag.hydeTagType==7&&param==tag.name){
                                    arr.push(tag.id)
                            }
                        })
                       }
                    }
                   
                })
                params[key]=arr
            }
        }
        params.costDescUrl=txt
        params.id=sessionStorage.getItem('guideId')
        params.page=8
        params['status']=1
        var param = new URLSearchParams();
        for (const key in params) {
            param.append([key],params[key])
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                if(isNext!=0){
                    this.props.goToNext(3)
                    window.scrollTo(0,0)
                }else{
                    message.success('保存成功')
                }
            }
          })
    }
    saveInfo(step){
        if(step==1){
            window.scrollTo(0,0)
            this.props.goToNext(1)
            return false
        }
        if(this.props.userData.status==2){
            this.showModal(step)
            return false;
        }
        let {params,keys,txt,destId}=this.state
        let {userData}=this.props
        // params['serviceDestination']=destId
        for (const key in params) {
           if(key!=''&&key!='companyId'&&key!='costDescUrl'&&key!='additionalServices'&&key!='OpDescUrl'&&key!='WholeDescUrl'&&key!='StrainghtGuestUrl'){
               if(!params[key]||params[key].length==0){
                   console.log(key)
                   message.warning(`请选择或填写${keys[key]}`)
                   return false
               }
           }
           if(key=='additionalServices'){
               let arr=[]
                if(params[key]){
                    params[key].map((param)=>{
                        if(!isNaN(param*1)){
                            arr.push(param)
                        }
                       if(userData.tagVOList){
                            userData.tagVOList.map((tag)=>{
                            if(param==tag.name){
                                arr.push(tag.id)
                            }
                        })
                       }
                    })
                }
                params[key]=arr
           }else if(key=='carTypeId'){
                // params[key].map((param)=>{
                //     userData.hydeGuideCarVOList.map((tag)=>{
                //         if(param.name==tag.name){
                //             param=tag.id
                //         }
                //     })
                // })
           }else if(key=='multiLanguage'){
                    let arr=[]
                    params[key].map((param)=>{
                        if(!isNaN(param*1)){
                            arr.push(param)
                        }
                        if(userData.tagVOList){
                        userData.tagVOList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                    })
                    params[key]=arr
            }else if(key=='servableProducts'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }
                    if(userData.tagVOList){
                        userData.tagVOList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                })
                params[key]=arr
            }else if(key=='serviceDestination'){
                if(params[key]){
                    let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }
                    if(userData.districtList){
                        userData.districtList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                })
                // this.state.dests.map((dest)=>{
                //     if(dest.name==params[key]){
                //         arr.push(dest.id)
                //     }
                // })
                params[key]=arr
                }
            }else if(key=='districtId'){
                if(params[key]){
                    if(isNaN(params[key])){
                        params[key]=userData.districtId
                    }
                }
            }else if(key=='wizardTab'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }
                   if(userData.tagVOList){
                        userData.tagVOList.map((tag)=>{
                        if(param==tag.name){
                            arr.push(tag.id)
                        }
                    })
                   }
                })
                params[key]=arr
            }else if(key=='guideService'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }else{
                        if(userData.tagVOList){
                            userData.tagVOList.map((tag)=>{
                            if(tag.hydeTagType==6&&param==tag.name){
                                    arr.push(tag.id)
                            }
                        })
                       }
                    }
                   
                })
                params[key]=arr
            }else if(key=='conveyorService'){
                let arr=[]
                params[key].map((param)=>{
                    if(!isNaN(param*1)){
                        arr.push(param)
                    }else{
                        if(userData.tagVOList){
                            userData.tagVOList.map((tag)=>{
                            if(tag.hydeTagType==7&&param==tag.name){
                                    arr.push(tag.id)
                            }
                        })
                       }
                    }
                   
                })
                params[key]=arr
            }
        }
        params.costDescUrl=txt
        params.id=sessionStorage.getItem('guideId')
        params.page=8
        params.OpDescUrl=userData.OpDescUrl?userData.OpDescUrl:''
        params.WholeDescUrl=userData.WholeDescUrl?userData.WholeDescUrl:''
        params.StrainghtGuestUrl=userData.StrainghtGuestUrl?userData.StrainghtGuestUrl:''
        var param = new URLSearchParams();
        for (const key in params) {
            param.append([key],params[key])
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                if(step!=0){
                    this.props.goToNext(3)
                    window.scrollTo(0,0)
                }else{
                    message.success('保存成功')
                }
            }
          })
    }
    getValue(value){
        this.setState({
            txt:value
        })
    }
    checkAllOne(e){
        let {carTable}=this.state
        let arr=[]
        if(e.target.checked){
            carTable.hydeGuideCarServiceList.map((car)=>{
                if(arr.indexOf(car.id)==-1){
                    arr.push(car.id)
                }
            })
        }
        this.setState({
            checkAll1:e.target.checked,
            carCheckBox:arr
        })
    }
    addCheckBox(id,e){
        let {carCheckBox,checkAll1,carTable}=this.state
       if(e.target.checked){
           carCheckBox.push(id)
       }else{
           carCheckBox.splice(carCheckBox.indexOf(id),1)
       }
       if(carCheckBox.length!=carTable.hydeGuideCarServiceList.length){
           checkAll1=false
       }else{
        checkAll1=true
       }
       this.setState({
        carCheckBox,
        checkAll1
       })
    }
    checkAllTwo(e){
        let {elseTable}=this.state
        let arr=[]
        if(e.target.checked){
            elseTable.map((car)=>{
                if(arr.indexOf(car.id)==-1){
                    arr.push(car.id)
                }
            })
        }
        this.setState({
            checkAll2:e.target.checked,
            elseCheckBox:arr
        })
    }
    addCheckBox2(id,e){
        let {elseCheckBox,checkAll2,elseTable}=this.state
       if(e.target.checked){
        elseCheckBox.push(id)
       }else{
        elseCheckBox.splice(elseCheckBox.indexOf(id),1)
       }
       if(elseCheckBox.length!=elseTable.length){
        checkAll2=false
       }else{
        checkAll2=true
       }
       this.setState({
        elseCheckBox,
        checkAll2
       })
    }
    addProductCost(){
        let {addProCostModal}=this.state
        this.setState({
            addProCostModal:!addProCostModal
        })
    }
    addProductCost1(){
        let {addProCostModal1}=this.state
        this.setState({
            addProCostModal1:!addProCostModal1
        })
    }
    addInputChange(key,val){
        let {addProParam}=this.state
        addProParam[key]=val
        this.setState({
            addProParam
        })
    }
    addInputChange1(key,val){
        let {addProParam1}=this.state
        addProParam1[key]=val
        this.setState({
            addProParam1
        })
    }
    rmkVal(key,e){
        let {addProParam}=this.state
        addProParam[key]=e.target.value
        this.setState({
            addProParam
        })
    }
    rmkVal1(key,e){
        let {addProParam1}=this.state
        addProParam1[key]=e.target.value
        this.setState({
            addProParam1
        })
    }
    saveProCost(){
        let {addProParam,curEditId}=this.state
        addProParam['type']=2
        addProParam['hydeGuideBaseId']=sessionStorage.getItem('guideId')
        if(curEditId){
            addProParam['id']=curEditId
        }
        var param = new URLSearchParams();
        for (const key in addProParam) {
            param.append([key],addProParam[key])
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/GuideServiceCost/saveHydeGuideServiceCost`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                this.loadElseTable()
                this.setState({
                    addProCostModal:false
                })
                message.success('保存成功')
            }
          })
    }
    saveProCost1(){
        let {addProParam1,curEditId1,m_editList,isPhone}=this.state
        addProParam1['type']=2
        addProParam1['hydeGuideBaseId']=sessionStorage.getItem('guideId')
        if(curEditId1){
            addProParam1['id']=curEditId1
        }
        if(isPhone){
            addProParam1['price']=''
            addProParam1['hydeGuideCarServiceCostList']=JSON.stringify(m_editList)
        }
        var param = new URLSearchParams();
        for (const key in addProParam1) {
            param.append([key],addProParam1[key])
        }
        let path=isPhone?'GuideServiceCost/saveGuideCarServiceCost':'GuideServiceCost/saveHydeGuideCarService'
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/${path}`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            dataType: 'json',
                contentType: 'application/json; charset=UTF-8',// 解决415错误
            data: param
          }).then((res)=>{
            if(res.data.success){
                this.loadCarTableData()
                this.setState({
                    addProCostModal1:false
                })
                message.success('保存成功')
            }
          })
    }
   async editElseTable(table){
        let {addProParam}=this.state
        addProParam['cityId']=table['cityId']
        addProParam['currency']=table['currency']
        addProParam['price']=table['price']
        addProParam['serviceType']=table['serviceType']
        addProParam['rmk']=table['rmk']
        addProParam['id']=table['id']
        this.setState({
            addProParam,
            addProCostModal:true,
            curEditId:table.id
        })
    }
    async editElseTable1(table){
        let {addProParam1,isPhone,m_editList,carTable}=this.state
        let arr=[]
        if(isPhone&&carTable.hydeGuideCarInfoVOs){
            carTable.hydeGuideCarInfoVOs.map((car)=>{
                table.hydeGuideCarServiceCosts.map((cost)=>{
                    if(car.id==cost.carId){
                        arr.push(cost)
                    }
                })
            })
        }
        console.log(arr)
        addProParam1['cityId']=table['cityId']
        addProParam1['currency']=table['currency']
        addProParam1['price']=table['price']
        addProParam1['serviceType']=table['serviceType']
        addProParam1['scheduleDetail']=table['scheduleDetail']
        addProParam1['id']=table['id']
        this.setState({
            addProParam1,
            addProCostModal1:true,
            curEditId1:table.id,
            m_editList:arr
        })
    }
    deleteElseTable(){
     let {elseCheckBox,carCheckBox,deleWhich,m_deleId0}=this.state
     var param = new URLSearchParams();
     let path
     if(deleWhich==0){
        param.append('hydeGuideCarServiceIds',m_deleId0?m_deleId0:carCheckBox)
        path='GuideServiceCost/deleteHydeGuideCarService'
     }else{
        param.append('hydeGuideServiceCostIds',m_deleId0?m_deleId0:elseCheckBox)
        path='GuideServiceCost/deleteHydeGuideServiceCost'
     }
     axios({
         url: `${URL}/SERVICE-MT-GUIDE/${path}`,
         method: 'post',
         headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
         data: param
       }).then((res)=>{
         if(res.data.success){
             if(deleWhich==0){
                this.loadCarTableData()
                this.setState({
                    deleModal:false,
                    carCheckBox:[]
                 })
             }else{
                this.loadElseTable()
                this.setState({
                    deleModal:false,
                    elseCheckBox:[]
                 })
             }
             message.success('删除成功')
         }
       })
    }
    changeDeleModal(num){
        let {deleModal}=this.state
        this.setState({
            deleModal:!deleModal,
            deleWhich:num
        })
    }
    changeDeleModalM(id,num,e){
        e.stopPropagation()
        let {deleModal,m_deleId0}=this.state
        this.setState({
            deleModal:!deleModal,
            m_deleId0:deleModal?'':[id],
            deleWhich:num
        })
    }
    carCostPrice(car,cost,e){
        if(isNaN(e.target.value*1)&&e.target.value*1>0){
            return false
        }
        let {focusParam,blurParam}=this.state
        focusParam['hydeGuideCarServiceId']=cost.hydeGuideCarServiceId?cost.hydeGuideCarServiceId:car.id
        focusParam['carId']=cost.carId?cost.carId:cost.id
        focusParam['carType']=isNaN(cost.carType*1)?2:cost.carType
        focusParam['price']=e.target.value
        blurParam[car.id][cost.id]=e.target.value
        this.setState({
            focusParam,
            blurParam
        })
    }
    saveCostPrice(){
        let {focusParam}=this.state
        if(!focusParam.price){
            return false
        }
        var param = new URLSearchParams();
        for (const key in focusParam) {
            param.append([key],focusParam[key])
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/GuideServiceCost/saveHydeGuideCarServiceCost`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                this.loadCarTableData()
            }
          })
    }
    deleteDoc(doc,b){
        let {delDocModal,delDocData}=this.state
       if(doc){
            this.setState({
                delDocData:doc,
                delDocModal:!delDocModal
            })
       }else{
        if(b){
            var param = new URLSearchParams();
            param.append('guideId',sessionStorage.getItem('guideId'))
            param.append('attachmentUrl',delDocData.url)
            axios({
                url: `${URL}/SERVICE-MT-GUIDE/GuideServiceCost/deleteGuideCarServiceAttachment`,
                method: 'post',
                headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
                data: param
            }).then((res)=>{
                if(res.data.success){
                    message.success('删除成功')
                    this.getUserData()
                    this.setState({
                        delDocModal:!delDocModal
                    })
                }
            })
        }else{
            this.setState({
                delDocModal:!delDocModal
            })
        }
       }
    }
    uploadFile(){
        var formData = new FormData();
        formData.append("file",document.getElementById("attachment").files[0])
        formData.append('guideId',sessionStorage.getItem('guideId'))
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/GuideServiceCost/saveGuideCarServiceAttachment`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: formData
        }).then((res)=>{
            if(res.data.success){
                message.success('添加成功')
                this.getUserData()
            }
        })
    }
   async addCarModalShow(car){
        let {addCarModal,addCarParam}=this.state
        if(car){
            addCarParam['carBrandId']=car.carBrandId
            addCarParam['carTypeId']=car.carTypeId
            addCarParam['passenger']=car.passenger
            let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getCar?id=${car.carBrandId}`,{headers:{'DM_AUTH':this.props.token}})
            let sets=[]
            brand.data.data.map((type)=>{
                if(type.typeId==car.carTypeId){
                    sets.push(type)
                }
            })
            this.setState({
                carType:brand.data.data,
                carSets:sets
            })
        }
        this.setState({
            addCarModal:!addCarModal,
            deleCarId:car?car.id:'',
            addCarParam
        })
    }
    async handleCarChange(e,key){
        let {addCarParam}=this.state
        addCarParam['carBrandId']=key
        addCarParam['carTypeId']=''
        addCarParam['passenger']=''
        let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getCar?id=${key}`,{headers:{'DM_AUTH':this.props.token}})
        this.setState({
            carType:brand.data.data,
            addCarParam
        })
    }
    async handleCarTypeChange(e,key){
        let {addCarParam}=this.state
        addCarParam[e]=key
        await  this.setState({
            addCarParam
            })
        let sets=[]
        this.state.carType.map((type)=>{
            if(type.typeId==key){
                sets.push(type)
            }
        })
        this.setState({
            carSets:sets
        })
    }
   async saveAddCar(){
        let {addCarParam,deleCarId}=this.state
           addCarParam['carAddress']=''
           addCarParam['carNumber']=''
           addCarParam['files']=[]
           addCarParam['page']=4
           addCarParam['guideId']=sessionStorage.getItem('guideId')*1
           if(deleCarId){
               addCarParam['id']=deleCarId
           }
           var formData = new FormData();
           for (const key in addCarParam) {
            formData.append([key],addCarParam[key])
            }
           let res= await axios.post(`${URL}/SERVICE-MT-GUIDE/car/save`,formData,{headers:{'DM_AUTH':this.props.token}})
           if(res.data.success){
            message.success('保存成功')
            this.setState({
                addCarModal:false
            })
            this.loadCarTableData()
        }
    }
    async deleCarItem(car){
        var formData = new FormData();
        formData.append('ids',[car.id])
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/car/delete`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: formData
        }).then((res)=>{
            if(res.data.success){
                message.success('删除成功')
                this.loadCarTableData()
            }
        })
    }
    showService(){
        Modal.info({
            title:null,
            icon:null,
            content: (
              <div>
                <ServiceInfo></ServiceInfo>
              </div>
            ),
            onOk() {},
          });
    }
    changeEditlist(i,e){
        let {m_editList}=this.state
        m_editList[i].price=e.target.value
        this.setState({
            m_editList
        })
    }
    render() {
        let {countryss,carTypes,localLans,englishLevs,moreLans,jobTypes,elseSers,
            proTypes,tags,dests,params,takeCar,elseTable,
            carTable,checkAll1,carCheckBox,addProParam,productTypes,
            coinTypes,checkAll2,elseCheckBox,deleModal,productTypes1,addProParam1,
            elseProTypes,blurParam,elseDoc,brands,addCarParam,carType,carSets,isPhone,
            m_editList,curCompanyId,curEditId,curEditId1}=this.state
        let date
        const dateFormat = 'YYYY-MM-DD';
        if(params['endDistrictDate']){
            params['endDistrictDate']=params['endDistrictDate'].replace(' 00:00:00','')
            date=moment(params['endDistrictDate'], dateFormat)
        }
        return (
            <div className='baseInfo serviceInfo'>
                <div className='infos'>
                    <div className='infoItem'>
                        <div className='title f20'>服务信息</div>
                        <div className='cover'></div>
                        <div className='inputItem citys' id='citys'>
                            <span className='label_'>常住城市：</span>
                            <Select  style={{ width: 200 }} onSearch={this.seacrVal.bind(this)}
                             onChange={this.handleChange.bind(this,'districtId')} optionFilterProp="data-name" 
                             filterOption={(input, option) => option.props.children.indexOf(input) >= 0} 
                             showArrow={true} showSearch value={params['districtId']}>
                                {countryss.length>0&&countryss.map((country,c)=>{
                                    return(
                                        <Option value={country.id} key={c} data-name={country.name}>{country.name}</Option>
                                    )
                                })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>目的地开始居住年月：</span>
                            <DatePicker onChange={this.handleChange.bind(this,'endDistrictDate')} value={date}/>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem' id='dest'>
                            <span className='label_'>服务目的地(可多选)：</span>
                            <Select  style={{ width: 200 }} onSearch={this.seacrVals.bind(this)} optionFilterProp={'data-name'}   onChange={this.handleChange.bind(this,'serviceDestination')} value={params['serviceDestination']} showArrow={true} mode="multiple">
                                {dests.length>0&&dests.map((dest,d)=>{
                                    return(
                                        <Option value={dest.id} key={d} data-name={dest.name}>{dest.name}</Option>
                                    )
                                })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        {/* <div className='inputItem'>
                            <span className='label_'>车辆类型：</span>
                            <Select  style={{ width: 200 }} onChange={this.handleChange.bind(this,'carTypeId')} defaultValue={[]} showArrow={true} mode="multiple" value={params['carTypeId']}>
                                {carTypes.map((car,c)=>{
                                    return(
                                        <Option value={car.id} key={c}>{car.name}</Option>
                                    )
                                })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div> */}
                        <div className='inputItem'>
                            <span className='label_'>当地语言：</span>
                            <Select  style={{ width: 200 }} onChange={this.handleChange.bind(this,'languageLevel')} value={params['languageLevel']} showArrow={true} >
                                {localLans.map((local,l)=>{
                                        return(
                                            <Option value={local.id} key={l}>{local.name}</Option>
                                        )
                                    })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>英语水平：</span>
                            <Select style={{ width: 200 }} onChange={this.handleChange.bind(this,'englishLevel')} showArrow={true} value={params['englishLevel']}>
                                {englishLevs.map((english,e)=>{
                                        return(
                                            <Option value={english.id} key={e}>{english.name}</Option>
                                        )
                                    })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>向导标签(可多选)：</span>
                            <Select defaultValue="lucy" style={{ width: 200 }} onChange={this.handleChange.bind(this,'wizardTab')} value={params['wizardTab']} defaultValue={[]} showArrow={true} mode="multiple">
                                {tags.map((tag,t)=>{
                                        return(
                                            <Option value={tag.id} key={t}>{tag.name}</Option>
                                        )
                                    })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>多门语言(可多选)：</span>
                            <Select defaultValue="lucy" style={{ width: 200 }} onChange={this.handleChange.bind(this,'multiLanguage')} value={params['multiLanguage']} defaultValue={[]} showArrow={true} mode="multiple">
                            {moreLans.map((moreLan,m)=>{
                                    return(
                                        <Option value={moreLan.id} key={m}>{moreLan.name}</Option>
                                    )
                                })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>职业类型：</span>
                            <Select  style={{ width: 200 }} onChange={this.handleChange.bind(this,'jobType')}  showArrow={true} value={params['jobType']}>
                            {jobTypes.map((job,j)=>{
                                    return(
                                        <Option value={job.id} key={j}>{job.name}</Option>
                                    )
                                })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>附加服务(可多选)：</span>
                            <Select defaultValue="lucy" style={{ width: 200 }} onChange={this.handleChange.bind(this,'additionalServices')} value={params['additionalServices']} defaultValue={[]} showArrow={true} mode="multiple">
                            {elseSers.map((el,e)=>{
                                    return(
                                        <Option value={el.id} key={e}>{el.name}</Option>
                                    )
                                })}
                            </Select>
                            {/* <span className='mustIcon'>*</span> */}
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>接送机服务(可多选)：</span>
                            <Select defaultValue="lucy" style={{ width: 200 }} onChange={this.handleChange.bind(this,'conveyorService')} value={params['conveyorService']?params['conveyorService']:[]} defaultValue={[]} showArrow={true} mode="multiple">
                            {carTypes.map((go,g)=>{
                                    return(
                                        <Option value={go.id} key={g}>{go.name}</Option>
                                    )
                                })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>带车向导服务(可多选)：</span>
                            <Select defaultValue="lucy" style={{ width: 200 }} onChange={this.handleChange.bind(this,'guideService')} value={params['guideService']?params['guideService']:[]} defaultValue={[]} showArrow={true} mode="multiple">
                            {takeCar.map((rec,r)=>{
                                    return(
                                        <Option value={rec.id} key={r}>{rec.name}</Option>
                                    )
                                })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                        {/* <div className='inputItem'>
                            <span className='label_'>服务次数：</span>
                            <Input onChange={this.onChange.bind(this,'serviceTime')}  value={params['serviceTime']}/>
                            <span className='mustIcon'>*</span>
                        </div> */}
                        <div className='inputItem'>
                            <span className='label_'>向导服务(可多选)：</span>
                            <Select defaultValue="lucy" style={{ width: 200 }} onChange={this.handleChange.bind(this,'servableProducts')} value={params['servableProducts']} showArrow={true} mode="multiple">
                            {proTypes.map((pro,p)=>{
                                    return(
                                        <Option value={pro.id} key={p}>{pro.name}</Option>
                                    )
                                })}
                            </Select>
                            <span className='mustIcon'>*</span>
                        </div>
                    </div>
                    <div className='infoItem'>
                        <div className='title f20'>结算信息</div>
                        <div className='cover'></div>
                        <div>灵活结算：出团前邮件下单确认，团组结束后3个工作日无投诉结清团款。</div>
                    </div>
                    <div className='infoItem'>
                        <div className='title f20'>退改政策</div>
                        <div className='cover'></div>
                        <div>1.行程开始前7天（不含7天）以上取消订单，无需支付违约金；</div>
                        <div>2.行程开始前7-3天（不含3天）取消订单,支付订单金额的20%的违约金；</div>
                        <div>3.行程开始前3-1天（不含1天）取消订单,支付订单金额的50%的违约金；</div>
                        <div>4.行程开始24小时内取消订单,支付订单金额的100%的违约金。</div>
                    </div>
                    <div className='alignRight'>
                        <Button onClick={this.saveInfo.bind(this,1)} type="primary" className='saveBtn mr15'>上一页</Button>
                        <Button onClick={this.saveInfo.bind(this,0)} type="primary" className='saveBtn mr15'>保存</Button>
                        <Button onClick={this.saveInfo.bind(this)} type="primary" className='saveBtn'>保存并下一页</Button>
                    </div>
                </div>
                <Modal
                    title="温馨提示："
                    visible={this.state.visible}
                    onOk={this.confirmModify.bind(this)}
                    onCancel={this.hideModal.bind(this)}
                    okText="确认"
                    cancelText="取消">
                    <div>是否重新保存？重新保存后需重新审核。</div>
                </Modal>
                
            </div>
        );
    }
}
export default ServiceDes