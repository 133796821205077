import React, { Component } from 'react';
import { Icon } from 'antd';
class Protocol extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        let fees=[{'name':'美洲（北美洲、南美洲）','fee':'60元／30分钟'},{'name':'大洋洲（澳大利亚、新西兰）','fee':'50元／30分钟'},
        {'name':'欧洲','fee':'75元／30分钟'},
        {'name':'日本、新加坡','fee':'60元／30分钟'},
        {'name':'亚洲（除日本、新加坡）','fee':'50元／30分钟'},
        {'name':'非洲','fee':'30元／30分钟'}]
        let fee1=[{'name':'市内／周边包车','fee':'10小时/天','way':'300公里/天'},{'name':'长途包车（欧洲地区）','fee':'10小时/天','way':'450公里/天'},
        {'name':'长途包车（非欧洲地区）','fee':'10小时/天','way':'600公里/天'}]
        let fee2=[{'name':'欧洲、南美洲','fee':'150元/30分钟','way':'175元/30分钟'},{'name':'大洋洲、非洲、新加坡、北美洲、日本、香港','fee':'100元/30分钟','way':'125元/30分钟（北美洲为175/30分钟）'},
        {'name':'亚洲（不含日本、新加坡、香港）','fee':'50元/30分钟','way':'75元/30分钟'}]
        let fee3=[{'name':'欧洲、美洲（北美＋南美）、日本','fee':'200元/天'},{'name':'大洋洲、亚洲（除日本、印度尼西亚）、非洲','fee':'100元/天'},{'name':'印度尼西亚','fee':'不收取'}]
        return (
            <div className='protocolWrap' style={{width:'100%',marginTop:'0'}}>
                <h2 className='fb'>【接送机】</h2>
                <div className='fb'>一、费用说明</div>
                <div>
                    <span className='fb'>费用包含：</span>
                    司导服务费、小费，油费、过路费、高速费、进城费、拥堵费、空驶费。
                </div>
                <div>
                    <span className='fb'>费用不包含：</span>
                    等待超时费、行程变更导致服务里程变化产生的差价、附加收费服务费（举牌接机、协助登机、酒店check in等） 
                </div>
                <div className='fb'>二、免费等待说明</div>
                <div>1、接机：提供航班落地后免费等待90分钟服务，超出90分钟将收取等待费用。</div>
                <div>2、送机：提供免费等待30分钟服务，超出30分钟将收取等待费用。</div>
                <div>3、各地等待超时收费标准：司导按照30分钟为一档收取费用，不满30分钟按30分钟收取，超时等待费用现场支付给司导。</div>
                <table>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>大洲/国家</th>
                        <th>超时费用(人民币)</th>
                      </tr>
                    </thead>
                    <tbody>
                        {fees.map((fee,f)=>{
                            return(
                                <tr key={f}>
                                    <td>{f+1}</td>
                                    <td>{fee.name}</td>
                                    <td>{fee.fee}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <h2 className='fb mt10'>【按天包车游】</h2>
                <div className='fb'>一、市内/周边包车、跨城市包车的定义</div>
                <div>1、市内/周边包车：指在目的地城市、市内景点及近郊景点的按天包车游浏览；</div>
                <div>2、跨城市包车：指在多个城市按天包车游游览城市内景点、远郊景点及途中景点；</div>
                <div className='fb'>二、服务说明</div>
                <table>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>包车类型</th>
                        <th>服务里程</th>
                        <th>服务时间（从订单服务时间开始计算到服务结束）</th>
                      </tr>
                    </thead>
                    <tbody>
                        {fee1.map((fee,f)=>{
                            return(
                                <tr key={f}>
                                    <td>{f+1}</td>
                                    <td>{fee.name}</td>
                                    <td>{fee.way}</td>
                                    <td>{fee.fee}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className='fb'>三、费用说明</div>
                <div className='fb'>一、费用说明</div>
                <div>
                    <span className='fb'>费用包含：</span>
                    司导服务费、小费，司导餐补、司导住宿补助； 油费、过路费、高速费、进城费、拥堵费、空驶费
                </div>
                <div>
                    <span className='fb'>费用不包含：</span>
                    超时费，夜间服务费（22:00~08:00），景点门票，司导下车陪同讲解所产生的停车费。
                </div>
                <div>
                    <span className='fb' style={{color:'red'}}>超时费和夜间服务费说明：</span>
                </div>
                <div>1、超时费：司导按照30分钟为一档收取费用，30分钟以下免费，超过30分钟开始计算。</div>
                <div>
                    <span className='fb'>收费标准如下：</span>
                </div>
                <table>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>大洲/国家</th>
                        <th>5-8座车型</th>
                        <th>9-14座车型</th>
                      </tr>
                    </thead>
                    <tbody>
                        {fee2.map((fee,f)=>{
                            return(
                                <tr key={f}>
                                    <td>{f+1}</td>
                                    <td>{fee.name}</td>
                                    <td>{fee.way}</td>
                                    <td>{fee.fee}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div>2、夜间服务费： 常规服务时间段为08:00－22:00，如需非常规服务时间段（22:00至次日08:00）服务，可按以下标准要求客人支付夜间服务费，现场支付给司导。</div>
                <div>
                    <span className='fb'>收费标准如下：</span>
                </div>
                <table>
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>大洲/国家</th>
                        <th>夜间服务费(人民币)</th>
                      </tr>
                    </thead>
                    <tbody>
                        {fee2.map((fee,f)=>{
                            return(
                                <tr key={f}>
                                    <td>{f+1}</td>
                                    <td>{fee.name}</td>
                                    <td>{fee.fee}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <h2 className='fb mt10'>【徒步导游】</h2>
                <div className='fb'>服务说明：</div>
                <div>
                    <span className='fb'>一、费用包含：</span>
                    <div>1、导游服务费：每天9小时服务时间，不满9小时按9小时计算；服务时间自双方约定的见面时间开始计算。</div>
                    <div>2、导游餐补、导游往返客人会面地点的交通接驳费用。</div>
                </div>
                <div>
                    <span className='fb'>二、费用不包含：</span>
                    <div>1、景点门票费、客人餐费、住宿费、境外旅行保险费；</div>
                    <div>2、导游陪游门票、行程中的交通费、小费；</div>
                    <div>3、超时费：当天陪同服务时间超过9小时后的服务费用，按30分钟60元人民币现付给向导。</div>
                </div>
                <h2 className='fb mt10'>【商务/展会翻译】</h2>
                <div className='fb'>服务说明：</div>
                <div>
                    <span className='fb'>一、费用包含：</span>
                    <div>1、展会翻译：每天8小时服务时间，不满8小时按8小时计算； 半天服务按照4小时计算。</div>
                    <div>2、翻译人员往返客人会面地点的交通接驳费用、服务小费。</div>
                </div>
                <div>
                    <span className='fb'>二、费用不包含：</span>
                    <div>1、超时费：当天陪同服务时间超过8小时后的服务费用，按照1.3倍时薪现付给翻译人员。</div>
                </div>
                <h2 className='fb mt10'>【摄影服务】</h2>
                <div className='fb'>服务说明：</div>
                <div>
                    <span className='fb'>一、费用包含：</span>
                    <div>1、摄影服务。</div>
                    <div>2、摄影人员往返客人会面地点的交通接驳费用、服务小费。</div>
                </div>
                <div>
                    <span className='fb'>二、费用不包含：</span>
                    <div>1、摄影助理、化妆、道具、场地费等。</div>
                </div>
            </div>
        );
    }
}
export default Protocol