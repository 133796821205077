import React, { Component } from 'react';
import AddCar from '../components/AddCar' 
import { Icon,Modal,message,Button,Table ,Checkbox  } from 'antd';
import {URL} from  '../config/url';
import axios from 'axios';
import moment from 'moment';
const monthFormat = 'YYYY/MM';
let options={
    top:450,
    duration:1.5,
}
const confirm = Modal.confirm;
message.config(options)
const CheckboxGroup = Checkbox.Group;
class ServiceCar extends Component{
    constructor(props){
        super(props)
        this.state={
            isShow:false,
            isPic:false,
            dests:[],
            cars:[],
            carPics:[],
            delArr:[],
            selected:[],
            carItem:'',
            param:{carAddress:''},
            isHaveCar:false,
            paramTxt:{'files':'请上传至少一张车辆图片','carAddress':'请选择车辆所在地','carBrandId':'请选择车的品牌','carNumber':'请输入车牌号','carTypeId':'请选择车型','passenger':'请选择车辆座位'}
        }
    }
  async  carShow(car){
        let {param}=this.state
        let curDate=moment(new Date).format(monthFormat)
        param['purchaseTime']=curDate;
        if(car){
            param['carAddress']=car.districtId
           param['carBrandId']=car.carBrandNO
           param['carTypeId']=car.typeId
           param['passenger']=car.passenger
           param['carNumber']=car.carNumber
           param['purchaseTime']=car.purchaseTime;
           param['files']=[]
           if(car.carListStr){
            let imgs=car.carListStr.split(',')
            imgs.map((img,i)=>{
                let item={'url':img,'status':'done','uid':i}
                param['files'].push(item)
            })
           }
        }else{
            param['id']=''
        }
      await  this.setState({
            isShow:true,
            carItem:car?car:1
        })
    }
    checkAll(){

    }
    carHide(){
        this.setState({
            isShow:false,
            carItem:''
        })
    }
    reflash(){
      window.location.reload()
    }
    getCarTypeInfo(params){
        let {param}=this.state
        for (const key in params) {
           if(key=='carBrandId'&&isNaN(param.key*1)){
            param[key]=params.carBrandId
           }else if(key=='carTypeId'&&isNaN(param.key*1)){
            param[key]=params.carTypeId
           }else if(key=='carAddress'&&isNaN(param.key*1)){
            param[key]=params.carAddress
           }else if(key=='files'){
            let arr=[]
            params[key].map((img)=>{
                arr.push(img.url?img.url:img)
            })
            param['files']=arr
           }else{
               param[key]=params[key]
           }
        }
        this.setState({
            param
        })
    }
  async  delCar(){
        let {delArr,cars,selected}=this.state
        let ids=[]
        delArr.map((sel)=>{
                    ids.push(sel.id)
                })
        if(selected.length>0){
            ids=selected
        }
        var param = new URLSearchParams();
            param.append('ids',ids)
        let res=await axios.post(`${URL}/SERVICE-MT-GUIDE/car/delete`,param,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
        if(res.data.code==200&&res.data.success){
            message.success('删除成功')
        }else{
            message.error('删除失败，稍后重试')
        }
        let carss= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/list?id=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
       this.setState({
           cars:carss.data.data
       })
    }
    async  delCar1(id){
        let {cars}=this.state
        let _this=this
        var param = new URLSearchParams();
            param.append('ids',[id])
            confirm({
                title: '温馨提示：',
                content: '确认删除此车辆？',
                onOk() {
                         axios.post(`${URL}/SERVICE-MT-GUIDE/car/delete`,param,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}}).then((res)=>{
                        if(res.data.code==200&&res.data.success){
                            message.success('删除成功')
                            axios.get(`${URL}/SERVICE-MT-GUIDE/car/list?id=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}}).then((res)=>{
                                  _this.setState({
                                       cars:res.data.data
                                   })
                               })
                        }else{
                            message.error('删除失败，稍后重试')
                        }
                    })
                    
                    
                   
                },
                onCancel() {},
              });
              return false
        
    }
  async  submitInfo(){
        let {param,paramTxt,carItem,cars}=this.state
        param['guideId']=sessionStorage.getItem('guideId')
        param.page=4
        if(carItem&&carItem!=1){
            param.id=carItem.id
        }
        if(isNaN(param.carAddress*1)){
            let id
            cars.map((item)=>{
                if(item.id==param.id){
                    id=item.districtId
                }
            })
            
            param.carAddress=id
        }
        let carImgs=[]
        param['files'].map((item)=>{
            if(item.url){
                carImgs.push(item.url)
            }else{
                carImgs.push(item)
            }
        })
        param['files']=carImgs
        var params = new URLSearchParams();
        for (const key in param) {
            params.append([key],param[key])
            if(!param[key]&&paramTxt[key]){
                message.warning(paramTxt[key])
                return false
            }
        }
       let res= await axios.post(`${URL}/SERVICE-MT-GUIDE/car/save`,params,{headers:{'DM_AUTH':this.props.token}})
        if(res.data.success){
            message.success('保存成功')
            this.setState({
                isShow:false,
                carItem:1
            })
        }
        let carss= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/list?id=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
       this.setState({
           cars:carss.data.data
       })
    }
  async  componentDidMount(){
        // var params = new URLSearchParams();
        // params.append('id',this.props.userData.id)
       let cars= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/list?id=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
       let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/guide/detail?guideId=${sessionStorage.getItem('guideId')}`,{headers:{'DM_AUTH':sessionStorage.getItem('userToken')}})
      if(data.data.data.tagVOList){
        data.data.data.tagVOList.map((list)=>{
            if(list.hydeTagType==6&&list.id!=9388){
              this.setState({
                  isHaveCar:true
              })
            }
        })
      }
       this.setState({
           cars:cars.data.data
       })
    }
  async  lookPic(id){
        let {cars}=this.state
        cars.map((car)=>{
            if(car.id==id){
                let arr=[]
                arr=car.carListStr?car.carListStr.split(','):[]
                this.setState({
                    carPics:arr,
                    isPic:true
                })
            }
        })
    }
    picShow(){
        this.setState({
            isPic:false
        })
    }
    saveInfo(num){
        let {cars,isHaveCar}=this.state
        if(num==1){
            window.scrollTo(0,0)
            if(this.props.userData.companyId==3525){
                this.props.goToNext(4)
            }else{
                this.props.goToNext(3)
            }   
        }else{
            if(isHaveCar&&cars.length==0){
                message.warning('请添加一个车辆')
                return false
            }
            window.scrollTo(0,0)
            window.location='/#/userInfo'
        }
    }
    overView(){
        window.location='/#/userInfo'
    }
    getImgs(imgs){
        let {param}=this.state
        param['files']=[]
        imgs.map((img)=>{
            param['files'].push(img.response?img.response.data:img.url)
            
        })
        this.setState({
            param
        })
    }
    onChange(id,e){
        let {selected}=this.state
        if(e.target.checked){
            selected.push(id)
        }else{
            selected.splice(selected.indexOf(id),1)
        }
        this.setState({
            selected
        })
    }
    render(){
        let {isShow,cars,carPics,isPic,carItem}=this.state
        const columns = [ {
            title: '编号',
            dataIndex: 'id'
          },{
            title: '车品牌',
            dataIndex: 'carNme'
          }, {
            title: '车型',
            dataIndex: 'typeName'
          }, {
            title: '车座位',
            dataIndex: 'passenger'
          },{
            title: '车辆所在地',
            dataIndex: 'address'
          }, {
            title: '车牌号',
            dataIndex: 'carNumber'
          }, {
            title: '车辆照片',
            render: (item) => <a onClick={this.lookPic.bind(this,item.id)}>查看</a>,
          }, {
            title: '添加时间',
            dataIndex: 'crateTime'
          }, {
            title: '操作',
            render: (item) => <a onClick={this.carShow.bind(this,item)}>修改</a>,
          }]
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    delArr:selectedRows
                })
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
              disabled: record.name === 'Disabled User', // Column configuration not to be checked
              name: record.name,
            }),
          };
          let arr=[]
        cars.map((car,c)=>{
                arr.push(<div key={c} style={{marginTop:'20px',display:'flex'}}>
                <Checkbox className='mr15 chooseIcon' onChange={this.onChange.bind(this,car.id)}></Checkbox>
                 <div className='ml15 carItem'>
                      <span className='infoItem w100'><span>编号：</span>{car.id}</span>
                      <span className='infoItem w100'><span>车品牌：</span>{car.carNme}</span>
                      <span className='infoItem w100'><span>车型：</span>{car.typeName}</span>
                      <span className='infoItem w100'><span>车座位：</span>{car.passenger}</span>
                      <span className='infoItem w100'><span>车辆所在地：</span>{car.address}</span>
                      <span className='infoItem w100'><span>车牌号：</span>{car.carNumber}</span>
                      <span className='infoItem w100 mc'><span>车辆照片：</span><a onClick={this.lookPic.bind(this,car.id)}>查看</a></span>
                      <span className='infoItem w100'><span>操作：</span><a onClick={this.carShow.bind(this,car)}>修改</a> / <a onClick={this.delCar1.bind(this,car.id)}>删除</a></span>
                      <span className='infoItem w100'><span>添加时间：</span>{car.crateTime}</span>
                  </div>
                </div>)
            })
        return(
            <div className='service_car'>
                    <div className='top_nav'>
                        <Button onClick={this.carShow.bind(this,'')}  icon='plus' type="primary" className='mr15'>新增</Button>
                        <Button onClick={this.delCar.bind(this)}  icon='delete' type="primary" className='mr15 delBtn'>删除</Button>
                    </div>
                    <div className='table_m'>{arr}</div>
                    <div className='table_pc'><Table rowSelection={rowSelection} columns={columns} dataSource={cars} pagination={false}/></div>
                    <div className='alignRight mt10'>
                        <Button onClick={this.saveInfo.bind(this,1)} type="primary" className='saveBtn mr15'>上一页</Button>
                        <Button onClick={this.overView.bind(this)} type="primary" className='saveBtn'>预览</Button>
                    </div>
                    <Modal  visible={isShow}   onOk={this.submitInfo.bind(this)}
                        onCancel={this.carHide.bind(this)} >
                        {carItem&&<AddCar carHide={this.carHide.bind(this)} carItem={carItem} token={this.props.token} getImgs={this.getImgs.bind(this)} getCarTypeInfo={this.getCarTypeInfo.bind(this)}></AddCar>}
                    </Modal>
                    <Modal  visible={isPic}  
                        onCancel={this.picShow.bind(this)} footer={null}>
                        {carPics.map((carPic,c)=>{
                            return(
                                <div  key={c} style={{textAlign:'center'}}>
                                    <img src={carPic} alt="" style={{width:'200px'}}/>
                                </div>
                            )
                        })}
                    </Modal>
            </div>
        )
    }
}
export default ServiceCar