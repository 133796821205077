import React, { Component } from 'react';
import ReactUmeditor from 'react-umeditor'
import {URL} from  '../config/url';
class Editors extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			content: ""
		}
	}
	handleChange(content){
        this.props.getValue(content)
		this.setState({
			content: content
		})
	}
async	componentDidMount(){
		if(this.props.values){
		await	this.setState({
				content: this.props.values
			})
		}
	}
	getIcons(){
		var icons = [
			"source | undo redo | bold italic underline strikethrough fontborder emphasis | ",
			"paragraph fontfamily fontsize | superscript subscript | ",
			"forecolor backcolor | removeformat | insertorderedlist insertunorderedlist | selectall | ",
			"cleardoc  | indent outdent | justifyleft justifycenter justifyright | touppercase tolowercase | ",
			"horizontal date time  | image emotion spechars | inserttable"
		]
		return icons;
	}
	getPlugins(){
		return {
			"image": { 
				"uploader": { 
					"name":"file", 
					"url": `${URL}/SERVICE-MT-GUIDE/img/save?DM_AUTH=${sessionStorage.getItem('userToken')}&basePath=${`guide/img/cost`}` ,
					filter:(res)=>res.data
				} 
			} 
		}
	}
	render(){
	    var icons = this.getIcons();
		var plugins = this.getPlugins();
		let {content}=this.state
		if(!content){
			content=this.props.values
		}
		return (<ReactUmeditor ref="editor" 
			icons={icons} 
			value={content}  
			onChange={this.handleChange.bind(this)} 
			plugins={plugins} />)
	}
}
export default Editors