import React, { Component } from 'react';
import {Cascader,Select,Input,Modal ,DatePicker} from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import Upload from '../components/Upload'
import {URL} from  '../config/url';
import axios from 'axios';
import moment from 'moment';
import ElseUpload from './ElseUpload'
import car1 from '../images/car1.jpg'
import car2 from '../images/car2.jpg'
import car3 from '../images/car3.jpg'
import car4 from '../images/car4.jpg'
import car5 from '../images/car5.jpg'
const Option = Select.Option;
const { MonthPicker } = DatePicker;
const monthFormat = 'YYYY/MM';
const dateFormat = 'YYYY/MM/DD';
class AddCar extends Component{
    constructor(props){
        super(props)
        this.state={
            dests:[],
            brands:[],
            params:{'files':[],'carAddress':'','carBrandId':'','carNumber':'','carTypeId':'','passenger':'','purchaseTime':''},
            types:[],
            sets:[],
            passenger:'',
            previewVisible:false,
            previewImage:'',
            guideImgs:[],
            carItemId:'',
        }
    }
   carHide(){
       this.props.carHide()
   }
  async componentWillReceiveProps(e){
    let {params,guideImgs}=this.state
    let {carItem}=e
    if(carItem&&carItem.id!=this.state.carItemId&&carItem!=1){
        params['carAddress']=carItem.address
           params['carBrandId']=carItem.carBrandNO
           params['carTypeId']=carItem.typeId
           params['passenger']=carItem.passenger
           params['carNumber']=carItem.carNumber
           params['purchaseTime']=carItem.purchaseTime;
        params['files']=[]
        guideImgs=[]
        if(carItem.carListStr){
        let imgs=carItem.carListStr.split(',')
        imgs.map((img,i)=>{
         let item={'url':img,'status':'done','uid':i}
         guideImgs.push(item)
         params['files'].push(item)
        })
        }
        
        let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getCar?id=${carItem.carBrandNO}`,{headers:{'DM_AUTH':this.props.token}})
            this.setState({
                types:brand.data.data,
                params,
                guideImgs,
                carItemId:e.carItem.id
            })
    }
   }
   async componentDidMount(){
       let {params,guideImgs,defaultVal}=this.state
       let {carItem}=this.props
       let curDate=moment(new Date).format(monthFormat)
       params['purchaseTime']=curDate;
       defaultVal=curDate;
       if(carItem&&carItem!=1){
           params['carAddress']=carItem.address
           params['carBrandId']=carItem.carBrandNO
           params['carTypeId']=carItem.typeId
           params['passenger']=carItem.passenger
           params['carNumber']=carItem.carNumber
           params['purchaseTime']=carItem.purchaseTime;
           params['files']=[]
           if(carItem.carListStr){
            let imgs=carItem.carListStr.split(',')
            imgs.map((img,i)=>{
             let item={'url':img,'status':'done','uid':i}
             guideImgs.push(item)
             params['files'].push(item)
            })
           }
            let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getCar?id=${carItem.carBrandNO}`,{headers:{'DM_AUTH':this.props.token}})
            this.setState({
                types:brand.data.data,
                carItemId:carItem.id,
            })
       }
    let data= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getDistrictName?name=&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
    let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getAllCarBrand`,{headers:{'DM_AUTH':this.props.token}})
    let carType= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24421`,{headers:{'DM_AUTH':this.props.token}})
   await this.setState({
        dests:data.data.data,
        brands:brand.data.data,
        sets:carType.data.data,
        params,
        guideImgs,
        defaultVal
    })
}
onChange(key,e){
    let {params}=this.state
    params[key]=e.target.value
    this.setState({
        params
    })
    this.props.getCarTypeInfo(params)
}
async chooseAddress(e){
    let {params}=this.state
    params['carAddress']=e
    this.setState({
        params
    })
    this.props.getCarTypeInfo(params)
}
async handleChange(e,key){
    let {params}=this.state
    params['carBrandId']=key
    let brand= await axios.get(`${URL}/SERVICE-MT-GUIDE/car/getCar?id=${key}`,{headers:{'DM_AUTH':this.props.token}})
    this.setState({
        types:brand.data.data,
    })
    this.props.getCarTypeInfo(params)
}
async handleChanges(e,key){
    let {params}=this.state
    params[e]=key
    await  this.setState({
            params
        })
    let sets=[]
    this.state.types.map((type)=>{
        if(type.typeId==key){
            sets.push(type)
            this.setState({
                sets,
                params
            })
        }
    })
    this.props.getCarTypeInfo(params)
}
getImgUrl(url,key,index){
    let {params}=this.state
    if(!params[key][index]){
        params[key].push(url)
    }else{
        params[key][index]=url
    }
    this.setState({
        params
    })
    this.props.getCarTypeInfo(params)
}
getGuideImg(data){
    this.props.getImgs(data)
}
handleCancel(){
    this.setState({
        previewVisible:false
    })
}
lookPic(url){
    this.setState({
        previewImage:url,
        previewVisible:true
    })
}
async seacrVals(e){
    let value=document.querySelectorAll('#car_address .ant-select-search__field')[0].value
    if(value){
        let res= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getDistrictName?name=${value}&types=${['c']}`,{headers:{'DM_AUTH':this.props.token}})
        await this.setState({
            dests:res.data.data
        })
    }
    }
    onChangeDate(e){
       let {params}=this.state
       params['purchaseTime']=moment(e).format(monthFormat)
       this.setState({
           params
       })
       this.props.getCarTypeInfo(params)
    }
    render(){
        let {dests,brands,types,sets,passenger,previewVisible,previewImage,params,guideImgs,defaultVal}=this.state
        return(
            <div className='modals add_Car'>
                {/* <div className='add_head'>
                    <span>新增</span>
                    <span onClick={this.carHide.bind(this)} className='point'>关闭</span>
                </div> */}
                <div className='car_address' id='car_address'>

                    <span className='label_'>车辆所在地：</span> 
                    <Select  style={{ width: 260 }} onSearch={this.seacrVals.bind(this)} value={params['carAddress']}   onChange={this.chooseAddress.bind(this)} 
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}  showArrow={true} showSearch>
                                {dests.map((dest,de)=>{
                                    return(
                                        <Option value={dest.id} key={de}>{dest.name}</Option>
                                    )
                                })}
                    </Select>
                </div>
                <div className='car_address'>
                    <span className='label_'>车型：</span>
                    <div style={{paddingLeft:'100px'}} className='mt10'>
                        <Select placeholder='请选择车型' style={{width:260}}  value={params['carBrandId']?params['carBrandId']:'请选择车品牌'}  onChange={this.handleChange.bind(this,'carBrandId')} 
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}  showArrow={true} showSearch>
                                    {brands.map((brand,b)=>{
                                        return(
                                            <Option value={brand.id} key={b}>{brand.name}</Option>
                                        )
                                    })}
                        </Select>
                    </div>
                    <div style={{paddingLeft:'100px'}} className='mt10'>
                        <Select placeholder='请选择' style={{width:260}} value={params['carTypeId']?params['carTypeId']:'请选择车型'}   onChange={this.handleChanges.bind(this,'carTypeId')} 
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}  showArrow={true} showSearch>
                                    {types.map((type,t)=>{
                                        return(
                                            <Option value={type.typeId} key={t}>{type.name}</Option>
                                        )
                                    })}
                        </Select>
                    </div>
                    <div style={{paddingLeft:'100px'}} className='mt10'>
                        <Select placeholder='请选择' style={{width:260}} value={params['passenger']?params['passenger']:'请选择车座位'} onChange={this.handleChanges.bind(this,'passenger')} 
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}  showArrow={true} showSearch>
                                    {sets.map((set,s)=>{
                                        return(
                                            <Option value={set.passenger} key={s}>{set.passenger}</Option>
                                        )
                                    })}
                        </Select>
                    </div>
                </div>
                <div className='car_address'>
                <span className='label_'>车牌号：</span>
                <Input placeholder='请输入车牌号：' style={{width:260}} value={params['carNumber']}  onChange={this.onChange.bind(this,'carNumber')}/>
                {/* <input placeholder='请输入' style={{width:260}} onChange={this.onChange.bind(this,'carNumber')}></input> */}
                </div>
                <div className='car_address'>
                <span className='label_'>车购置年月：</span>
                {params['purchaseTime']&&<MonthPicker  locale={locale} defaultValue={moment(params['purchaseTime'])}   format={monthFormat} onChange={this.onChangeDate.bind(this)}/>}
                </div>
                <div className='car_address'>
                    <span className='label_'>车辆照片：</span>
                    <div className='inputItem w100'>
                            <ElseUpload getGuideImg={this.getGuideImg.bind(this)} guideImgs={guideImgs}></ElseUpload>
                            {/* <Upload label={''} getImgUrl={this.getImgUrl.bind(this)} keys={'files'} index='0' basePath={'guide/img/car'}></Upload>
                            <Upload label={''} getImgUrl={this.getImgUrl.bind(this)} keys={'files'} index='1' basePath={'guide/img/car'}></Upload>
                            <Upload label={''} getImgUrl={this.getImgUrl.bind(this)} keys={'files'} index='2' basePath={'guide/img/car'}></Upload> */}
                    </div>
                </div>
                <div className='exImgs'>
                <span className='label_'>样例：</span>
                    <div className='center'>
                        <img src={car1} alt="" onClick={this.lookPic.bind(this,car1)}/>
                        <img src={car2} alt=""  onClick={this.lookPic.bind(this,car2)}/>
                        <img src={car3} alt=""  onClick={this.lookPic.bind(this,car3)}/>
                        <img src={car4} alt=""  onClick={this.lookPic.bind(this,car4)}/>
                        <img src={car5} alt=""  onClick={this.lookPic.bind(this,car5)}/>
                    </div>
                </div>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel.bind(this)}
                    >
                    <img alt="example" style={{ width: "100%" }} src={previewImage} />
                </Modal>
            </div>
        )
    }
}
export default AddCar