import React, { Component } from "react";
import { Upload, Icon, Modal } from "antd";
import {URL} from '../config/url'
import axios from 'axios'
class Uploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      fileList: []
    };
  }
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };
  beforeUpload(file){
    const formData = new FormData();
    formData.append('file', file);
    axios({
      url: `${URL}/SERVICE-MT-GUIDE/img/save?basePath=${this.props.basePath}`,
      method: 'post',
      headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
      data: formData,
    }).then((res)=>{
      this.props.getImgUrl(res.data.data,this.props.keys,this.props.index)
    })
  }
  componentDidMount(){
    if(this.props.imgs){
      this.props.imgs['url']=this.props.imgs.file
      this.props.imgs['status']='done'
      this.props.imgs['uid']='-1'
      this.setState({
        fileList:[this.props.imgs]
      })
    }
  }
  handleChange({fileList}){
    this.setState({ fileList })
    if(fileList.length==0){
      this.props.getImgUrl('',this.props.keys,this.props.index)
    }
  }
  // handleChange = ({ fileList }) => this.setState({ fileList })
  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const {label,isCol} =this.props
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    let style={
        alignSelf:'center',
        // marginRight:'10px',
        width:'100px',
        textAlign:'right'
    }
    return (
      <div className="uploadItem" style={{display:isCol?'flex':''}}>
      {isCol&&<div className='txt' style={style}>{label}</div>}
        <Upload
          action={`${URL}/SERVICE-MT-GUIDE/img/save?DM_AUTH=${sessionStorage.getItem('userToken')}&basePath=${this.props.basePath}`}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange.bind(this)}
          beforeUpload={this.beforeUpload.bind(this)}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        {!isCol&&<div className='txt'>{label}</div>}
      </div>
    );
  }
}
export default Uploads;
