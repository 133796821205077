import React, { Component } from 'react';
import { DatePicker,Select,message,Modal,Icon,Upload,Input,Button} from 'antd';
import Uploads from '../components/Upload'
import {URL} from  '../config/url';
import moment from 'moment';
import ElseUpload from './ElseUpload'
import axios from 'axios'
const Option = Select.Option;
class BaseInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params:{'passportNumber':'','passportValidity':'','passportURL':[],'driverNumber':'','driverValidity':'',
            'drivingLicensURL':[],'guideNumber':'','guideCardValidity':'','guideStar':'','guideCardOrg':'','gurdeCardURL':[],'page':2,'account':'','openBankName':'','openAccountName':''},
            guideRank:[],
            previewVisible: false,
            previewImage: '',
            fileList: [],
            guideImgs:[],
            postImgs:[],
            visible:false,
            isNext:''
        }
    }
    getGuideImg(list){
        this.setState({
            guideImgs:list
        })
    }
    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = (file) => {
      this.setState({
        previewImage: file.url || file.thumbUrl,
        previewVisible: true,
      });
    }
  
    handleChange = ({ fileList }) => this.setState({ fileList })
    onChange(key,e,date){
        let {params}=this.state
        params[key]=date
        this.setState({
            params
        })
    }
    // handleChange(e,val){
    //     let {params}=this.state
    //     params[e]=val
    //     this.setState({
    //         params
    //     })
    // }
    getImgUrl(url,key,index){
        let {params}=this.state
        params[key][index]=url
        this.setState({
            params
        })
    }
    inputVal(key,e){
        let {params}=this.state
        params[key]=e.target.value
        this.setState({
            params
        })
    }
   async componentDidMount(){
       let {params,fileList,guideImgs,postImgs}=this.state
       for (const key in params) {
            if(this.props.userData[key]&&key!='passportURL'&&key!='drivingLicensURL'&&key!='gurdeCardURL'){
                params[key]=this.props.userData[key]
            }
       }
       if(this.props.userData.hydeGuideImgList){
           this.props.userData.hydeGuideImgList.map((img,i)=>{
        if(img.imgType==1){
            let item={'url':img.file,'status':'done','uid':i}
            params['passportURL'].push(img.file)
            fileList.push(item)
        }else if(img.imgType==3){
            let item={'url':img.file,'status':'done','uid':i}
            params['gurdeCardURL'].push(img.file)
            guideImgs.push(item)
        }else if(img.imgType==2){
            let item={'url':img.file,'status':'done','uid':i}
            params['drivingLicensURL'].push(img.file)
            postImgs.push(item)
        }
       })
       }
        let guideRank= await axios.get(`${URL}/SERVICE-MT-GUIDE/tag/list?id=24428`,{headers:{'DM_AUTH':this.props.token}})
        this.setState({
            guideRank:guideRank.data.data,
            fileList,
            guideImgs
        })
    }
    hideModal(){
        this.setState({
            visible:false,
        })
    }
    showModal(isNext){
        this.setState({
            visible:true,
            isNext
        })
    }
    confirmModify(){
        let {params,fileList,guideImgs,postImgs,isNext}=this.state
        this.setState({
            visible:false,
        })
        if(fileList.length>0){
            let arr=[]
            fileList.map((list)=>{
                if(list.url){
                    arr.push(list.url)
                }else{
                    arr.push(list.response.data)
                }
            })
            params['passportURL']=arr
        }
        if(guideImgs.length>0){
            let arr=[]
            guideImgs.map((list)=>{
                if(list.url){
                    arr.push(list.url)
                }else{
                    arr.push(list.response.data)
                }
            })
            params['gurdeCardURL']=arr
        }
        if(!params['passportNumber']&&!params['driverNumber']){
            message.warning('驾照信息，护照信息两个必须填写一个')
            return false
        }
        if(params['passportNumber']&&params['passportURL'].length==0){
            message.warning('请上传护照照片')
            return false
        }
        if(params['driverNumber']&&params['drivingLicensURL'].length==0){
            message.warning('请上传驾照照片')
            return false
        }
        var param = new URLSearchParams();
        params['id']=sessionStorage.getItem('guideId')
        params['status']=1
        for (const key in params) {
            if(key=='passportValidity'||key=='driverValidity'||key=='guideCardValidity'){
                params[key].replace('00:00:00','')
            }
            param.append([key],params[key]) 
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                if(isNext==0){
                 message.success('保存成功')
                }else{
                    window.scrollTo(0,0)
                    this.props.goToNext(2)
                }
             }
          })
    }
    saveInfo(isNext){
        if(isNext==1){
            window.scrollTo(0,0)
            this.props.goToNext(0)
            return false
        }
        if(this.props.userData.status==2){
            this.showModal(isNext)
            return false;
        }
        let {params,fileList,guideImgs,postImgs}=this.state
        if(fileList.length>0){
            let arr=[]
            fileList.map((list)=>{
                if(list.url){
                    arr.push(list.url)
                }else{
                    arr.push(list.response.data)
                }
            })
            params['passportURL']=arr
        }else{
            params['passportURL']=[]
        }
        if(guideImgs.length>0){
            let arr=[]
            guideImgs.map((list)=>{
                if(list.url){
                    arr.push(list.url)
                }else{
                    arr.push(list.response.data)
                }
            })
            params['gurdeCardURL']=arr
        }
        if(!params['passportNumber']&&!params['driverNumber']){
            message.warning('驾照信息，护照信息两个必须填写一个')
            return false
        }
        if(params['passportNumber']&&params['passportURL'].length==0){
            message.warning('请上传护照照片')
            return false
        }
        if(params['driverNumber']&&params['drivingLicensURL'].length==0){
            message.warning('请上传驾照照片')
            return false
        }
        var param = new URLSearchParams();
        params['id']=sessionStorage.getItem('guideId')
        for (const key in params) {
            if(key=='passportValidity'||key=='driverValidity'||key=='guideCardValidity'){
                params[key].replace('00:00:00','')
            }
            param.append([key],params[key]) 
        }
        axios({
            url: `${URL}/SERVICE-MT-GUIDE/guide/save`,
            method: 'post',
            headers:{'DM_AUTH':sessionStorage.getItem('userToken')},
            data: param
          }).then((res)=>{
            if(res.data.success){
                if(isNext==0){
                 message.success('保存成功')
                }else{
                    window.scrollTo(0,0)
                    this.props.goToNext(2)
                }
             }
          })
    }
    render() {
        let {guideRank,params,guideImgs}=this.state
        const dateFormat = 'YYYY-MM-DD';
        let date,date1,date2
        if(params['passportValidity']){
            params['passportValidity']=params['passportValidity'].replace(' 00:00:00','')
            date=moment(params['passportValidity'], dateFormat)
        }else if(params['driverValidity']){
            params['driverValidity']=params['driverValidity'].replace(' 00:00:00','')
            date1=moment(params['driverValidity'], dateFormat)
        } else if(params['guideCardValidity']){
            params['guideCardValidity']=params['guideCardValidity'].replace(' 00:00:00','')
            date2=moment(params['guideCardValidity'], dateFormat)
        } 
        
        let passports=[]
        let drives=[]
        let guides=[]
       if(this.props.userData.hydeGuideImgList){
        this.props.userData.hydeGuideImgList.map((list)=>{
            if(list.imgType==1){
                passports.push(list)
            }else if(list.imgType==2){
                drives.push(list)
            }else if(list.imgType==3){
                guides.push(list)
            }
        })
       }
       const { previewVisible, previewImage, fileList } = this.state;
       const uploadButton = (
         <div>
           <Icon type="plus" />
           <div className="ant-upload-text">Upload</div>
         </div>
       );
        return (
            <div className='baseInfo'>
                <div className='infos'>
                    <div className='infoItem'>
                        <div className='title f20'>护照</div>
                        <div className='cover'></div>
                        <div className='inputItem'>
                            <span className='label_'>护照号码：</span>
                            <Input type="text" onChange={this.inputVal.bind(this,'passportNumber')} value={params['passportNumber']} placeholder='请输入护照号码'/>
                            {/* <input type="text" onChange={this.inputVal.bind(this,'passportNumber')} value={params['passportNumber']}/> */}
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>护照有效期：</span>
                            <DatePicker onChange={this.onChange.bind(this,'passportValidity')} value={params['passportValidity']?moment(params['passportValidity'], dateFormat):null}/>
                        </div>
                        <div className='inputItem w100'>
                            <span className='vTop label_'>护照照片：</span>
                                <div className="clearfix mt20" style={{marginLeft:'100px'}}>
                                    <Upload
                                    action={`${URL}/SERVICE-MT-GUIDE/img/save?DM_AUTH=${sessionStorage.getItem('userToken')}&basePath=guide/img/passport`}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleChange}
                                    >
                                    {fileList.length >= 4 ? null : uploadButton}
                                    </Upload>
                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </div>
                            {/* <Uploads label={'正面照'} getImgUrl={this.getImgUrl.bind(this)} keys={'passportURL'} index='0' imgs={passports[0]} basePath={"guide/img/passport"}></Uploads>
                            <Uploads label={'反面照'} getImgUrl={this.getImgUrl.bind(this)} keys={'passportURL'} index='1' imgs={passports[1]}  basePath={"guide/img/passport"}></Uploads> */}
                        </div>
                    </div>
                    <div className='infoItem'>
                        <div className='title f20'>驾照</div>
                        <div className='cover'></div>
                        <div className='inputItem'>
                            <span className='label_'>驾照号码：</span>
                            <Input type="text" onChange={this.inputVal.bind(this,'driverNumber')} value={params['driverNumber']} placeholder='请输入驾照号码'/>
                            {/* <input type="text" onChange={this.inputVal.bind(this,'driverNumber')} value={params['driverNumber']} /> */}
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>驾照有效期：</span>
                            <DatePicker onChange={this.onChange.bind(this,'driverValidity')} value={params['driverValidity']?moment(params['driverValidity'], dateFormat):null}/>
                        </div>
                        <div className='inputItem w100'>
                            <span className='vTop label_'>驾照照片：</span>
                            <Uploads label={'正面照'} getImgUrl={this.getImgUrl.bind(this)} keys={'drivingLicensURL'} index='0' imgs={drives[0]}  basePath={"guide/img/drivingLicense"}></Uploads>
                            <Uploads label={'反面照'} getImgUrl={this.getImgUrl.bind(this)} keys={'drivingLicensURL'} index='1' imgs={drives[1]}  basePath={"guide/img/drivingLicense"}></Uploads>
                        </div>
                    </div>
                    <div className='infoItem'>
                        <div className='title f20'>导游证</div>
                        <div className='cover'></div>
                        <div className='inputItem'>
                            <span className='label_'>导游证编号：</span>
                             <Input type="text" onChange={this.inputVal.bind(this,'guideNumber')} value={params['guideNumber']} placeholder='请输入导游证编号'/>
                            {/* <input type="text" onChange={this.inputVal.bind(this,'guideNumber')} value={params['guideNumber']}/> */}
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>导游证有效期：</span>
                            <DatePicker onChange={this.onChange.bind(this,'guideCardValidity')} value={params['guideCardValidity']?moment(params['guideCardValidity'], dateFormat):null}/>
                        </div>
                        {/* <div className='inputItem'>
                            <span className='label_'>导游等级：</span>
                            <Select  style={{ width: 200 }} onChange={this.handleChange.bind(this,'guideStar')}  showArrow={true} value={params['guideStar']}>
                                {guideRank.map((guide,g)=>{
                                        return(
                                            <Option value={guide.id} key={g}>{guide.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div> */}
                        <div className='inputItem'>
                            <span className='label_'>导游发证机构：</span>
                            <Input type="text" onChange={this.inputVal.bind(this,'guideCardOrg')} value={params['guideCardOrg']} placeholder='请输入导游发证机构'/>
                            {/* <input type="text" onChange={this.inputVal.bind(this,'guideCardOrg')} value={params['guideCardOrg']}/> */}
                        </div>
                        <div className='inputItem w100'>
                            <span className='vTop label_'>导游证照片：</span>
                            <ElseUpload getGuideImg={this.getGuideImg.bind(this)} guideImgs={guideImgs}></ElseUpload>
                            {/* <Uploads label={'正面照'} getImgUrl={this.getImgUrl.bind(this)} keys={'gurdeCardURL'} index='0' imgs={guides[0]}  basePath={"guide/img/guideCard"}></Uploads>
                            <Uploads label={'反面照'} getImgUrl={this.getImgUrl.bind(this)} keys={'gurdeCardURL'} index='1' imgs={guides[1]}  basePath={"guide/img/guideCard"}></Uploads> */}
                        </div>
                    </div>
                    <div className='infoItem'>
                        <div className='title f20'>账户信息</div>
                        <div className='cover'></div>
                        <div className='inputItem'>
                            <span className='label_'>开户行：</span>
                             <Input type="text" onChange={this.inputVal.bind(this,'openBankName')} value={params['openBankName']} placeholder='请输入开户行'/>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>开户名称：</span>
                             <Input type="text" onChange={this.inputVal.bind(this,'openAccountName')} value={params['openAccountName']} placeholder='请输入开户名称'/>
                        </div>
                        <div className='inputItem'>
                            <span className='label_'>账号：</span>
                             <Input type="text" onChange={this.inputVal.bind(this,'account')} value={params['account']} placeholder='请输入账号'/>
                        </div>
                    </div>
                    <div className='alignRight'>
                        {/* <a className='saveBtn mr15' onClick={this.saveInfo.bind(this,0)}>保存</a>
                        <a className='saveBtn' onClick={this.saveInfo.bind(this)}>保存并下一步</a> */}
                        <Button onClick={this.saveInfo.bind(this,1)} type="primary" className='saveBtn mr15'>上一页</Button>
                        <Button onClick={this.saveInfo.bind(this,0)} type="primary" className='saveBtn mr15'>保存</Button>
                        <Button onClick={this.saveInfo.bind(this)} type="primary" className='saveBtn'>保存并下一页</Button>
                    </div>
                </div>
                <Modal
                    title="温馨提示："
                    visible={this.state.visible}
                    onOk={this.confirmModify.bind(this)}
                    onCancel={this.hideModal.bind(this)}
                    okText="确认"
                    cancelText="取消">
                    <div>是否重新保存？重新保存后需重新审核。</div>
                </Modal>
            </div>
        );
    }
}
export default BaseInfo