import 'core-js/fn/object/assign';
import React from 'react';
import ReactDOM from 'react-dom';
// Render the main component into the dom
import {Provider} from 'react-redux';
import RouterConfig from './router'
import 'babel-polyfill'
import 'antd/dist/antd.css';
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
// import 'url-search-params-polyfill';
ReactDOM.render(
    <LocaleProvider locale={zh_CN}>
    {/* <Provider> */}
        <RouterConfig />
    {/* </Provider> */}
    </LocaleProvider>,
document.getElementById('root'));
