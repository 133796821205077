import '../App.less'
import React, { Component } from 'react'
import axios from 'axios'
import codeSrc from '../images/验证码.png'
import logo from '../images/dmLogo.png'
import {countrys} from  '../config/countrys'
import {URL} from  '../config/url'
import { Select ,message,Input,Button,Checkbox,Modal,Icon } from 'antd';
import Protocol from '../components/protocol'
const Option = Select.Option;
let options={
    top:450,
    duration:1.5,
    left:250
  }
  message.config(options)
class ListPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            timer:'获取验证码',
            num:60,
            message:'',
            phone:'',
            smsCode:'',
            loginTxt:'立即登录',
            country:'86',
            status:0,
            countryName:'中国',
            info:'',
            up:false,
            success:true,
            token:'',
            isCheck:true,
            countryArr:[],
            show:false
        }
    }
    tick = () => {
        const { num } = this.state;
        let txt=`${num-1} (s)`
        if(num==0){
            clearInterval(this.interval)
            this.setState({
                num:60,
                timer:'获取验证码'
            })
        }else{
            this.setState({
                timer: txt,
                num:num-1
            })
        }
        
    }
   async sendSms(){
        let{country,phone,token}=this.state
        let SMS='SMS_86895056'
        if(country!=86){
            SMS='SMS_152190302'
            phone=`${country}${phone}`
        }
        let reg=/^\d{6,}$/
        if(!reg.test(phone)){
            // message.warning('请输入正确手机号')
            this.setState({
                success:true,
                message:'请输入正确手机号',
            })
            return false
        }
        if(!phone){
            // message.warning('请输入请输入手机号')
            this.setState({
                message:'请输入手机号',
                success:true
            })
            return false
        }
        let params = 'url=' + window.getNVCVal()+`&phone=${phone}&areaCode=${country}&templateCode=${SMS}&action=2&signName=达美旅行&appId=1058`
        let _this=this
        if(this.state.num!=60){
            return false
        }
        _this.setState({
            timer:'发送中...',
        })
        axios.post( `${URL}/SERVICE-MT-APP/center/sendCode?${params}`,{},{headers:{'DM_AUTH':token}}).then(function (res) {
            window.NVC_Opt.nvcCallback=function(data){
                let param='url=' + window.getNVCVal()+`&phone=${phone}&areaCode=${country}&templateCode=${SMS}&action=2&signName=达美旅行&appId=1058`
                let item=document.getElementById('captcha')
                    item.style.display='none'
                    _this.setState({
                        status:0,
                        timer:'发送中...',
                    })
                axios.post( `${URL}/SERVICE-MT-APP/center/sendCode?${param}`,{},{headers:{'DM_AUTH':token}}).then(function (res){
                    if(res.data.code==100||res.data.code==200){
                            // message.success('发送成功，注意查收')
                            _this.setState({
                                timer:'60 (s)',
                                success:false,
                                message:'发送成功，注意查收',
                            })
                            _this.interval = setInterval(() => _this.tick(), 1000);
                        
                    }else{
                        // message.error('发送失败，请稍后重试')
                        _this.setState({
                            timer:'获取验证码',
                            success:true,
                            status:0,
                            message:'发送失败，请稍后重试',
                        })
                    }
                })
            }
            if(res.data.code==100||res.data.code==200){
                    // message.success('发送成功，注意查收')
                    _this.setState({
                        timer:'60 (s)',
                        success:false,
                        status:0,
                        message:'发送成功，注意查收',
                    })
                    _this.interval = setInterval(() => _this.tick(), 1000);
            }else if(res.data.code==400){
                _this.setState({
                    status:400,
                    timer:'获取验证码',
                    message:''
                 })
                window.getNC().then(function(){
                    window._nvc_nc.upLang('cn', {
                        _startTEXT: "请按住滑块，拖动到最右边",
                        _yesTEXT: "验证通过",
                        _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
                        _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>",
                    })
                    window._nvc_nc.reset()
                })
            }else if (res.data.code == 600) {
                 _this.setState({
                    status:600,
                    timer:'获取验证码',
                    message:''
                 })
                //唤醒刮刮卡
                window.getSC().then(function(){

                })
            } else if (res.data.code == 900||res.data.code == 800) {
                // message.error('发送频繁，请稍后重试')
                _this.setState({
                    success:true,
                    timer:'获取验证码',
                    message:'发送频繁，请稍后重试',
                })
            }else{
                // message.error(res.data.msg)
                _this.setState({
                    timer:'获取验证码',
                    success:true,
                    status:0,
                    message:'发送失败，请稍后重试',
                })
            } 
            
        }).catch(function (err) {
            _this.setState({
                timer:'获取验证码',
                success:true,
                status:0,
                message:'发送失败，请稍后重试',
            })
        });
        
    }
    phoneVal(e){
        this.setState({
            phone:e.target.value
        })
    }
    smsCode(e){
        this.setState({
            smsCode:e.target.value
        })
    }
    checkVal(e){
        this.setState({
            isCheck:e.target.checked
        })
    }
   async loginIn(){
        let msg=''
        let {phone,smsCode,country,isCheck}=this.state
        let reg=/^\d{6,}$/
        let reg1=/^\d{6}$/
        if(!phone&&smsCode){
            msg='请输入手机号'
        }
        if(phone&&!smsCode){
            if(!reg.test(phone)){
                msg='手机号格式不正确'
            }else{
                msg='请输入验证码'
            }
        }
        if(phone&&smsCode){
            if(!reg.test(phone)){
                msg='手机号格式不正确'
            }else if(!reg1.test(smsCode)){
                msg='验证码格式不正确'
            }
        }
        if(!phone&&!smsCode){
            msg='请输入手机号'
        }
        if(!isCheck){
            msg='请同意私导服务协议'
        }
        if(msg!=''){
            // message.warning(msg)
            this.setState({
                success:true,
                message:msg
            }) 
            return false
        }
        this.setState({
            loginTxt:'登录中...',
        })
        let token
       let res=await axios.post(`${URL}/AUTH/loginByGuidePhone?appId=1058&phoneZone=${country}&phone=${phone}&validateNo=${smsCode}&action=2&companyId=3492`)
        if(res.data.success){
            token=res.data.data
            sessionStorage.setItem('userToken',token)
            sessionStorage.setItem('phoneNum',phone)
            sessionStorage.setItem('phoneArea',country)
        }else{
            this.setState({
                loginTxt:'立即登录',
                message:res.data.message,
                success:true
            })
            return false
        }
        axios.get(`${URL}/SERVICE-MT-GUIDE/guide/detailByPhone?phoneZone=${country}&phone=${phone}`,{headers:{'DM_AUTH':token}}).then((detail)=>{
            if(!detail.data.data){
                sessionStorage.setItem('guideId','')
                window.location='/#/completeInfo'
            }else{
                sessionStorage.setItem('guideId',detail.data.data.id)
                window.scrollTo(0,0)
                window.location='/#/userInfo'
            }
        }).catch((m)=>{
            this.setState({
                loginTxt:'立即登录',
                message:`登录失败，请稍后重试`,
                success:true
            })
        })
        
    }
  async  componentDidMount(){
      let res= await  axios.post(`${URL}/AUTH/loginByApp?appId=1058&token=71b0eea0fe5ece3d9818ce07b343ef24`)
      let countrys= await axios.get(`${URL}/SERVICE-MT-GUIDE/district/getAreaCodeByCodeOrName?countryArea=`,{headers:{'DM_AUTH':res.data.data}})
      this.setState({
          token:res.data.data,
          countryArr:countrys.data.data
      })
      let _this=this
      document.addEventListener('click',function(e){
       if(e.target.offsetParent){
        if(e.target.offsetParent.className=='protocolContent'||e.target.offsetParent.className=='protocolWrap'){
            _this.setState({
                show:true
            })
        }else{
            _this.setState({
                show:false
            }) 
        }
       }else{
        _this.setState({
            show:false
        }) 
       }
       if(e.target.className=='protocolName'){
        _this.setState({
            show:true
        })
       }
       if(e.target.className=='closeBtn'){
        _this.setState({
            show:false
        })
       }
      })
    }
    componentWillMount(){
        clearInterval(this.interval);
        clearTimeout(this.clear)
    }
    handleChange(value) {
        this.setState({
            country:value
        })
      }
      seacrVal(){

      }
      showProtocol(){
        this.setState({
            show:!this.state.show
        })
      }
    render() {
        let {timer,loginTxt,country,status,countryName,info,success,message,isCheck,countryArr,show}=this.state
        let style={
            color:timer==='获取验证码'||timer==='发送中...'?'#fff':'#333'
        }
        let style1={
            background:loginTxt=='立即登录'?'':'#e09800',
            marginTop:status==0?'60px':'0'
        }
        let disable=false
        if(loginTxt!='立即登录'){
            disable=true
        }
        let style3={
            visibility:show?'visible':'hidden',
            opacity:show?1:0
        }
        return (
            <div className='LoginPage'>
                <div className='content welcomePage'>
                    <div className='logo'>
                        <img src={logo} alt=""/>
                        <div className='name'>
                            <div className='f15 fb fc66'>欢迎来到达美</div>
                            <div className='f24 fb'>全球向导中心</div>
                        </div>
                    </div>
                    <div className='loginContent'>
                        <div className='label'>个人向导登录</div>
                        <div className='inputBox'>
                        <Select defaultValue="86" style={{ width: 120 }} onChange={this.handleChange.bind(this)}  onSearch={this.seacrVal.bind(this)} optionFilterProp="title"  showSearch filterOption={(input, option) => option.props.children.indexOf(input)!=-1} >
                        {countryArr.map((item,i)=>{
                                                return(
                                                    <Option value={item.countryAreaNum} key={i} title={item.countryAreaNum+item.countryAreaName}>{item.countryAreaNum}（{item.countryAreaName}）</Option>
                                                )
                                            })}
                        </Select>
                            <span className='line'></span>
                            <Input type="text" placeholder='手机号：' onChange={this.phoneVal.bind(this)} className="phoneid"/>
                        </div>
                        <div className='inputBox'>
                            <Input type="text" placeholder='请输入验证码：' onChange={this.smsCode.bind(this)}  className='verifyCode'/>
                    
                            <a  className='smsCode' onClick={this.sendSms.bind(this)}  disabled={timer==='获取验证码'||timer==='发送中...'?false:true} style={style}> {timer} </a>
                            {/* <div className='smsCode' onClick={this.sendSms.bind(this)} style={style}>{timer}</div> */}
                        </div>
                        <div>
                        <div className='protocol'>
                            <Checkbox onChange={this.checkVal.bind(this)} checked={isCheck}></Checkbox>
                            <span className='fc66'>我已阅读并接受<a onClick={this.showProtocol.bind(this)} className='protocolName'>《私导服务协议》</a></span>
                        </div>
                        </div>
                        {message&&<div className='tipsContent'>
                          {success?<span className='iconfont icon-jingshigantanhao2'></span>:<span className='iconfont icon-gou'></span>}
                          {success?<span style={{verticalAlign:'sub'}}>{message}</span>:<span style={{color:'#459830',verticalAlign:'sub'}}>{message}</span>}
                        </div>}
                        {status!=0&&<div id="captcha" ></div>}
                        <Button type="primary"  className='loginBtn' onClick={this.loginIn.bind(this)} loading={loginTxt==='登录中...'?true:false}> {loginTxt} </Button>
                        <div className='protocolContent' style={style3}>
                            <Protocol show={show}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ListPage