import Header from './Header'
import Footer from './Footer'
import React from 'react';
const Layout = (props) => {
 return (<div className='next-content'>
        <Header currentUrl={props.currentUrl} navs={props.navs}/>
        {props.children}
        <Footer />
  </div>)
}
export default Layout