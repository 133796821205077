import React, { Component } from 'react';
class Foot extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    render() {
        return (
            <div className='footerContent'>
                <div className='info'>
                    我们保证，您的信息仅用于向各国大使馆用于签证填报申请!
                </div>
            </div>
        );
    }
}
export default Foot