import React, { Component } from 'react';
import Layout from '../components/Layout'
import errImg from '../images/sErr.jpg'
class ErrorPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    
    render() {
        let currentUrl=this.props.match.url
        return (
            <Layout currentUrl={currentUrl} navs={[]}>
                <div className='errorPage'>
                    <img src={errImg} alt=""/>
                </div>
            </Layout>
        );
    }
}
export default ErrorPage